import React, { FC } from "react"
import { Box, Heading } from "@looker/components"
import styled from "styled-components"

import {
  SideBarCard,
  SideBarCardProps
} from "./SideBarCard"

export interface SideBarProps {
  heading: string
  cards: Array<SideBarCardProps>
}

export const SideBar: FC<SideBarProps> = (props) => {
  return (
    <SideBarBoxContainer id="relatedContent">
      <Heading as="h3" fontSize="xlarge" paddingBottom="xlarge">
        {props.heading}
      </Heading>
      <SideBarCards>
        {props.cards.map((card: SideBarCardProps) => {
          return (
            <SideBarCard
              key={card.title}
              title={card.title}
              content={card.content}
              link={card.link}
            />
          )
        })}
      </SideBarCards>
    </SideBarBoxContainer>
  )
}

export const SideBarBoxContainer = styled(Box)`
  width: 100%;
  padding-bottom: ${(props) => props.theme.space.xxxlarge};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 320px;
    padding-bottom: 0;
  }
`

export const SideBarCards = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${(props) => props.theme.space.xlarge};

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${(props) => props.theme.space.xlarge};
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: block;
  }

  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      display: block;
    }
  }
`
