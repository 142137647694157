import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../components/Layout/SectionOverview';
import { Link, Button, Card, CardContent } from '@looker/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Get started with embedding Looker" headerTitle="Get started with embedding Looker">{children}</SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Card marginBottom="medium" color="neutral" maxWidth="800px" mdxType="Card"><CardContent mdxType="CardContent"><b>Knowledge check:</b> This page assumes you understand the basics of Inline Frame HTML Element &lt;iframe>, window.postMessage(), and Javascript. Check out the MDN web documentation on <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe" mdxType="Link">iframes</Link> and <Link href="https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage" mdxType="Link">postmessaging</Link> to familiarize yourself with these concepts.</CardContent></Card>
    <p>{`We provide options for you to embed Looker content in your host application via iframes. Each option differs in how you manage and interact with your iframe and authorize/authenticate your user. If you are not sure which option to start with, check out the suggested learning path at the end of this page.`}</p>
    <h1>{`Your iframe embedding options`}</h1>
    <p>{`Every option supports theming and can embed `}<Link href="https://cloud.google.com/looker/docs/creating-user-defined-dashboards" mdxType="Link">{`dashboards`}</Link>{`, `}<Link href="https://cloud.google.com/looker/docs/building-lookml-dashboards" mdxType="Link">{`LookML dashboards`}</Link>{`,  `}<Link href="https://cloud.google.com/looker/docs/exploring-data" mdxType="Link">{`Explores`}</Link>{`, `}<Link href="https://cloud.google.com/looker/docs/saving-and-editing-looks" mdxType="Link">{`Looks`}</Link>{`, query visualizations, and `}<Link href="https://developers.looker.com/extensions/overview" mdxType="Link">{`extensions`}</Link>{`.`}</p>
    <h3>{`Private embedding`}</h3>
    <ul>
      <li parentName="ul">{`You manage your iframe directly.`}</li>
      <li parentName="ul">{`You may use window.postMessage() to interact with your iframe.`}</li>
      <li parentName="ul">{`Your user must authenticate/authorize with Looker directly or with Looker via single sign-on (SSO) with an identity provider.`}</li>
    </ul>
    <Link href="/embed/getting-started/private" mdxType="Link"><Button mdxType="Button">Get started with private embedding</Button></Link>
    <h3>{`SSO embedding`}</h3>
    <ul>
      <li parentName="ul">{`You manage your iframe directly.`}</li>
      <li parentName="ul">{`You may use window.postMessage() to interact with your iframe.`}</li>
      <li parentName="ul">{`You generate a unique iframe src url for every embed user session. This one-time-use URL creates a new Looker embed user or updates an existing one.`}</li>
      <li parentName="ul">{`You must authenticate/authorize the user outside of Looker, for example, through your host application’s identity provider via SSO.`}</li>
    </ul>
    <Link href="/embed/getting-started/sso" mdxType="Link"><Button mdxType="Button">Get started with SSO embedding</Button></Link>
    <h3>{`SSO embedding with Embed SDK (Recommended)`}</h3>
    <ul>
      <li parentName="ul">{`You use the Embed SDK’s convenient Javascript API to `}<strong parentName="li">{`manage`}</strong>{` and `}<strong parentName="li">{`interact`}</strong>{` with your iframe.`}</li>
      <li parentName="ul">{`You generate a unique URL for every embed user session. This one-time-use URL creates a new Looker embed user or updates an existing one. The Embed SDK helps automate part of the URL generation.`}</li>
      <li parentName="ul">{`You must authenticate/authorize the user outside of Looker, for example, through your host application’s identity provider via SSO.`}</li>
    </ul>
    <Link href="/embed/embed-sdk/sso-embed-with-embed-sdk" mdxType="Link"><Button mdxType="Button">Get started with SSO embedding with the Embed SDK</Button></Link>
    <h1>{`Suggested learning path`}</h1>
    <p>{`We suggest you get started in this order:`}</p>
    <ol>
      <li parentName="ol">
        <Link href="/embed/getting-started/private" mdxType="Link">Get started with private embedding</Link> for a basic understanding of Looker embedding.
      </li>
      <li parentName="ol">
        <Link href="/embed/embed-sdk/sso-embed-with-embed-sdk" mdxType="Link">Get started with SSO embedding with Embed SDK</Link> to familiarize yourself with our Embed SDK and how to interact with your iframe.
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      