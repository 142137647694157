import { startCase, isEmpty, map } from 'lodash'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import React, { FC, useEffect, useState } from 'react'
import {
  Aside,
  Accordion,
  Text,
  AccordionContent,
  AccordionDisclosure,
  Box,
  Heading,
  InputText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@looker/components'
import { Link } from 'gatsby'
import { useSitemap } from './useSitemap'
import { NavigationProps, NavigationPage } from '../../types/navigation'
import { upperCase, lowerCase } from 'lodash'
import { Search as SearchButton } from '@styled-icons/material/'

interface ComponentsGroups {
  [key: string]: NavigationPage[]
}

const groupComponents = (pages: NavigationPage[]) => {
  const groups = pages.reduce((acc: ComponentsGroups, page) => {
    const path = `/${page.productArea}/docs/${
      page.section ? page.section + (page.slug ? '/' : '') : ''
    }${page.slug ? page.slug : ''}`
    if (path == 'components/') return acc
    const groupName = page.section
    const group = acc[groupName] ? [...acc[groupName], page] : [page]
    return { ...acc, [groupName]: group }
  }, {})

  return map(groups, (group, groupKey) => {
    const groupKeyArr = groupKey.split('/')
    const groupName = groupKeyArr[groupKeyArr.length - 1]
    return { children: group, path: groupKey, title: startCase(groupName) }
  }).sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
}

const NavigationLayout: FC<NavigationProps> = (props) => {
  const location = useLocation()
  const productArea = 'components'
  const [searchQuery, setSearchQuery] = useState<string | undefined>()
  const [pages, setPages] = useState()

  const map = useSitemap()
    [productArea].filter(
      (page) =>
        !['www', 'develop', 'foundations'].includes(page.section) &&
        page.title != 'Components' &&
        page.title.length > 0
    ) //Filter to only get reference pages
    .sort((a, b) => (a.title > b.title ? 1 : -1))

  useEffect(() => {
    searchQuery
      ? setPages(
          map.filter((page) =>
            page.title.toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
      : setPages(map)
  }, [searchQuery])

  if (!pages) return null
  const groups = groupComponents(pages)

  const onChange = (e) => {
    setSearchQuery(e.currentTarget.value)
  }

  const content = (
    <Box>
      <Box px="large" pb="large">
        <Heading as="h5" color="key" fontWeight="bold" paddingY="small">
          Components Documentation
        </Heading>
        <InputText onChange={onChange} placeholder="Search for a component" />
      </Box>
      <Tabs>
        <TabList distribute>
          <Tab>Grouped</Tab>
          <Tab>A-Z</Tab>
        </TabList>
        <TabPanels pt="xsmall">
          <TabPanel>
            <Box paddingLeft="small" paddingRight="medium">
              {groups.map((group) => {
                return (
                  <Accordion
                    key={group.path}
                    indicatorColor="text1"
                    defaultOpen={
                      searchQuery?.length > 0 ||
                      location.pathname.includes(
                        `/components/docs/${group.path}`
                      )
                    }
                  >
                    <AccordionDisclosure py="0">
                      <SideBarLinkAccordion>
                        {startCase(group.path)}
                      </SideBarLinkAccordion>
                    </AccordionDisclosure>
                    <AccordionContent>
                      <SideBarLinkList>
                        {group.children &&
                          group.children?.map((page) => {
                            const path = `/${page.productArea}/docs/${
                              page.section
                                ? page.section + (page.slug ? '/' : '')
                                : ''
                            }${page.slug ? page.slug : ''}`
                            return (
                              <li key={path}>
                                <SideBarLink key={page.title} to={path}>
                                  {page.title}
                                </SideBarLink>
                              </li>
                            )
                          })}
                      </SideBarLinkList>
                    </AccordionContent>
                  </Accordion>
                )
              })}
            </Box>
          </TabPanel>
          <TabPanel>
            <SideBarLinkListUnstyled>
              {pages.map((page) => {
                const path = `/components/docs/${
                  page.section ? page.section + (page.slug ? '/' : '') : ''
                }${page.slug ? page.slug : ''}`
                return (
                  <li key={path}>
                    <SideBarLinkUnstyled key={page.title} to={path}>
                      {startCase(page.title)}
                    </SideBarLinkUnstyled>
                  </li>
                )
              })}
            </SideBarLinkListUnstyled>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )

  return (
    <Aside
      width="320px"
      as="nav"
      style={{
        height: `100vh`,
        overflow: 'scroll',
        backgroundColor: 'white',
      }}
      {...props}
    >
      {content}
    </Aside>
  )
}

export const ComponentNavigation = styled(NavigationLayout)`
  position: fixed;
  border-right: 1px solid #dee1e5;
  min-height: 100vh;
  height: 100%;
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: block;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  span {
    padding-left: 10px;
  }

  ${AccordionDisclosure}:hover svg {
    color: ${(props) => props.theme.colors.key};
  }

  [aria-expanded='true'] {
    color: ${(props) => props.theme.colors.key};

    a {
      font-weight: 600;
    }
  }
`

const SideBarLinkList = styled.ul`
  border-left: 1px dashed ${(props) => props.theme.colors.ui2};
  padding-left: ${(props) => props.theme.space.xxsmall};
  margin-left: ${(props) => props.theme.space.xsmall};
  margin-top: ${(props) => props.theme.space.xxsmall};
  margin-bottom: ${(props) => props.theme.space.xxsmall};
  color: ${(props) => props.theme.colors.text4};
  list-style: none;
`

const SideBarLinkAccordion = styled(Link)`
  font-family: 'Google Sans';
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes.small};
  display: block;
  color: ${(props) => props.theme.colors.text4};
  padding: ${(props) => props.theme.space.xsmall};
  border-radius: ${(props) => props.theme.space.xxsmall};

  &:hover {
    color: ${(props) => props.theme.colors.key};
  }
`

const SideBarLink = styled(Link)`
  font-family: 'Google Sans';
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes.small};
  display: block;
  color: ${(props) => props.theme.colors.text4};
  padding: ${(props) => props.theme.space.xsmall};
  border-radius: ${(props) => props.theme.space.xxsmall};

  &:hover {
    background-color: ${(props) => props.theme.colors.ui1};
  }

  &[aria-current='page'] {
    font-weight: 600;
    background-color: ${(props) => props.theme.colors.ui1};
  }
`

const SideBarLinkListUnstyled = styled.ul`
  padding-left: ${(props) => props.theme.space.xxsmall};
  margin-left: ${(props) => props.theme.space.xsmall};
  margin-right: ${(props) => props.theme.space.xsmall};
  margin-top: 0;
  list-style: none;
`

const SideBarLinkUnstyled = styled(Link)`
  font-family: 'Google Sans';
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes.small};
  display: block;
  color: ${(props) => props.theme.colors.text3};
  padding: ${(props) => props.theme.space.xsmall};
  border-radius: ${(props) => props.theme.space.xxsmall};

  &:hover {
    color: ${(props) => props.theme.colors.key};
  }

  &[aria-current='page'] {
    font-weight: 600;
  }
`

const SideBarOnPageLinks = styled.ul`
  padding-left: ${(props) => props.theme.space.medium};
  padding-top: ${(props) => props.theme.space.xxsmall};
  padding-bottom: ${(props) => props.theme.space.xxsmall};

  a {
    display: block;
    color: ${(props) => props.theme.colors.text3};
    font-size: ${(props) => props.theme.fontSizes.xsmall};
    padding: ${(props) => props.theme.space.xxsmall}
      ${(props) => props.theme.space.xxsmall}
      ${(props) => props.theme.space.xxsmall} 0;

    &:hover {
      color: ${(props) => props.theme.colors.text5};
    }

    &.selected {
      font-weight: 500;
      color: ${(props) => props.theme.colors.text5};
    }
  }
`
