import React from "react"
import { Box } from "@looker/components"
import styled from "styled-components"

import {
  SideBar,
  SideBarProps
} from "./SideBar"

const TempSideBar = () => {
  //TODO: Pull from CMS, pass data in as props.
  const temporarySideCards: SideBarProps = {
    heading: "Build on Examples and Tutorials",
    cards: [
      {
        title: "Python SDK Examples",
        content:
          "Source code examples and projects that use SDKs to communicate with the Looker API.",
        cta: "Click",
        link: "https://github.com/looker-open-source/sdk-codegen/tree/main/examples/python",
      },
      {
        title: "Language Specific Content",
        content:
          "Content related to the language that is currently selected or generic content that works for all languages.",
        cta: "Click",
        link: "https://github.com/looker-open-source/sdk-codegen/tree/main/examples",
      },
      {
        title: "Data from a Look to a Block",
        content:
          "The Get Look Data Block contains an API call for retrieving the results of a Look.",
        cta: "Click",
        link: "https://github.com/looker-open-source/sdk-codegen/blob/main/examples/python/download_look.py",
      },
    ],
  }

  return (
    <SideBarBox>
      <SideBar
        heading={temporarySideCards.heading}
        cards={temporarySideCards.cards}
      />
    </SideBarBox>
  )
}

export default TempSideBar

export const SideBarBox = styled(Box)`
`
