import React, { FC } from "react"
import CookieConsent from 'react-cookie-consent'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from "@reach/router"

export interface CookieBannerProps {
}

export const CookieBanner: FC<CookieBannerProps> = () => {
  const location = useLocation()
  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      declineButtonText="Decline"
      enableDeclineButton
      flipButtons
      cookieName="looker-developer-portal-gtag"
      onAccept={() => {
        initializeAndTrack(location) 
      }}
      style={{
        'background': 'white',
        'color': '#262D33',
        'padding': '8px',
        'border-top': 'solid 1px #e0e0e0'
      }}
      buttonStyle={{
        'background': '#1A73E8',
        'color': 'white',
        'border': '1px solid #1A73E8',
        'border-radius': '0.25rem',
        'font-family': '"Google Sans", arial, sans-serif',
        'font-weight': '500',
        'line-height': '1',
        'font-size': '0.875rem',
        'height': '36px',
        'padding-left': '1.5rem',
        'padding-right': '1.5rem',
        'margin': '0'
      }}
      declineButtonStyle={{
        'background': 'white',
        'color': '#1A73E8',
        'border': '1px solid #DEE1E5',
        'border-radius': '0.25rem',
        'font-family': '"Google Sans", arial, sans-serif',
        'font-weight': '500',
        'line-height': '1',
        'font-size': '0.875rem',
        'height': '36px',
        'padding-left': '1.5rem',
        'padding-right': '1.5rem'
      }} >
    This site uses cookies to enhance the user experience.
  </CookieConsent>
  )
}
