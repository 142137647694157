import React from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type {
  CAll,
  SupportedChartTypes,
  CScatterSeries,
  CTableSeries,
  CLineSeries,
  CSeriesBasic,
  CSeriesLine,
  CSeriesSize,
  Fields,
} from '@looker/visualizations'
import { isNumeric } from '@looker/visualizations'
import { Fieldset, Grid } from '@looker/components'
import { SeriesColor } from './SeriesColor'
import type { CColorSupported } from './SeriesColor'
import { SeriesVisible } from './SeriesVisible'
import type { CVisibleSupported } from './SeriesVisible'
import { SeriesLabel } from './SeriesLabel'
import type { CLabelSupported } from './SeriesLabel'
import { SeriesLineWidth } from './SeriesLineWidth'
import type { CLineWidthSupported } from './SeriesLineWidth'
import { SeriesPointShape } from './SeriesPointShape'
import type { CPointShapeSupported } from './SeriesPointShape'
import { SeriesPointStyle } from './SeriesPointStyle'
import type { CPointStyleSupported } from './SeriesPointStyle'
import { SeriesSizeBy } from './SeriesSizeBy'
import type { CSeriesSizeBySupported } from './SeriesSizeBy'
import partial from 'lodash/partial'
import set from 'lodash/set'
import styled from 'styled-components'

/**
 * A list of relevant charts that access this configuration
 */
type SupportedChartConfig = {
  type:
    | SupportedChartTypes['area']
    | SupportedChartTypes['bar']
    | SupportedChartTypes['column']
    | SupportedChartTypes['line']
    | SupportedChartTypes['scatter']
    | SupportedChartTypes['sparkline']
    | SupportedChartTypes['table']
  series?: CScatterSeries | CTableSeries | CLineSeries
}

const renderFor: Array<SupportedChartConfig['type']> = [
  'area',
  'bar',
  'column',
  'line',
  'scatter',
  'sparkline',
  'table',
]

export type SeriesProps = {
  config: SupportedChartConfig
  fields: Fields
  onConfigChange: Dispatch<SetStateAction<Partial<CAll>>>
}

export const Series = (props: SeriesProps) => {
  const { config, fields, onConfigChange } = props
  const { series = {} } = config

  if (!renderFor.includes(config.type)) {
    // Early return! Only render for supported charts
    return null
  }

  const handleChange = (
    seriesKey: string,
    newSeries: CSeriesBasic | CSeriesLine
  ) => {
    const draft = set({ ...config }, ['series', seriesKey], newSeries)
    onConfigChange(draft as CAll)
  }

  const seriesList: Array<[string, CSeriesBasic | CSeriesLine]> = Array.isArray(
    series
  )
    ? series.map((s, i) => [String(i), s])
    : Object.entries(series)

  return (
    <>
      {seriesList.map(([key, s], i) => {
        const handleSeriesChange = partial(handleChange, key)

        // Sparkline only renders the first series
        const seriesDisabled = config.type === 'sparkline' && i > 0

        return (
          <StyledFieldset
            legend={isNumeric(key) ? `Series: ${key}` : key}
            accordion
            defaultOpen={seriesDisabled === false}
            key={key}
          >
            <SeriesVisible
              chartType={config.type as CVisibleSupported['type']}
              series={s as CSeriesBasic}
              onSeriesChange={handleSeriesChange}
            />
            <SeriesColor
              chartType={config.type as CColorSupported['type']}
              series={s as CSeriesBasic}
              onSeriesChange={handleSeriesChange}
              disabled={seriesDisabled}
            />
            <SeriesLabel
              chartType={config.type as CLabelSupported['type']}
              series={s as CSeriesBasic}
              onSeriesChange={handleSeriesChange}
            />
            <SeriesLineWidth
              chartType={config.type as CLineWidthSupported['type']}
              series={s as CSeriesLine}
              onSeriesChange={handleSeriesChange}
              disabled={seriesDisabled}
            />
            <Grid columns={2}>
              <SeriesPointStyle
                chartType={config.type as CPointStyleSupported['type']}
                series={s as CSeriesLine}
                onSeriesChange={handleSeriesChange}
              />
              <SeriesPointShape
                chartType={config.type as CPointShapeSupported['type']}
                series={s as CSeriesLine}
                onSeriesChange={handleSeriesChange}
              />
            </Grid>
            <SeriesSizeBy
              chartType={config.type as CSeriesSizeBySupported['type']}
              fields={fields}
              series={s as CSeriesSize}
              onSeriesChange={handleSeriesChange}
            />
          </StyledFieldset>
        )
      })}
    </>
  )
}

const StyledFieldset = styled(Fieldset)`
  legend {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
