import React from 'react'
import { Box, Flex, Heading } from '@looker/components'
import styled from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import { TempSideBar } from '../SideBar'
import Layout from './index'
import { Node, PageContext } from './types'
import { MarketingBanner } from './MarketingBanner'

const Doc = (pageContext: PageContext) => {
  const page: Node = pageContext.pageContext.page.node
  const headerTitle = page.title

  return (
    <Layout
      defaultNav={true}
      headerSearch={true}
      showQuotes={false}
      title={headerTitle}
    >
      <PageContainer>
        <MarketingBanner />
        <Box paddingTop="xlarge" id="header">
          <Box maxWidth="600px">
            <Heading as="h1" fontSize="xxxxxlarge" fontWeight="normal">
              {headerTitle}
            </Heading>
          </Box>
          <ContentBox id="contentBox">
            <MDXBox>
              <MDXRenderer>{page.content.childMdx.body}</MDXRenderer>
            </MDXBox>
            <TempSideBar />
          </ContentBox>
        </Box>
      </PageContainer>
    </Layout>
  )
}

export default Doc

export const PageContainer = styled.div`
  max-width: 1340px;
  margin: 0 auto;
  position: relative;
  padding: 0 ${(props) => props.theme.space.large};
`

export const ContentBox = styled(Flex)`
  padding-top: ${(props) => props.theme.space.xxlarge};
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: row;
  }
  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      flex-direction: row;
    }
  }
`

export const MDXBox = styled(Box)`
  max-width: auto;
  padding-right: 0;
  padding-bottom: ${(props) => props.theme.space.xxxlarge};
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    max-width: calc(100% - 320px);
    padding-right: ${(props) => props.theme.space.xxxxlarge};
  }
  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      max-width: calc(100% - 320px);
      padding-right: ${(props) => props.theme.space.xxxxlarge};
    }
  }
`
