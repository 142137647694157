import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../components/Layout/SectionOverview';
import { Link, Button, Card, CardContent } from '@looker/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Looker Extensions Overiew" headerTitle="Extensions" splashImage="extensions">{children}</SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Card marginBottom="medium" color="neutral" maxWidth="800px" mdxType="Card"><CardContent mdxType="CardContent"><b>Note:</b> These extensions are different from <Link href="https://docs.looker.com/data-modeling/learning-lookml/extends" mdxType="Link">LookML extends/extensions</Link>, the code organization syntax used when modeling data in LookML.</CardContent></Card>
    <p>{`Looker extensions allow you to provide highly customized and integrated experiences to your Looker instance’s users.`}</p>
    <p>{`A dedicated Looker page becomes your canvas, with a wide array of tools at your disposal, including the ability to:`}</p>
    <ul>
      <li parentName="ul">{`Run Javascript code `}</li>
      <li parentName="ul">{`Access the `}<a parentName="li" {...{
          "href": "/api/overview"
        }}>{`Looker APIs`}</a>{` through a pre-authenticated client`}</li>
      <li parentName="ul">{`Leverage `}<a parentName="li" {...{
          "href": "/components/overview"
        }}>{`Looker Components`}</a>{` for seamless UI`}</li>
      <li parentName="ul">{`Make HTTP calls from the client or through a convenient server proxy`}</li>
      <li parentName="ul">{`Authenticate with third-party services via OAuth`}</li>
      <li parentName="ul">{`Use additional `}<a parentName="li" {...{
          "href": "https://docs.looker.com/data-modeling/extension-framework/extension-framework-intro#extension_framework_features"
        }}>{`Extension Framework features`}</a></li>
    </ul>
    <p>{`Simultaneously, detailed sandboxing controls and built-in user permissioning allow your instance’s administrators to be confident about what data is accessible to application developers and end users.`}</p>
    <h1>{`Using extensions`}</h1>
    <p>{`The first step to using a Looker extension is authoring a JavaScript-based client-side application that uses the APIs that are exposed by Looker’s extension framework.`}</p>
    <p>{`The quickest way to get up and running with such an application is with our `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/create-looker-extension"
      }}><inlineCode parentName="a">{`create-looker-extension`}</inlineCode></a>{` command line tool, which will set you up with a boilerplate codebase, including the necessary build tooling to bundle your application code via webpack. The tool lets you choose between either JavaScript or TypeScript, and lets you select whether to use React.`}</p>
    <p>{`Once your codebase is ready to go, you can load it into your Looker instance in one of three ways:`}</p>
    <ul>
      <li parentName="ul">{`During development, you can use a `}<a parentName="li" {...{
          "href": "https://docs.looker.com/reference/manifest-params/application#url"
        }}>{`URL`}</a>{` to reference a locally hosted web server for quick and convenient development.`}</li>
      <li parentName="ul">{`You can build a JS bundle and `}<a parentName="li" {...{
          "href": "https://docs.looker.com/reference/manifest-params/application#file"
        }}>{`load the file through your LookML project`}</a>{`.`}</li>
      <li parentName="ul">{`You can deploy the JS file to a remote server or content delivery network (CDN) and then `}<a parentName="li" {...{
          "href": "https://docs.looker.com/reference/manifest-params/application#url"
        }}>{`reference it by URL`}</a>{`. This option is often the most convenient when used together with continuous deployment automation from your extension’s codebase.`}</li>
    </ul>
    <h1>{`Try it out`}</h1>
    <p>{`Want to see Looker extensions in action before writing any code? Several `}<a parentName="p" {...{
        "href": "https://marketplace.looker.com/marketplace/directory?Type=applications"
      }}>{`Looker-published extensions`}</a>{` can be `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/marketplace"
      }}>{`installed with one click`}</a>{` into your Looker instance from the Looker Marketplace.`}</p>
    <h1>{`Getting started`}</h1>
    <Link href="/extensions/getting-started" mdxType="Link"><Button mdxType="Button">Get started with a Looker Extension</Button></Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      