import { Card, Flex, Heading, Text, Button } from "@looker/components";
import styled from "styled-components";
import React, { FC } from "react";
import { Link } from "gatsby";

export interface InfoCardProps {
  title: string;
  content: string;
  cta: string;
  link: string;
  icon: string;
}

export const InfoCard: FC<InfoCardProps> = (props) => {
  return (
    <NewCard borderRadius="8px" padding="xlarge">
      <Flex flexDirection="column">
        <img src={props.icon} />
        <Heading paddingBottom="large" color="key">
          {props.title}
        </Heading>
        <Text paddingBottom="56px" color="text3">
          {props.content}
        </Text>
        <Link to={props.link}>
          <Button type="submit">{props.cta}</Button>
        </Link>
      </Flex>
    </NewCard>
  );
};

export const NewCard = styled(Card)`
  &:hover {
    border-color: ${(props) => props.theme.colors.ui3};
  }

  ${Flex} {
    position: relative;
    height: 100%;
  }

  img {
    margin-left: -4px;
    padding-bottom: ${(props) => props.theme.space.large};
    width: 64px;
  }

  a {
    text-decoration: none;
    position: absolute;
    bottom: 0;
  }
`;
