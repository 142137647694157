import React from "react"
import {
  Box,
  Heading,
} from "@looker/components"
import styled from "styled-components"

import LogoPlaceholder from "../../../images/logo_placeholder.svg"
import {
  QuoteCard
} from "../../Content"


const QuotesBox = () => {
  return (
    <Box
      paddingTop={["xxxlarge", "xxxlarge", "xxxlarge", "xxxxlarge"]}
      paddingBottom="xxxxlarge"
      backgroundColor="ui1"
    >
      <QuoteContainer>
        <Heading
          color="text"
          as="h2"
          fontWeight="normal"
          fontSize={["xxxlarge", "xxxlarge", "xxxlarge", "xxxxlarge"]}
          textAlign="center"
          paddingBottom="large"
        >
          What developers are saying
        </Heading>
        <QuoteCards>
          <QuoteCard
            id="slug"
            content="Using the Looker platform has been a game changer for us. We do not need worry about authentication and hosting, and are able to match the look and feel of Looker, saving time on front end development."
            image={'https://images.squarespace-cdn.com/content/v1/607e09ef64c06153a1e9846f/1618877300087-H8QC5C2VL6LC8WCTMPPG/4MILE+dot+32.png?format=1500w'}
            attribution="Nick Fogler, CEO at 4Mile"
          />
          <QuoteCard
            id="slug"
            content="Developing with Looker was a huge win for us. We've delivered a tremendous amount of value to our shared users with minimal lift on our end."
            image={'https://www.datadriven.works/assets/DD-Logo_Black-4929008ffe035ddab3373c40af39e30e82abf202317d1f02f28e99a8e26056a2.png'}
            attribution="Jawad Laraqui, CEO at Data Driven"
          />
        </QuoteCards>
      </QuoteContainer>
    </Box>
  )
}

export default QuotesBox

export const QuoteContainer = styled(Box)`
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 ${(props) => props.theme.space.large};
`

export const QuoteCards = styled.div`
  padding-top: ${(props) => props.theme.space.xxlarge};
  display: grid;
  grid-auto-flow: row;
  gap: ${(props) => props.theme.space.xlarge};
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-auto-flow: column;
  }
`
