import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../components/Layout/SectionOverview';
import { Link, Button, Space } from '@looker/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Looker Components Overiew" headerTitle="Components" splashImage="components">
    {children}
  </SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Looker’s component library allows applications to leverage Looker’s design elements, UI patterns, and data visualizations, all through the interface of React components. Some examples of components include forms, buttons, filter inputs, pagination controls, layouts, line charts, bar charts, scatterplot charts, tables, and more.`}</p>
    <p>{`While quick time-to-value is one of the strengths of components, where they really shine is in enabling more seamless experiences between Looker and custom-built applications.`}</p>
    <p>{`Some common use cases include:`}</p>
    <ul>
      <li parentName="ul">{`Creating Looker-like UI, such as page navigation, within a Looker extension`}</li>
      <li parentName="ul">{`Creating Looker-like UI, such as dashboard filters, around embedded Looker content`}</li>
      <li parentName="ul">{`Delivering a lighter-weight individual visualization within an embedded analytics application without the full experience of an `}<a parentName="li" {...{
          "href": "https://docs.looker.com/reference/embedding/sso-embed"
        }}>{`iframe embed`}</a></li>
    </ul>
    <h1>{`Using components`}</h1>
    <p>{`Being React components, Looker components are published to NPM and can be `}<a parentName="p" {...{
        "href": "https://looker-open-source.github.io/components/latest/?path=/docs/develop-add-npm-package--docs"
      }}>{`conveniently added`}</a>{` to your front-end Javascript or Typescript application using NPM.`}</p>
    <p>{`Separate NPM packages are available for `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/@looker/components"
      }}>{`UI components`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/@looker/filter-components"
      }}>{`filter components`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/@looker/visualizations"
      }}>{`visualization components`}</a>{`.`}</p>
    <h1>{`Try it out`}</h1>
    <p>{`Want to see Looker components in action before writing any code? These demos and sandboxes can help:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://components.looker.com"
        }}>{`Components Interactive Reference`}</a>{`: a listing of all the Looker components other than the visualization components`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developers.looker.com/components/visualization-components/"
        }}>{`Visualization components playground`}</a>{`: Interactively pick from the available visualization options and see the visualization rendered in the playground application, with no embed`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://atomfashion.io/analytics/home"
        }}>{`Atom Fashion`}</a>{`: a demo data-driven web application built using Looker components (requires Google account to log in)`}</li>
    </ul>
    <h1>{`Getting started`}</h1>
    <Space mdxType="Space">
  <Link href="https://components.looker.com" mdxType="Link">
    <Button mdxType="Button">Get started with Looker UI components</Button>
  </Link>
  <Link href="https://docs.looker.com/data-modeling/extension-framework/components" mdxType="Link">
    <Button mdxType="Button">Get started with Looker components</Button>
  </Link>
  <Link href="/components/getting-started" mdxType="Link">
    <Button mdxType="Button">Get started with the Looker visualization components</Button>
  </Link>
    </Space>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      