import type { FC, FormEvent, SetStateAction, Dispatch } from 'react'
import React from 'react'
import { FieldText } from '@looker/components'
import styled from 'styled-components'
import { FieldInfo } from '../FieldInfo'

interface EmbedEditorProps {
  width?: string
  setWidth: Dispatch<SetStateAction<string | undefined>>
  height?: string
  setHeight: Dispatch<SetStateAction<string | undefined>>
}

/** EmbedEditor is used to modify settings unrelated to vis config  */

export const EmbedEditor: FC<EmbedEditorProps> = ({
  width,
  height,
  setWidth,
  setHeight,
}) => {
  const handleWidthChange = (e: FormEvent) => {
    const value = (e.target as HTMLInputElement).value
    setWidth(value)
  }
  const handleHeightChange = (e: FormEvent) => {
    const value = (e.target as HTMLInputElement).value
    setHeight(value)
  }
  return (
    <EmbedEditorGrid>
      <FieldText
        label="Width"
        value={width}
        onChange={handleWidthChange}
        detail={
          <FieldInfo
            content={
              "'auto' spans available width. Otherwise accepts a number to set pixel value."
            }
          />
        }
      />
      <FieldText
        label="Height"
        value={height}
        onChange={handleHeightChange}
        detail={
          <FieldInfo
            content={
              "'auto' falls back to default value hard-coded in the charts. Otherwise accepts a number to set pixel value."
            }
          />
        }
      />
    </EmbedEditorGrid>
  )
}

const EmbedEditorGrid = styled.div`
  display: grid;
  grid-template-columns: 100px 100px;
  grid-gap: 1rem;
  padding: 1rem 0;
`
