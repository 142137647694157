import React from 'react'
import { Box, Flex, Heading, Text, Link } from '@looker/components'
import styled from 'styled-components'
import Layout from '.'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useSitemap } from '../Navigation/useSitemap'
import { SideBarCard } from '../SideBar'
import { MarketingBanner } from './MarketingBanner'

interface Edge {
  node: Node
}

interface Node {
  productArea: string
  section: string
  index: number
  title: string
  content: {
    childMdx: {
      id: string
      body: string
    }
  }
}

interface PageContext {
  pageContext: {
    page: Edge
  }
}

const Overview = (pageContext: PageContext) => {
  const page: Node = pageContext.pageContext.page.node
  const headerTitle = page.title

  const sectionPages = useSitemap()[page.productArea].filter(
    (sectionPage) =>
      sectionPage.section == page.section && sectionPage.slug != null
  )

  // The title of these pages looks like it comes from Markdown. We might want the main title to be
  // able to be styled like other main titles headers on Getting Started page. I deleted them
  // "Overview" title because it was redundant.

  return (
    <Layout
      defaultNav={false}
      headerSearch={true}
      showQuotes={false}
      title={headerTitle}
    >
      <PageContainer>
        <MarketingBanner />
        <Box paddingTop="xlarge" id="header">
          <Box maxWidth="600px">
            <Heading
              as="h1"
              fontSize="xxxxxlarge"
              fontWeight="normal"
              paddingBottom="u5"
            >
              {headerTitle}
            </Heading>
          </Box>
        </Box>
        <Box>
          <Box>
            <MDXBox>
              <MDXRenderer>{page.content.childMdx.body}</MDXRenderer>
            </MDXBox>
          </Box>
          <ContentBox id="contentBox">
            <GridCards>
              {sectionPages.map((page) => {
                return (
                  <SideBarCard
                    key={page.title}
                    title={page.title}
                    content={page.excerpt}
                    link={`/${page.productArea}/${page.section}/${page.slug}`}
                  />
                )
              })}
            </GridCards>
          </ContentBox>
        </Box>
      </PageContainer>
    </Layout>
  )
}

export default Overview

export const GridCards = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${(props) => props.theme.space.xlarge};
  padding-bottom: ${(props) => props.theme.space.xxxxlarge};

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${(props) => props.theme.space.xlarge};
  }
`

export const PageContainer = styled.div`
  max-width: 1340px;
  margin: 0 auto;
  position: relative;
  padding: 0 ${(props) => props.theme.space.large};
`

export const ContentBox = styled(Flex)`
  padding-top: ${(props) => props.theme.space.xxlarge};
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: row;
  }
  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      flex-direction: row;
    }
  }
`

export const MDXBox = styled(Box)`
  max-width: auto;
  padding-right: 0;
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    max-width: calc(100% - 320px);
    padding-right: ${(props) => props.theme.space.xxxxlarge};
  }
  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      max-width: calc(100% - 320px);
      padding-right: ${(props) => props.theme.space.xxxxlarge};
    }
  }
`
