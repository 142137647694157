import React from 'react'
import { Box, Text, Flex } from '@looker/components'
import styled from 'styled-components'

import { ExternalLink } from '../../Content'

const LegalBox = () => {
  return (
    <Flex paddingTop="large" justifyContent="space-between">
      <Text fontSize="small">
        © {new Date().getFullYear()} Looker Data Sciences, Inc.
      </Text>
      <Box>
        <PrivacyLink
          fontSize="small"
          target="_blank"
          href="https://looker.com/privacy"
        >
          Privacy
        </PrivacyLink>{' '}
        <Text fontSize="small"> | </Text>{' '}
        <PrivacyLink
          fontSize="small"
          target="_blank"
          href="https://looker.com/terms"
        >
          Terms
        </PrivacyLink>{' '}
        <Text fontSize="small"> | </Text>{' '}
        <PrivacyLink
          fontSize="small"
          target="_blank"
          href="https://looker.com/trust-center/privacy/cookies"
        >
          Cookies
        </PrivacyLink>
      </Box>
    </Flex>
  )
}

export default LegalBox

export const PrivacyLink = styled(ExternalLink)`
  color: ${(props) => props.theme.colors.text3};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
  }

  &:visited {
    color: ${(props) => props.theme.colors.text3};
  }
`
