import type { FC, Dispatch, SetStateAction } from 'react'
import React, { useContext } from 'react'
import type {
  CAll,
  SupportedChartTypes,
  RawApiConfigResponse,
} from '@looker/visualizations'
import {
  buildChartConfig,
  supportedChartTypes,
  QueryContext,
} from '@looker/visualizations'
import { FieldSelect, SpaceVertical } from '@looker/components'
import { Legend } from '../Legend'
import { RenderNullValues } from '../RenderNullValues'
import { Tooltips } from '../Tooltips'
import { Positioning } from '../Positioning'
import { Series } from '../Series'
import { XAxis, YAxis } from '../axes'

interface ConfigEditorProps {
  config: Partial<CAll>
  onConfigChange: Dispatch<SetStateAction<Partial<CAll>>>
}

const supportedTypeOptions = Object.keys(supportedChartTypes)
  .filter(t=>t!='pie') //Not sure why it's not working currently, so filtering it out for demo purposes
  .map(t=>({value:t}))

export const ConfigEditor: FC<ConfigEditorProps> = ({
  config: configOverrides,
  onConfigChange,
}) => {
  const { config: configDefaults = {}, fields } = useContext(QueryContext)

  const config: any = buildChartConfig(
    { ...configDefaults, ...configOverrides } as CAll | RawApiConfigResponse,
    fields
  )

  return (
    <SpaceVertical>
      {/* Select a new chart type from SupportedChartTypes */}
      <FieldSelect
        name="type"
        label="Chart Type"
        onChange={t => {
          onConfigChange({ type: t as keyof SupportedChartTypes })
        }}
        value={config.type}
        options={supportedTypeOptions}
      />
      <Legend config={config} onConfigChange={onConfigChange} />
      <Positioning config={config} onConfigChange={onConfigChange} />
      <Tooltips config={config} onConfigChange={onConfigChange} />
      <RenderNullValues config={config} onConfigChange={onConfigChange} />
      <Series config={config} fields={fields} onConfigChange={onConfigChange} />
      <XAxis config={config} onConfigChange={onConfigChange} />
      <YAxis config={config} onConfigChange={onConfigChange} />
    </SpaceVertical>
  )
}
