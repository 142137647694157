import React from "react"
import {
  Box,
  ButtonToggle,
  ButtonItem,
} from "@looker/components"
import { startCase } from "lodash"
import { SubType } from '../Layout/GettingStarted'

const getDisplayValue = (section: string) => {
  section = section?.length <= 3 ? section.toUpperCase() : startCase(section)
  return section.replace("-", " ")
}

interface SubtypeSelectorProps {
  subTypes: SubType[]
  activeSection: any
  handleSectionUpdate: (e: string) => void
}

const SubtypeSelector = ({
  subTypes,
  activeSection,
  handleSectionUpdate
}: SubtypeSelectorProps) => {
  return (
    <Box pt="xxlarge" id="subtypeselector">
      {subTypes.length > 1 && (
        <ButtonToggle
          value={getDisplayValue(activeSection)}
          onChange={handleSectionUpdate}
        >
          {subTypes.map((item) => {
            return (
              <ButtonItem key={item.subType}>
                { getDisplayValue(item.subType) }
              </ButtonItem>
            )
          })}
        </ButtonToggle>
      )}
    </Box>
  )
}

export default SubtypeSelector
