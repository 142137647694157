import type { FC } from 'react'
import React, { useState, useEffect } from 'react'
import { FieldCheckbox } from '@looker/components'

interface CheckboxProps {
  label?: string
  onChange?: (isChecked: boolean) => void
  checked?: boolean
}

/**
 * A simple checkbox designed to be symmetrical with other components used in
 * Config editor. Stores checked state, and accepts a "checked" prop (rather
 * than "checked" per standard checkbox)
 */

export const Checkbox: FC<CheckboxProps> = ({ onChange, label, checked }) => {
  const [isChecked, setIsChecked] = useState(Boolean(checked))
  useEffect(() => {
    onChange?.(isChecked)
  }, [isChecked])
  return (
    <FieldCheckbox
      label={label}
      checked={isChecked}
      onChange={() => {
        setIsChecked(!isChecked)
      }}
    />
  )
}
