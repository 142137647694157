import {
  mockSdkConfigResponse,
  mockSdkFieldsResponse,
  mockSdkDataResponse,
} from '@looker/visualizations-adapters'

// eslint-disable-next-line no-restricted-imports
import type { ILooker40SDK } from '@looker/sdk'
import type { SDKResponse } from '@looker/sdk-rtl'

type Response = SDKResponse<any, any>

export const mockSDK: Partial<ILooker40SDK> = {
  query_for_slug: (...args: any[]) =>
    Promise.resolve({
      ok: true,
      value: { id: 126, vis_config: mockSdkConfigResponse },
    } as Response),
  run_query: (...args: any[]) =>
    Promise.resolve({
      ok: true,
      error: null,
      value: {
        data: mockSdkDataResponse,
        fields: mockSdkFieldsResponse,
      },
    } as Response),
  query: (...args: any[]) =>
    Promise.resolve({
      ok: true,
      value: { vis_config: mockSdkConfigResponse },
    } as Response),
}
