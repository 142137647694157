import React from 'react'
import { Box, Card, Flex, Heading, Link, Text } from '@looker/components'
import { lowerCase } from 'lodash'
import styled from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useQueryParams, StringParam } from 'use-query-params'

import { TempSideBar } from '../SideBar'
import Layout from '../Layout'
import SubtypeSelector from '../Content/SubtypeSelector'
import { ProductSplashImage } from '../Content/ProductSplashImage'
import { MarketingBanner } from './MarketingBanner'

interface Edge {
  node: {
    slug: string
    productArea: string
    subtype: string
    index: number
    headerTitle: string
    headerTagline: string
    headerLinks?: {
      links: Array<HeaderLink>
    }
    content: {
      childMdx: {
        id: string
        body: string
      }
    }
  }
}

interface PageContext {
  pageContext: {
    page: Array<Edge>
  }
}

interface HeaderLink {
  name: string
  href: string
}

export interface SubType {
  index: number
  subType: string
  content: string
}

enum EmbedSubtype {
  public = 'public',
  private = 'private',
  sso = 'sso',
}

const getSubtypeDescription = (type: EmbedSubtype) => {
  if (type === 'public') {
    return "I'm trying to embed a Looker visualization on my site or app so that anyone in the world can access the content easily."
  } else if (type === 'private') {
    return 'I’m trying to embed a Looker visualization on my site or app and I need people to login to their Looker account to access the content.'
  } else if (type === 'sso') {
    return "I'm trying to embed a Looker visualization on my site or app so that anyone who is logged in with SSO can view the content."
  }
  return "I'm trying to embed Looker content."
}

const GettingStarted = (pageContext: PageContext) => {
  const pages: Array<Edge> = pageContext.pageContext.page

  //TODO: REWORK THE ENTIRE GETTING STARTED STRUCTURE. IT STINKS!

  //There are multiple pages per productArea sometimes (ie: Python / Ruby / TS) so we grab this global info from the first one.
  //No real reason, just saves some re-renders and more complex logic. One day perhaps we'd want them to be customized.
  //These are brittle— that's OK. We don't want a page render to succeed without the right information here.
  const node = pages[0].node
  const productArea = node.productArea
  const headerTitle = node.headerTitle
  const headerTagline = node.headerTagline
  const headerLinks = node.headerLinks ? node.headerLinks['links'] : undefined //It's OK for there to be no links.

  const [query, setQuery] = useQueryParams({
    language: StringParam,
    embed_method: StringParam,
    extension_language: StringParam,
    marketplace_type: StringParam,
  })

  //TODO: Persist this
  const [activeSection, setActiveSection] = React.useState<any>(
    productArea == 'api'
      ? query.language ?? 'python'
      : productArea == 'embed'
      ? query.embed_method ?? 'public'
      : productArea == 'extensions'
      ? query.extension_language ?? 'react'
      : productArea == 'marketplace'
      ? query.marketplace_type
      : undefined
  )

  const subTypes: Array<SubType> = pages
    .map((edge: Edge) => {
      return {
        index: edge.node.index,
        subType: edge.node.subtype.trim(),
        content: edge.node.content.childMdx.body,
      }
    })
    .sort((a, b) => (a.index > b.index ? 1 : -1)) //We sort them by index (CMS provided) to return the button tabs in the correct order.

  React.useEffect(() => {
    if (productArea == 'embed') {
      setActiveSection(query.embed_method || 'private')
    } else if (productArea == 'api') {
      setActiveSection(
        query.language ? lowerCase(query.language).replace(' ', '-') : 'python'
      )
    } else if (productArea == 'extensions') {
      setActiveSection(
        query.extension_language
          ? lowerCase(query.extension_language).replace(' ', '-')
          : 'react'
      )
    } else if (productArea == 'marketplace') {
      setActiveSection(
        query.marketplace_type
          ? lowerCase(query.marketplace_type).replace(' ', '-')
          : 'blocks'
      )
    }
  }, [query])

  const handleSectionUpdate = (e: string) => {
    const newValue = lowerCase(e).replace(' ', '-')
    if (productArea == 'embed') {
      setQuery({ embed_method: newValue.replace('-embed', '') })
    } else if (productArea == 'api') {
      setQuery({ language: newValue })
    } else if (productArea == 'extensions') {
      setQuery({ extension_language: newValue })
    } else if (productArea == 'marketplace') {
      setQuery({ marketplace_type: newValue })
    }
  }

  return (
    <Layout
      defaultNav={false}
      headerSearch={true}
      showQuotes={false}
      title={headerTitle}
    >
      <PageContainer>
        <MarketingBanner />
        <ProductSplashImage section={productArea} />
        <Box paddingTop="xlarge" id="header">
          <Box maxWidth="600px">
            <Heading
              as="h1"
              fontSize="xxxxxlarge"
              fontWeight="normal"
              paddingBottom="u5"
            >
              {headerTitle}
            </Heading>
            <Text color="text3" fontSize="large">
              {headerTagline}
            </Text>
            <Box paddingTop="large" id="headerLinks">
              {headerLinks &&
                headerLinks.map((headerLink) => {
                  ;<Link href={headerLink.href}>
                    <Text fontSize="large" color="#1A73E8">
                      {headerLink.name} |
                    </Text>
                  </Link>
                })}
            </Box>
          </Box>
          <SubtypeSelector
            subTypes={subTypes}
            activeSection={activeSection}
            handleSectionUpdate={handleSectionUpdate}
          />
          {productArea == 'embed' && (
            <Box pt="medium" id="subtypedesc" maxWidth="700px">
              <SubtypeDescCard p="large">
                <Text color="text4">
                  {getSubtypeDescription(activeSection)}
                </Text>
              </SubtypeDescCard>
            </Box>
          )}
        </Box>
        <ContentBox id="contentBox">
          <MDXBox>
            <MDXRenderer>
              {subTypes?.filter((item) =>
                item.subType.includes(lowerCase(activeSection))
              )[0]?.content ||
                subTypes[0]?.content ||
                ''}
            </MDXRenderer>
          </MDXBox>
          <TempSideBar />
        </ContentBox>
      </PageContainer>
    </Layout>
  )
}

export default GettingStarted

export const PageContainer = styled.div`
  max-width: 1340px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  padding: 0 ${(props) => props.theme.space.large};
`

export const ContentBox = styled(Flex)`
  padding-top: ${(props) => props.theme.space.xxlarge};
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: row;
  }
  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      flex-direction: row;
    }
  }
`

export const MDXBox = styled(Box)`
  max-width: auto;
  padding-right: 0;
  padding-bottom: ${(props) => props.theme.space.xxxlarge};
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    max-width: calc(100% - 320px);
    padding-right: ${(props) => props.theme.space.xxxxlarge};
  }
  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      max-width: calc(100% - 320px);
      padding-right: ${(props) => props.theme.space.xxxxlarge};
    }
  }
`

const SubtypeDescCard = styled(Card)`
  background: ${(props) => props.theme.colors.keySubtle};
  border-color: transparent;
  :hover {
    border-color: transparent;
  }
`
