import React, { FC } from "react"
import { Card, Flex, Heading, Text } from "@looker/components"
import styled from "styled-components"

import {
  ExternalLink
} from '../Content'

export interface SideBarCardProps {
  title: string
  content: string | undefined
  link: string
  cta?: string
  icon?: string
}

export const SideBarCard: FC<SideBarCardProps> = (props) => {
  return (
    <SideCard borderRadius="8px" marginBottom="xlarge">
      <ExternalLink href={props.link}>
        <Flex flexDirection="column">
          <Heading paddingBottom="large" color="key">
            {props.title}
          </Heading>
          <Text marginBottom="0" color="text3">
            {props.content}
          </Text>
        </Flex>
      </ExternalLink>
    </SideCard>
  )
}

export const SideCard = styled(Card)`
  > a {
    text-decoration: none;
    padding: ${(props) => props.theme.space.xlarge}
      ${(props) => props.theme.space.xlarge} 0
      ${(props) => props.theme.space.xlarge};

    &:hover,
    &:active,
    &:focus { text-decoration: none; }

    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      padding: ${(props) => props.theme.space.xlarge}
        ${(props) => props.theme.space.xlarge} 0
        ${(props) => props.theme.space.xlarge};
    }

    @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
      padding: ${(props) => props.theme.space.xlarge};
    }
  }

  .nav-hiding & {
    > a {
      @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
        padding: ${(props) => props.theme.space.xlarge};
      }
    }
  }

  ${Flex} {
    position: relative;
  }
`
