import React from 'react'
import {
  Flex,
  Heading,
  Text,
  Button,
} from '@looker/components'

import Image404 from '../images/404.svg'
import Layout from '../components/Layout'

const PageNotFound = () => {
  return (
    <Layout defaultNav={false} headerSearch={false}>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <div style={{ height: '4rem' }}></div>
        <div
          className="nav-hiding"
          style={{ flex: '1 1 0%', overflowY: 'auto' }}
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="90%"
          >
            <img src={Image404} width="248px " />
            <Heading paddingBottom="small" paddingTop="medium">
              Oops! Page cannot be found.
            </Heading>
            <Text paddingBottom="xlarge" color="text3">
              Looks like we're off the beaten track. Let's try going somewhere
              else.
            </Text>
            <form action="/">
              <Button type="submit">Back to Home</Button>
            </form>
          </Flex>
        </div>
      </div>
    </Layout>
  )
}

export default PageNotFound
