import React from 'react'
import { useLocation, Link } from '@reach/router'

const ComponentsRedirect = () => {
  //Note: Pages which exist on our site (e.g. overview, getting-started) will be routed before this redirector
  
  const location = useLocation()
  const subpath = location.pathname.replace(/^\/components/,"")
  
  const base = "https://components.looker.com/"
  let destination
  if(subpath==="" || subpath === "/"){
	  destination = "/components/overview"
  }
  if(!destination) {
	  destination = base
  }

  if(location.search){
	  destination += location.search
  }
  
  //The build process uses node which doesn't have the window object defined. 
  const isBrowser = typeof window !== "undefined"
  if(isBrowser){
    //Using a Javascript based redirect because React's redirect didn't want to load under @reach's 
    // router, and @reach's router did not properly handle external URLs
    window.location.replace(destination)
  }
  return <>
    <Link to={destination}>Looker Components docs</Link>
  </>
}

export default ComponentsRedirect
