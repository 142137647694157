import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../../components/Layout/SectionOverview';
import { Link, Button, Card, CardContent } from '@looker/components';
import Img from '../../../images/GettingStarted/embedprivate.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Get started with private embedding" headerTitle="Get started with private embedding">{children}</SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Card marginBottom="medium" color="neutral" maxWidth="800px" mdxType="Card"><CardContent mdxType="CardContent"><b>Knowledge check:</b> This page assumes you know the basics of Inline Frame &lt;iframe> HTML elements, Uniform Resource Locators (URLs), and third-party cookies. Check out the MDN web documentation on <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe" mdxType="Link">iframes</Link> to familiarize yourself with the basics.
      </CardContent></Card>
    <p>{`This page will walk you through how to set up private embedding. The private embedding option requires that you manage the `}{`<`}{`iframe> HTML element directly to embed your Looker content and requires that the user log in to Looker separately from the host application.`}</p>
    <p>{`We will walk through this private embedding code example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html",
        "metastring": "static=true",
        "static": "true"
      }}>{`<iframe
    src="https://instance.looker.com/embed/dashboards/4?Timeframe=14+day"
    width="600"
    height="300"
    frameborder="0">
</iframe>
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note: The following directions apply to all embeddable Looker content: `}<Link href="https://cloud.google.com/looker/docs/creating-user-defined-dashboards" mdxType="Link">{`dashboards`}</Link>{`, `}<Link href="https://cloud.google.com/looker/docs/building-lookml-dashboards" mdxType="Link">{`LookML dashboards`}</Link>{`,  `}<Link href="https://cloud.google.com/looker/docs/exploring-data" mdxType="Link">{`Explores`}</Link>{`, `}<Link href="https://cloud.google.com/looker/docs/saving-and-editing-looks" mdxType="Link">{`Looks`}</Link>{`, query visualizations, and `}<Link href="https://developers.looker.com/extensions/overview" mdxType="Link">{`extensions`}</Link>{`.`}</p>
    </blockquote>
    <h1>{`1. Build the embed content URL`}</h1>
    <p>{`Let’s consider an example of Looker content at the URL `}<inlineCode parentName="p">{`https://instance.looker.com/dashboards/4?theme=red&Timeframe=14+day`}</inlineCode>{`. From this Looker content URL, we will construct the`}<strong parentName="p">{` embed content URL`}</strong>{` and set the iframe’s `}<inlineCode parentName="p">{`src`}</inlineCode>{` attribute to it. Feel free to use your own Looker content URL as you work through these steps.`}</p>
    <p>{`The embed content URL from the above code sample is:`}</p>
    <div style={{
      "marginBottom": "1rem"
    }}><span style={{
        "fontSize": "0.9em",
        "fontFamily": "'Menlo','Lucida Console',monospace",
        "color": "black"
      }}>https://<span style={{
          "borderRadius": "3px",
          "backgroundColor": "#f9e237",
          "padding": "3px"
        }}>instance.looker.com</span><span style={{
          "borderRadius": "3px",
          "backgroundColor": "#65d837",
          "padding": "3px"
        }}>/embed/dashboards/4</span><span style={{
          "borderRadius": "3px",
          "backgroundColor": "#2bb0fc",
          "padding": "3px"
        }}>?theme=red&Timeframe=14+day</span></span></div>
    <p>{`The embed content URL consists of three parts:`}</p>
    <ul>
      <li parentName="ul">
        <span style={{
          "borderRadius": "3px",
          "backgroundColor": "#f9e237",
          "padding": "3px"
        }}>Hostname</span>: your Looker instance’s hostname
      </li>
      <li parentName="ul">
        <span style={{
          "borderRadius": "3px",
          "backgroundColor": "#65d837",
          "padding": "3px"
        }}>Path</span>: Looker content URL path prefixed with `/embed` (with extra steps for query visualizations)
      </li>
      <li parentName="ul">
        <span style={{
          "borderRadius": "3px",
          "backgroundColor": "#2bb0fc",
          "padding": "3px"
        }}>Parameters</span>: URL parameters that specify filters and theming
      </li>
    </ul>
    <p>{`The protocol must always be `}<inlineCode parentName="p">{`https://`}</inlineCode>{`. Let’s build each part in detail.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: As a shortcut, think of the `}<strong parentName="p">{`embed content URL`}</strong>{` as your complete Looker content URL with `}<inlineCode parentName="p">{`embed/`}</inlineCode>{` prefixed to its path (unless you’re embedding query visualizations noted below).`}</p>
    </blockquote>
    <h2>{`Part 1: Hostname`}</h2>
    <ol>
      <li parentName="ol">{`Navigate to your Looker content. Following the example Looker content URL: `}<inlineCode parentName="li">{`https://instance.looker.com/dashboards/4?theme=red&Timeframe=14+day`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Your embed content URL hostname is `}<inlineCode parentName="li">{`instance.looker.com`}</inlineCode>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Note: The hostname includes the port number, if present, e.g., `}<inlineCode parentName="p">{`instance.looker.com:9999`}</inlineCode>{`.`}</p>
    </blockquote>
    <h2>{`Part 2: Path`}</h2>
    <p>{`Your embed content URL path depends on the Looker content you embed.`}</p>
    <h3>{`Embedding all Looker content except query visualizations`}</h3>
    <ol>
      <li parentName="ol">{`Navigate to your Looker content. Following the example Looker content URL: `}<inlineCode parentName="li">{`https://instance.looker.com/dashboards/4?theme=red&Timeframe=14+day`}</inlineCode></li>
      <li parentName="ol">{`Identify your Looker content URL’s path: `}<inlineCode parentName="li">{`/dashboards/4`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Prefix `}<inlineCode parentName="li">{`/embed`}</inlineCode>{` onto your Looker content URL's path. Your embed content URL's path is `}<inlineCode parentName="li">{`/embed/dashboards/4`}</inlineCode>{`.`}</li>
    </ol>
    <h3>{`Embedding a query visualization`}</h3>
    <ol>
      <li parentName="ol">{`Navigate to your query visualization. Example URL: `}<inlineCode parentName="li">{`instance.looker.com/explore/my_model/my_explore?qid=1234567890abcdefghij12`}</inlineCode></li>
      <li parentName="ol">{`Identify your query’s client ID. The `}<inlineCode parentName="li">{`qid`}</inlineCode>{` parameter: `}<inlineCode parentName="li">{`1234567890abcdefghij12`}</inlineCode>{` is your query’s client ID that represents the query and the visualizations settings.`}</li>
      <li parentName="ol">{`Your embed content URL’s path is `}<inlineCode parentName="li">{`/embed/query-visualization/`}</inlineCode>{` appended with your query client ID. The example embed content URL's path is `}<inlineCode parentName="li">{`/embed/query-visualization/1234567890abcdefghij12`}</inlineCode></li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Note:You can also find your query’s client ID with Looker API’s `}<Link href="/api/explorer/4.0/methods/Query/query" mdxType="Link">{`Get Query`}</Link>{` method as the `}<inlineCode parentName="p">{`client_id`}</inlineCode>{` field of the `}<inlineCode parentName="p">{`Query`}</inlineCode>{` object.`}</p>
    </blockquote>
    <h2>{`Part 3: Parameters`}</h2>
    <p>{`Your embed content URL parameters control your embedded content’s filters and theming.`}</p>
    <h3>{`Filters`}</h3>
    <ol>
      <li parentName="ol">{`Navigate to your Looker content URL.`}</li>
      <li parentName="ol">{`Manually adjust the content’s filters to what you desire. For this example, your resulting Looker content URL is: `}<inlineCode parentName="li">{`https://instance.looker.com/dashboards/4?Timeframe=14+day`}</inlineCode></li>
      <li parentName="ol">{`Your embed content URL parameters are the Looker content URL parameters, e.g., `}<inlineCode parentName="li">{`Timeframe=14+day`}</inlineCode></li>
    </ol>
    <p>{`In this example, the parameter `}<inlineCode parentName="p">{`Timeframe=14+day`}</inlineCode>{` sets the value of the dashboard’s `}<inlineCode parentName="p">{`Timeframe`}</inlineCode>{` filter.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: The filter parameter does not create the filter, it must exist on the dashboard beforehand. `}</p>
    </blockquote>
    <h3>{`Theming`}</h3>
    <p>{`Check out the `}<Link href="https://docs.looker.com/admin-options/settings/themes#specify_theme" mdxType="Link">{`theming guide`}</Link>{` to read about how to control the appearance of your embedded content.`}</p>
    <h1>{`2. Test your embed content URL`}</h1>
    <p>{`Open your embed content URL in your browser to preview your embed content’s behavior and appearance.`}</p>
    <img style={{
      "maxWidth": "100%"
    }} src={Img} />
    <h1>{`3. Create your iframe`}</h1>
    <ol>
      <li parentName="ol">{`Create your iframe element in your host application.`}</li>
      <li parentName="ol">{`Set the `}<inlineCode parentName="li">{`src`}</inlineCode>{` attribute to your embed content URL.`}</li>
      <li parentName="ol">{`Define the `}<inlineCode parentName="li">{`width`}</inlineCode>{`, `}<inlineCode parentName="li">{`height`}</inlineCode>{`, and other attributes to what you need to best display your embedded Looker content.`}</li>
    </ol>
    <h1>{`4. Consider user access issues`}</h1>
    <p>{`The user must be logged in to Looker to view your embedded content. The iframe will show a 401 error page if the user is not logged in.`}</p>
    <h2>{`User login options`}</h2>
    <p>{`Your user can log in to Looker in one of two ways:`}</p>
    <h3>{`1. Log in to Looker beforehand`}</h3>
    <p>{`Your user must log in to Looker on the same browser before they can view the embedded content.`}</p>
    <h3>{`2. Enable an optional embed Looker login screen`}</h3>
    <p>{`Add `}<inlineCode parentName="p">{`allow_login_screen=true`}</inlineCode>{` to your embed content URL parameters to present a Looker login screen in the iframe if the user is not logged in.  Our example embed content URL becomes: `}<inlineCode parentName="p">{`https://instance_name.looker.com/embed/dashboards/4?Timeframe=14+day&allow_login_screen=true`}</inlineCode>{`.`}</p>
    <p>{`Keep in mind two caveats:`}</p>
    <ul>
      <li parentName="ul">{`You must `}<Link href="https://docs.looker.com/admin-options/platform/embed#same-origin_protections_for_looker_login_pages" mdxType="Link">{`disable the Same-Origin Protections for Looker Login Pages setting`}</Link>{` to enable the Looker login screen in the iframe embed.`}</li>
      <li parentName="ul">{`If your Looker instance authenticates users via single sign-on (SSO) with an identity provider, your identity provider may block the login screen within your iframe. You will need to use option 1 if this happens.`}</li>
    </ul>
    <h2>{`User browser cookie policy`}</h2>
    <p>{`Looker uses cookies for user authentication and session storage. Your user’s browser must enable third-party cookies if your user accesses your Looker instance embed content url’s hostname is under a different domain from your host application.`}</p>
    <p>{`Some browsers, such as Firefox and Safari, default to a cookie policy that blocks third-party cookies. If the user’s browser cannot allow third-party cookies, you can `}<Link href="https://community.looker.com/common-account-questions-1024/how-do-i-get-a-custom-url-domain-25958" mdxType="Link">{`add a custom domain to your Looker instance`}</Link>{` so your host application and embed content url’s hostnameLooker instance reside under the same domain.`}</p>
    <h1>{`5. Interact with your iframe`}</h1>
    <p>{`Get started with `}<Link href="/embed/advanced-embedding/embed-javascript-events" mdxType="Link">{`Looker embedding iframe post messaging`}</Link>{`.`}</p>
    <h1>{`Next steps`}</h1>
    <p>{`Get started`}<Link href="/embed/embed-sdk/sso-embed-with-embed-sdk" mdxType="Link">{` with SSO Embedding using our Embed SDK`}</Link>{` and check out `}<Link href="https://github.com/looker-open-source/LookerEmbedReference" mdxType="Link">{`examples`}</Link>{` of what you can do with Looker embedding.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      