import React from 'react'
import type { FormEvent } from 'react'
import type {
  CArea,
  CBar,
  CColumn,
  CLine,
  CScatter,
  CSparkline,
  CSeriesBasic,
} from '@looker/visualizations'
import { FieldColor } from '@looker/components'

/**
 * A list of relevant charts that access this configuration
 */
export type CColorSupported =
  | CArea
  | CBar
  | CColumn
  | CLine
  | CScatter
  | CSparkline

const renderFor: Array<CColorSupported['type']> = [
  'area',
  'bar',
  'column',
  'line',
  'scatter',
  'sparkline',
]

export type SeriesColorProps = {
  chartType: CColorSupported['type']
  series: CSeriesBasic
  onSeriesChange: (series: CSeriesBasic) => void
  disabled: boolean
}

export const SeriesColor = (props: SeriesColorProps) => {
  const { chartType, series, onSeriesChange, ...restProps } = props

  if (!renderFor.includes(chartType)) {
    // Early return! Only render for supported charts
    return null
  }

  const handleChange = (e: FormEvent) => {
    const draft = { ...series, color: (e.target as HTMLInputElement).value }
    onSeriesChange(draft)
  }

  return (
    <FieldColor
      label="Color"
      onChange={handleChange}
      value={series.color}
      {...restProps}
    />
  )
}
