import { startCase, isEmpty } from 'lodash'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import React, { FC, useState, useEffect, MouseEvent } from 'react'
import { Aside, Box, Heading, Icon, Space } from '@looker/components'
import {
  ArrowBack,
  KeyboardArrowDown,
  KeyboardArrowUp,

} from '@styled-icons/material'
import { Link } from 'gatsby'
import { HeaderLeadingCornerLayout } from '../Layout/Header/HeaderLeadingCorner'
import { useSitemap } from './useSitemap'
import { useTopMenuData } from '../../utils/useTopMenuData'
import { NavigationPage, NavigationProps } from '../../types/navigation'
import queryString from 'query-string'
import {defaultBreakpointWidth} from '.'

const variantParametersByProductArea : Record<string, any> = {
  api: {
    key: 'language',
    default: 'python',
  },
  embed: {
    key: 'embed_method',
    default: 'public-embed',
  },
  extensions: {
    key: 'extension_language',
    default: 'react',
  },
  marketplace: {
    key: 'marketplace_type',
    default: 'extensions',
  }
}

const navRoot = ''
const gettingStarted = 'getting-started'
const overview = 'overview'

export const Navigation: FC<NavigationProps> = (props) => {
  const {closeNavigation, children} = props
  const breakpointWidth = props?.theme?.breakpoints?.[2] || defaultBreakpointWidth

  const location = useLocation()
  const topMenuData = useTopMenuData()
  const sitemap = useSitemap()

  const [isNarrowViewport, setIsNarrowViewport] = useState(true) //When the viewport is narrow, nav should allow "drilling up" to replace top nav menu

  // These state elements act as keys that, when applied to site data, determine the entire rest of the nav state
  const [navPath, setNavPath] = useState([navRoot]) //Path from root to nav's current point in site hierarchy. (For now only length 1 or 2)
  const [activePath, setActivePath] = useState([] as string[]) //Within current nav, which links are highlighted indicating the user's current location
  const [expandPath, setExpandPath] = useState([] as string[]) //Within current nav, which link is currently expanded by interaction
  const [pageVariant, setPageVariant] = useState(undefined as string|undefined) //Getting started pages have the ability to vary internally based on a parameter, and this can affect in-page navigation

  // These state elements are derived from they above state + site structure data, within the updateLinks effect
  // Saving these derived values to state lets us minimize computation during the component function call
  const [links, setLinks] = useState([] as any[])
  const [navCurrentTitle, setNavCurrentTitle] = useState("")
  const [navDrillUpTitle, setNavDrillUpTitle] = useState("")
  const [navDrillUpHref, setNavDrillUpHref] = useState("")

  useEffect(addResizeListener,[])
  useEffect(resetNavPaths, [])
  useEffect(updateLinks,[location, navPath, expandPath, activePath, pageVariant, isNarrowViewport])

  if (!links) return null

  return <NavigationLayout>
    <NavigationLightbox onClick={closeNavigation}>
      <Aside as="nav" width="320px"
        style={{
          height: `100vh`,
          overflow: 'scroll',
          backgroundColor: 'white',
          position: 'fixed'
        }}
        onClick={(evt)=>{evt.stopPropagation()} /* Prevent click navigtion to lighbox from closing the nav panel*/ }
      >
        {isNarrowViewport &&
          <HeaderLeadingCornerLayout toggleNavigation={closeNavigation} />
        }
        <Box className={ navPath.length>1 ? "deep":"" }>
          { !!navDrillUpTitle &&
            <Space between paddingTop="large">
              <BackLink to={navDrillUpHref} onClick={drillUp}>
                <Space align="center">
                  <Icon icon={<ArrowBack />} display="inline-flex" size="xxsmall" />
                  {navDrillUpTitle}
                </Space>
              </BackLink>
            </Space>
          }
          <Heading as="h5" color="key" fontWeight="bold">
            {navCurrentTitle}
          </Heading>
          {/* If props had children, use them (so APIX and Components can provide their own nav), else go with our "default" deep nav */}
          {children ??
            <SideBarLinkList className={ navPath.length>1 ? "deep":"" } >
              {links.map((link:any) =>
                  <li key={link.path}>
                    <SideBarLink
                      to={link.path}
                      onClick={
                        link.expander == 'expander' ? expand(link.path)
                        : link.expander == 'collapser' ? collapse(link.path)
                        : undefined
                      }
                      className={link.active ? 'active' : ''}
                    >
                      <Space between>
                        <span>{link.title}</span>
                        {
                          link.expander == 'expander' ? <Icon icon={<KeyboardArrowDown/>} size="small" />
                          : link.expander == 'collapser' ? <Icon icon={<KeyboardArrowUp/>} size="small" />
                          : <span />
                        }
                      </Space>
                    </SideBarLink>
                    {link.sublinks &&
                      <SideBarSublinkList>
                        {link.sublinks
                          .map((sublink:any) =>
                            <li key={sublink.path}>
                              <a
                                href={sublink.path}
                                className={sublink.active ? 'active' : ''}
                              >
                                {sublink.title}
                                &nbsp;
                                {sublink.external && <External />}
                              </a>
                            </li>
                          )}
                      </SideBarSublinkList>
                    }
                  </li>
              )}
            </SideBarLinkList>
          }
        </Box>
      </Aside>
    </NavigationLightbox>
  </NavigationLayout>

  function addResizeListener(){
    onResize()
    window.addEventListener('resize',onResize)
    return ()=>window.removeEventListener('resize',onResize)
  }
  function onResize(){
    const viewportWidth = window.innerWidth
    console.log({viewportWidth})
    if(viewportWidth < breakpointWidth){
      setIsNarrowViewport(true)
    }
    else {
      setIsNarrowViewport(false)
      if(navPath.length<=1){
        // When menu is drilled up while narrow, and window becomes not narrow, reset nav paths
        // Theoretically, this should be when navPath.length < maxNavDepth, but for now maxNavDepth is always 1 or 0
        resetNavPaths()
      }
    }
  }
  function resetNavPaths(){
    const qs = queryString.parse(location.search)
    const path = location.pathname.split('/')
    const hash = location.hash.replace(/^#/,'') //Strip leading hash character

    // The first component of the path is always the "product area" - api, embed, etc
    const productArea = path[1]

    // The second component of the path, let's call it section, is largely a freeform second level of hierarchy, except...
    // We don't currently support bare product area paths, like `/api`, `/embed`, so section will
    // be set everywhere other than the homepage, but in the case of getting started, the nav works differently...
    const section = path[2]

    // Conversely, we do allow bare section paths, like `/api/advanced-usage` and
    // show content that we represent within the nav as an "overview"
    // ...at the same hierarchy level as the content it indexes
    const slug = path[3] || (section && section !== gettingStarted ? overview : undefined)

    const variantParameter = variantParametersByProductArea[productArea] || {}
    const variant = qs[variantParameter.key] || variantParameter.default

    const isGettingStartedPage = section == gettingStarted

    setPageVariant(variant)
    if(!section || section == gettingStarted){
      // As per historical behavior, and for the time being, getting started pages' navigation acts as though it were an
      // index page (e.g. `/api/getting-started` has top level nav as though it were `/api`. `/api` does not currently exist)
      setNavPath([navRoot])
      setExpandPath([productArea])
      setActivePath([productArea,section])
    }
    else {
      // Without endorsing this behavior: we pair levels of hierarchy, so skip product area for nav path,
      // it is instead collapsible within the root level rather than being its own level
      setNavPath([navRoot,section])
      setExpandPath([slug].filter(Boolean) as string[])
      setActivePath([slug,hash].filter(Boolean) as string[] )
    }
  }
  function updateLinks(){
    if(navPath.length == 1){
      updateLinksAsTop()
    }
    else {
      updateLinksAsDeep()
    }
  }

  function drillUp(evt:MouseEvent<HTMLAnchorElement>){
    evt.preventDefault()
    setNavPath([navRoot])
    const path = location.pathname.split('/')
    const productArea = path[1]
    const section = path[2]
    setExpandPath([productArea])
    setActivePath([productArea,section])
  }
  function expand(linkPath:string){
    return function expandHandler(evt:MouseEvent<HTMLAnchorElement>){
      evt.preventDefault()
      const pathParts = linkPath.split('/')
      const expandPath = pathParts.slice(1,-1) //Drop first empty string element, and last most granular part of path
      setExpandPath(expandPath)
    }
  }
  function collapse(linkPath:string){
    return function collapseHandler(evt:MouseEvent<HTMLAnchorElement>){
      evt.preventDefault()
      const pathParts = linkPath.split('/')
      const expandPath = pathParts.slice(1,-2) //Drop first empty string element, the last most granular part of path, and one additional most granular part
      setExpandPath(expandPath)
    }
  }

  function updateLinksAsTop(){
    setNavCurrentTitle("")
    setNavDrillUpTitle("")
    setNavDrillUpHref(`/`)

    const links = topMenuData.menuLinks.map(menuLink=>{
      const active = ("/"+activePath.join("/")).indexOf(menuLink.link) == 0
      const expanded = ("/"+expandPath.join("/")).indexOf(menuLink.link) == 0
      return {
        title: menuLink.name,
        path: menuLink.link+"/"+gettingStarted, //We don't have proper index pages... getting started is the convention for now.
        active,
        expander:
          !menuLink.subMenu ? 'none'
          : menuLink.subMenu.length == 0  ? 'none'
          : expanded ? 'collapser'
          : 'expander',
        sublinks: expanded && menuLink.subMenu.map(subLink=>{
          const active = ("/"+activePath.join("/")).indexOf(subLink.link) == 0
          return {
            title:subLink.name,
            path:subLink.link,
            active,
            external: subLink.link[0] !== '/'
          }
        })
      }
    })

    setLinks(links)
  }

  function updateLinksAsDeep(){
    const qs = queryString.parse(location.search)

    const [,productArea,section,slug] = location.pathname.split('/')
    const subsectionParameter = variantParametersByProductArea[productArea] || {}
    const subsectionId = qs && qs[subsectionParameter.key] || subsectionParameter.default
    const isGettingStartedPage = section == gettingStarted

    if(isNarrowViewport){
      setNavDrillUpTitle(startCase(productArea)) //TODO: This should come from Contentful content, not from the URL
      setNavDrillUpHref(`/${productArea}/${gettingStarted}`)
    }
    else {
      setNavDrillUpTitle("")
      setNavDrillUpHref(`/`)
    }

    setNavCurrentTitle(startCase(section))

    const productAreaPages = sitemap?.[productArea] ?? []
    const filteredPages = productAreaPages.filter((page) =>
      // getting-started URLs should list the pages under this product area that include getting-started in the slug
      // and have a matching "getting started type" (querystring parameter)
      isGettingStartedPage && page.slug?.includes(gettingStarted) && page.gettingStartedType == subsectionId
      // If the URL slug is not exactly "getting-started" but includes it (???) then inclde all getting started pages in the product area
      || !isGettingStartedPage && section?.includes(gettingStarted) && page.slug?.includes(gettingStarted)
      // If the URL has nothing to do with getting started, and the page's "section" matches the URL's "section"
      || !isGettingStartedPage && !section?.includes(gettingStarted) && page.section == section
    )

    const sortedPages = isGettingStartedPage ? filteredPages : filteredPages.sort((a, b) => (a.index > b.index ? 1 : -1))
    const sortedPagesWithPath = sortedPages.map((page) => ({
      ...page,
      path: '/'+[page.productArea, page.section, page.slug].filter(Boolean).join('/'),
    }))
    const links = sortedPagesWithPath
      .map(page => {
        // In the "deep" navigation, the overview (whose URL is of the form /area/section ) 
        // is displayed visually at the same level as the URLs of the form /area/section/slug, so we need to use more of an exact match for active logic
        const active = location.pathname.replace(/\/$/,"") == page.path
        return {
            title: startCase(page.title),
          path: page.path,
          active,
          sublinks: active && (page.headings||[])
            .filter((heading:any) => heading?.depth == 1)
            .map(heading=> ({
              title: startCase(heading.value),
              path: '#'+heading.anchor,
              active: location.hash == `#${heading.anchor}`
            }))
        }
      })
    setLinks(links)
  }
}


export const NavigationLayout = styled.div`
  @media (max-width: ${(props) => props?.theme?.breakpoints?.[2] || defaultBreakpointWidth}) {
    position: fixed;
    z-index:99;
    height: 100vh
  }
  @media (min-width: ${(props) => props?.theme?.breakpoints?.[2] || defaultBreakpointWidth}) {
    position: absolute;
    height: 100%;
  }
  min-height: 100vh;
  border-right: 1px solid #dee1e5;
  font-family: 'Google Sans';

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
  button {
    background: transparent;
    &.selected {
      color: ${(props) => props.theme.colors.text5};
      font-weight: 700;
    }
    &:hover {
      background: transparent;
      color: ${(props) => props.theme.colors.text3};
      font-weight: 700;
    }
  }

  ${Aside} {
    border-right: solid 1px ${(props) => props.theme.colors.ui2};
  }

  ${Box} {
    padding-left: 14px;
    padding-right: 14px;
    ${Heading} {
      padding-top: ${(props) => props.theme.space.medium};
      padding-bottom: 0;
    }

    &.deep {
      padding-left: 22px;
      padding-right: ${(props) => props.theme.space.large};

      ${Heading} {
        padding-top: ${(props) => props.theme.space.medium};
        padding-bottom: ${(props) => props.theme.space.xsmall};
      }
    }
  }
`

export const BackLink = styled(Link)`
  color: ${(props) => props.theme.colors.text4} !important;
  font-size: ${(props) => props.theme.fontSizes.large};
  position: relative;
  left: -2px;

  ${Icon} {
    color: ${(props) => props.theme.colors.key};
    background: ${(props) => props.theme.colors.keySubtle};
    border-radius: 100px;
    padding: 4px;
    box-sizing: content-box;
    margin-right: ${(props) => props.theme.space.large} !important;
  }
`

export const NavigationLightbox = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    position: fixed;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.6);
  }
`

export const SideBarLinkList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 0;

  &.deep {
    padding-left: ${(props) => props.theme.space.xxsmall};
    border-left: 1px dashed ${(props) => props.theme.colors.ui2};
  }
`

export const SideBarLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSizes.small};
  display: block;
  color: ${(props) => props.theme.colors.text1};
  border-radius: ${(props) => props.theme.space.xxsmall};
  padding: ${(props) => props.theme.space.xsmall};

  &.active {
    color: ${(props) => props.theme.colors.key};
    font-weight: 600;
    padding: ${(props) => props.theme.space.xsmall};

    .deep & {
      background-color: ${(props) => props.theme.colors.ui1};
      color: ${(props) => props.theme.colors.text};
    }
  }

  &:hover {
    .deep & {
      background-color: ${(props) => props.theme.colors.ui1};
    }
  }
`

export const SideBarSublinkList = styled.ul`
  margin-left: ${(props) => props.theme.space.u2};
  padding-left: ${(props) => props.theme.space.xxsmall};
  list-style: none;
  border-left: 1px dashed ${(props) => props.theme.colors.ui2};

  li:first-child {
    margin-top: ${(props) => props.theme.space.xxsmall};
  }
  li:last-child {
    margin-bottom: ${(props) => props.theme.space.xxsmall};
  }

  a {
    display: block;
    color: ${(props) => props.theme.colors.text3};
    font-size: ${(props) => props.theme.fontSizes.small};
    padding: ${(props) => props.theme.space.xsmall};

    &:hover {
      color: ${(props) => props.theme.colors.text};
    }

    &.active {
      background-color: ${(props) => props.theme.colors.ui1};
      border-radius: ${(props) => props.theme.space.xxsmall};
      font-weight: 500;
      color: ${(props) => props.theme.colors.text5};

      .deep & {
        background-color: transparent;
      }
    }
  }

  .deep & {
    border-left: none;
    padding-left: ${(props) => props.theme.space.xsmall};

    a {
      font-size: ${(props) => props.theme.fontSizes.xsmall};
      padding: ${(props) => props.theme.space.xxsmall} ${(props) => props.theme.space.xsmall};

      &.active {
        color: ${(props) => props.theme.colors.text5};
      }
    }
  }
`

const External = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  padding-left:0;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z' fill='%23939BA5'/%3E%3C/svg%3E%0A");
`
