export const THEME_OVERRIDES = {
  loadGoogleFonts: true,
  loadFontSources: true,
  themeCustomizations: {
    fontFamilies: { brand: "Google Sans" },
    fonts: {
      body: "Google Sans",
    },
    colors: {
      //TODO: flesh out the full themeOverrides
      key: "#1A73E8",
      text3: "#4C535B",
      text5: "#262D33",
    },
  },
}
