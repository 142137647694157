import React from 'react'
import type { FormEvent } from 'react'
import type {
  CArea,
  CBar,
  CColumn,
  CLine,
  CScatter,
  CTable,
  CSeriesBasic,
} from '@looker/visualizations'
import { FieldText } from '@looker/components'

/**
 * A list of relevant charts that access this configuration
 */
export type CLabelSupported = CArea | CBar | CColumn | CLine | CScatter | CTable

const renderFor: Array<CLabelSupported['type']> = [
  'area',
  'bar',
  'column',
  'line',
  'scatter',
  'table',
]

export type SeriesLabelProps = {
  chartType: CLabelSupported['type']
  series: CSeriesBasic
  onSeriesChange: (series: CSeriesBasic) => void
}

export const SeriesLabel = (props: SeriesLabelProps) => {
  const { chartType, series, onSeriesChange } = props

  if (!renderFor.includes(chartType)) {
    // Early return! Only render for supported charts
    return null
  }

  const handleChange = (e: FormEvent) => {
    const draft = { ...series, label: (e.target as HTMLInputElement).value }
    onSeriesChange(draft)
  }

  return (
    <FieldText label="Label" onChange={handleChange} value={series.label} />
  )
}
