
import { useStaticQuery, graphql } from 'gatsby'
import { ISiteMenuData } from '../types/sitemetadata'

export function useTopMenuData(){
	const data: ISiteMenuData = useStaticQuery(graphql`
		query NavMenu {
			site {
				siteMetadata {
				title
				menuLinks {
					link
					name
					subMenu {
					link
					name
					}
				}
				}
			}
		}
	`)
	return data.site.siteMetadata
}