import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../components/Layout/SectionOverview';
import { Link, Button, Card, CardContent } from '@looker/components';
import ImgBackend from '../../images/GettingStarted/components/backend.png';
import ImgFrontend from '../../images/GettingStarted/components/frontend.png';
import ImgViz from '../../images/GettingStarted/components/vizScreenshot.png';
import { CodeSandbox } from '../../MDX/Pre/CodeSandbox';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Space = makeShortcode("Space");
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Get started with Looker visualization components" headerTitle="Get started with Looker visualization components">
    {children}
  </SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Embedding Looker contents via iframe is just one of the methods that developers can use when they want to add a Dashboard, Look, or Explore into their web application. This tutorial presents another method for developers that want to add a Looker visualization into a React App. This tutorial is based on a simple Create React App starter and uses `}<a parentName="p" {...{
        "href": "https://cloud.google.com/looker/docs/components-vis"
      }}>{`Looker Visualization components`}</a>{`.`}</p>
    <p>{`These are the steps covered in this tutorial:`}</p>
    <ol>
      <li parentName="ol">{`Get the query slug from Looker`}</li>
      <li parentName="ol">{`Create a React application with Looker Visualization components`}</li>
      <li parentName="ol">{`Create a backend helper service`}</li>
    </ol>
    <h1><strong parentName="h1">{`Get the query slug from Looker`}</strong></h1>
    <p>{`There are few things that must be done in Looker since the React app depends on them.`}</p>
    <h2>{`Get a query slug`}</h2>
    <p>{`You need the query id or slug that’ll be used as a prop of the visualization component. `}<a parentName="p" {...{
        "href": "https://help.looker.com/hc/en-us/articles/4420226434451-The-Looker-Explore-URL-Explained-Community-"
      }}>{`This article explains how you can get the query slug from an Explore URL`}</a>{`. Another example can be found in `}<a parentName="p" {...{
        "href": "https://cloud.google.com/looker/docs/components-vis-qid#step_1_build_a_query_in_an_explore_and_copy_the_queryclient_id_value"
      }}>{`Looker’s documentation`}</a>{`.`}</p>
    <h2>{`Configure CORS in your Looker instance`}</h2>
    <p>{`Cross-Origin Resource Sharing (CORS) is controlled by the same domain allowlist as embedding.`}</p>
    <p>{`This is documented in more detail on the `}<a parentName="p" {...{
        "href": "https://docs.looker.com/reference/embedding/sso-embed"
      }}>{`Single Sign-on (SSO) Embedding`}</a>{` documentation page.`}</p>
    <ol>
      <li parentName="ol">{`Navigate to `}<strong parentName="li">{`Admin > Platform Embed`}</strong>{` on your Looker instance. This requires Admin privileges.`}</li>
      <li parentName="ol">{`The React app runs by default at `}<inlineCode parentName="li">{`http://localhost:3000`}</inlineCode>{`. By adding this address to the `}<strong parentName="li"><a parentName="strong" {...{
            "href": "https://docs.looker.com/admin-options/platform/embed#embedded_domain_allowlist"
          }}>{`Embedded Domain Allowlist`}</a></strong>{` you are telling Looker to allow requests from the app and respond to those requests via the same address. This step is mandatory since the app will be making API requests to the Looker instance else there won’t be communication between Looker and the app.`}</li>
    </ol>
    <h1><strong parentName="h1">{`Create the React application`}</strong></h1>
    <p>{`The frontend of this demo uses `}<a parentName="p" {...{
        "href": "https://create-react-app.dev/docs/getting-started"
      }}>{`Create React App`}</a>{` to create the single-page React application. Run the following commands at the root folder of the demo (`}<inlineCode parentName="p">{`get-started-viz-components`}</inlineCode>{`) to create the app and install the dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html",
        "metastring": "static=true",
        "static": "true"
      }}>{`npx create-react-app frontend-react cd frontend-react npm i
@looker/visualizations npm i @looker/components @looker/components-data
styled-components
`}</code></pre>
    <p>{`After running these commands your folder structure should look like this:`}</p>
    <img style={{
      "maxWidth": "100%"
    }} src={ImgFrontend} />
    <p>{`Check the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file and make sure that `}<inlineCode parentName="p">{`react-dom`}</inlineCode>{` is also installed, else install it by running `}<inlineCode parentName="p">{`npm i react-dom`}</inlineCode>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` of this demo looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static=true",
        "static": "true"
      }}>{`{
  "name": "frontend-react",
  "version": "0.1.0",
  "private": true,
  "dependencies": {
    "@looker/components": "^4.0.3",
    "@looker/components-data": "^1.0.0",
    "@looker/sdk": "^22.16.0",
    "@looker/sdk-rtl": "^21.4.0",
    "@looker/visualizations": "^1.1.1",
    "@testing-library/jest-dom": "^5.16.5",
    "@testing-library/react": "^12.1.0",
    "@testing-library/user-event": "^12.4.0",
    "i": "^0.3.7",
    "npm": "^8.19.2",
    "react": "^16.14.0",
    "react-dom": "^16.14.0",
    "react-scripts": "5.0.1",
    "styled-components": "^5.3.6",
    "web-vitals": "^2.1.4"
  },
  "scripts": {
    "start": "react-scripts start",
    "build": "react-scripts build",
    "test": "react-scripts test",
    "eject": "react-scripts eject"
  },
  "eslintConfig": {
    "extends": [
      "react-app",
      "react-app/jest"
    ]
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  }
}
`}</code></pre>
    <h2>{`Configure the environment variables`}</h2>
    <p>{`Create a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file in the root directory of the app (`}<inlineCode parentName="p">{`./frontend-react`}</inlineCode>{`) and set the following variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static=true",
        "static": "true"
      }}>{`REACT_APP_LOOKER_API_HOST=https://your-looker-instance.looker.com
REACT_APP_BACKEND_SERVER=http://localhost:3001/
`}</code></pre>
    <p><inlineCode parentName="p">{`REACT_APP_BACKEND_SERVER`}</inlineCode>{` is the address of the backend helper service that we will use to make an API call to Looker to extract the access token.`}</p>
    <p><inlineCode parentName="p">{`REACT_APP_LOOKER_API_HOST`}</inlineCode>{` is the address of the Looker instance that will be receiving API requests from the React app.`}</p>
    <h2>{`Initialize client side SDK`}</h2>
    <p>{`The React app will use the SDK to make API requests to the Looker server. Since this is done on the front end, you can use the following helper to initialize the `}<inlineCode parentName="p">{`sdk`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static=true",
        "static": "true"
      }}>{`import { Looker40SDK } from '@looker/sdk'
import {
  AuthToken,
  AuthSession,
  BrowserTransport,
  DefaultSettings,
} from '@looker/sdk-rtl'

class SDKSession extends AuthSession {
  // This is a placeholder for the fetchToken function.
  // It is modified to make it useful later.
  async fetchToken() {
    return fetch('')
  }

  activeToken = new AuthToken()
  constructor(settings, transport) {
    super(settings, transport || new BrowserTransport(settings))
  }

  // This function checks to see if the user is already authenticated
  isAuthenticated() {
    const token = this.activeToken
    if (!(token && token.access_token)) return false
    return token.isActive()
  }

  // This function gets the current token or fetches a new one if necessary
  async getToken() {
    if (!this.isAuthenticated()) {
      const token = await this.fetchToken()
      const res = await token.json()
      this.activeToken.setToken(res.user_token)
    }
    return this.activeToken
  }

  // This function authenticates a user, which involves getting a new token
  // It returns a modified object with a new authorization header.
  async authenticate(props) {
    const token = await this.getToken()
    if (token && token.access_token) {
      props.mode = 'cors'
      delete props.credentials
      props.headers = {
        ...props.headers,
        Authorization: \`Bearer \${this.activeToken.access_token}\`,
      }
    }
    return props
  }
}

// This class sets the fetchToken to use the 'real' address of the backend server.
class SDKSessionEmbed extends SDKSession {
  async fetchToken() {
    return fetch(\`\${process.env.REACT_APP_BACKEND_SERVER}\`)
  }
}

// This creates a new session with the 'real' address used above
const session = new SDKSessionEmbed({
  ...DefaultSettings,
  base_url: process.env.REACT_APP_LOOKER_API_HOST,
})

// This exports the SDK with the authenticated session
export const sdk = new Looker40SDK(session)
`}</code></pre>
    <h2>{`Embed the visualization into the app`}</h2>
    <p>{`Now that you have the query slug (in our example it’s `}<inlineCode parentName="p">{`Jlm4YHPeT3lLGA9UtHjZcA`}</inlineCode>{`) of the visualization and the `}<inlineCode parentName="p">{`sdk`}</inlineCode>{` object has been instantiated, the next step is to use the Looker Visualization components to embed and render the visualization into the app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static=true",
        "static": "true"
      }}>{`import { sdk } from '../src/helpers/CorsSession'
import { Query, Visualization } from '@looker/visualizations'
import { DataProvider } from '@looker/components-data'
import { ComponentsProvider } from '@looker/components'

function App() {
  return (
    <>
      <h1>Get started with Looker visualization components</h1>
      <ComponentsProvider>
        <DataProvider sdk={sdk}>
          {/* Change this query slug to match your query slug */}
          <Query query="Jlm4YHPeT3lLGA9UtHjZcA">
            <Visualization />
          </Query>
        </DataProvider>
      </ComponentsProvider>
    </>
  )
}

export default App
`}</code></pre>
    <p>{`The frontend is ready. You can add more components, add more styling to the app, etc.`}</p>
    <h1><strong parentName="h1">{`Create a backend helper service`}</strong></h1>
    <p>{`The final step is to build the backend helper service that’ll receive the call from the frontend, use the Looker-Node SDK to authenticate the user, extract their access token then send it back to the frontend.`}</p>
    <p>{`For simplicity we’re going to build a Node server with one endpoint. The server will use `}<inlineCode parentName="p">{`express`}</inlineCode>{`, `}<inlineCode parentName="p">{`cors`}</inlineCode>{`, and `}<inlineCode parentName="p">{`@looker/sdk-node`}</inlineCode>{` dependencies. You can run the following commands starting at the root folder (`}<inlineCode parentName="p">{`get-started-viz-components`}</inlineCode>{`):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static=true",
        "static": "true"
      }}>{`mkdir backend-node
cd backend-node
npm init -y
npm i express cors @looker/sdk-node
`}</code></pre>
    <p>{`To authenticate the SDK from the backend we’ll use a `}<inlineCode parentName="p">{`looker.ini`}</inlineCode>{` file. You can find more detail on how to populate the file on `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/@looker/sdk-node#configure-the-sdk-for-your-looker-server"
      }}>{`the SDK-Node page`}</a>{`. After running these commands your folder structure should look like this:`}</p>
    <img style={{
      "maxWidth": "100%"
    }} src={ImgBackend} />
    <p>{`The `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` should look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static=true",
        "static": "true"
      }}>{`{
  "name": "looker-embed-backend",
  "version": "1.0.0",
  "description": "Backend helper service for getting started with Looker Viz components",
  "main": "server.js",
  "scripts": {
    "start": "node server.js"
  },
  "author": "Looker",
  "license": "Apache-2.0",
  "dependencies": {
    "@looker/sdk-node": "^22.16.0",
    "cors": "^2.8.5",
    "express": "^4.18.2"
  }
}
`}</code></pre>
    <p>{`Next we’ll add this code into a new `}<inlineCode parentName="p">{`server.js`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static=true",
        "static": "true"
      }}>{`const cors = require('cors')
const express = require('express')
const { LookerNodeSDK } = require('@looker/sdk-node')

const port = 3001
const app = express()
// The init40 method below will authenticate using
// the looker.ini file
const sdk = LookerNodeSDK.init40()

app.use(
  cors({
    origin: '*',
  })
)
app.use(express.json())

app.get('/', async (req, res) => {
  const userId = await sdk.ok(sdk.me('id'))
  const accessToken = await sdk.login_user(userId.id)
  const user = {
    user_token: accessToken.value,
    token_last_refreshed: Date.now(),
  }
  res.json({ ...user })
})

app.listen(port, async () => {
  console.log(\`Backend Server listening on port \${port}\`)
})
`}</code></pre>
    <h1><strong parentName="h1">{`Start the Server and the React App`}</strong></h1>
    <ul>
      <li parentName="ul">{`Open a terminal and navigate to the `}<inlineCode parentName="li">{`backend-node`}</inlineCode>{` folder then run `}<inlineCode parentName="li">{`npm start`}</inlineCode></li>
      <li parentName="ul">{`Open a second terminal then navigate to the `}<inlineCode parentName="li">{`frontend-react`}</inlineCode>{` folder and run `}<inlineCode parentName="li">{`npm start`}</inlineCode></li>
      <li parentName="ul">{`Once the backend helper service and the react app are up and running, you can open the browser and go to `}<a parentName="li" {...{
          "href": "http://localhost:3000/"
        }}><inlineCode parentName="a">{`http://localhost:3000/`}</inlineCode></a>{` to see the visualization embedded into the application.`}</li>
    </ul>
    <img style={{
      "maxWidth": "100%"
    }} src={ImgViz} />
    <Space mdxType="Space">
  <Link href="https://github.com/looker-open-source/component-examples/tree/main/Visualization/get-started-viz-components" mdxType="Link">
    <Button mdxType="Button">See the code in GitHub</Button>
  </Link>
    </Space>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      