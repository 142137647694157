import React from 'react'
import { Box, Heading } from '@looker/components'
import styled from 'styled-components'

import Layout from '../Layout'
import { ProductSplashImage } from '../Content/ProductSplashImage'
import { MarketingBanner } from './MarketingBanner'

interface SectionOverviewProps {
  pageTitle?: string
  headerTitle?: string
  splashImage?: string
  children?: React.ReactNode
}

const SectionOverview = ({pageTitle, headerTitle, splashImage, children}:SectionOverviewProps) => {
  return (
    <Layout
      defaultNav={false}
      headerSearch={true}
      showQuotes={false}
      title={pageTitle ?? headerTitle}
    >
      <PageContainer>
        <MarketingBanner />
        <ProductSplashImage section={splashImage} />
        <Box paddingTop="xlarge" id="header">
          <Box maxWidth="600px">
            <Heading
              as="h1"
              fontSize="xxxxxlarge"
              fontWeight="normal"
              paddingBottom="u5"
            >
              {headerTitle ?? pageTitle ?? ''}
            </Heading>
          </Box>
        </Box>
        <MDXBox>
          {children}
        </MDXBox>
      </PageContainer>
    </Layout>
  )
}

export default SectionOverview

export const PageContainer = styled.div`
  max-width: 1340px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  padding: 0 ${(props) => props.theme.space.large};
`

export const MDXBox = styled(Box)`
  max-width: auto;
  padding-right: 0;
  padding-bottom: ${(props) => props.theme.space.xxxlarge};
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    max-width: calc(100% - 320px);
    padding-right: ${(props) => props.theme.space.xxxxlarge};
  }
  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      max-width: calc(100% - 320px);
      padding-right: ${(props) => props.theme.space.xxxxlarge};
    }
  }
  h1, h2, h3 {margin-top:1.25em}
`
