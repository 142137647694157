import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../components/Layout/SectionOverview';
import { Link, Button, Card, CardContent } from '@looker/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Looker Actions Overiew" headerTitle="Actions" splashImage="actions">{children}</SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Looker’s Action API allows developers to define Actions, or custom destinations to which Looker can send query results, dashboard results, or user interactions.`}</p>
    <Card marginBottom="medium" color="neutral" maxWidth="800px" mdxType="Card"><CardContent mdxType="CardContent"><b>Note:</b> The <Link href="https://docs.looker.com/sharing-and-publishing/action-hub#the_looker_action_hub" mdxType="Link">Looker Action Hub</Link>, a Looker-hosted service that provides common, prebuilt Actions, is built on top of the Action API, but is covered on docs.looker.com rather than the Developer Portal.</CardContent></Card>
    <p>{`Once an Action is developed, deployed as a web service, and then added to a Looker instance, Looker users will be able to send data via that Action from within Looker.`}</p>
    <h1>{`Using Actions`}</h1>
    <p>{`The Action API supports a number of variations in use cases:`}</p>
    <ul>
      <li parentName="ul">{`Actions support various levels of user interactivity. They may be designed for ad-hoc user interactions while a user is viewing a dashboard, or they may be intended to work with recurring or triggered schedules.`}</li>
      <li parentName="ul">{`Actions can instruct Looker to collect form data from users, in order to parameterize the handling of the data.`}</li>
      <li parentName="ul">{`Actions can even request user authentication to a third party service via OAuth, for example to implicitly deliver data to user-specific destinations.`}</li>
      <li parentName="ul">{`Actions can also receive query results in various formats, from data oriented formats like CSV and Excel, to various JSON formats for programmatic use cases, to visualization-oriented formats like PNG or PDF.`}</li>
    </ul>
    <p>{`While these use cases are diverse, the common thread between them is that Looker users are able to instruct Looker to reach out and send data to an Action.`}</p>
    <p>{`As a result, implementing an Action involves deploying a web service to listen for these requests.`}</p>
    <p>{`This can be achieved by simply adding a few new endpoints to an existing application server, deploying a simple web server exclusively for this purpose, or even using a serverless environment such as Google Cloud Functions. The Action API does not require the service to maintain any state between requests, so Action servers can benefit from scalable and flexible stateless deployment configurations.`}</p>
    <p>{`Finally, since the Action API consists of simple JSON-formatted HTTP POST requests, any server side language can be readily used to implement an action. Looker provides several examples in Typescript, including the open-source `}<a parentName="p" {...{
        "href": "https://github.com/looker-open-source/actions"
      }}>{`code for our ActionHub`}</a>{`.`}</p>
    <h1>{`Getting started`}</h1>
    <Link href="/actions/getting-started" mdxType="Link"><Button mdxType="Button">Get started with Looker Actions</Button></Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      