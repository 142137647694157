import React from 'react'
import type {
  LegendTypes,
  LegendValues,
  CPie,
  CPieLegend,
} from '@looker/visualizations-adapters'
import { FieldSelect, Fieldset } from '@looker/components'
import type { LegendProps } from './Legend'

const legendTypes: LegendTypes[] = ['legend', 'labels']
const legendValues: LegendValues[] = [
  'label',
  'label_percent',
  'label_value',
  'percent',
  'value',
]

export const LegendPie = ({ onConfigChange, config }: LegendProps) => {
  const { legend } = config

  if (!legend) return null

  const handleTypeChange = (newType: string) => {
    const draft: CPie = {
      ...(config as CPie),
      legend: {
        ...(legend as CPieLegend),
        type: newType as LegendTypes,
      },
    }
    onConfigChange(draft)
  }

  const handleValuesChange = (newVal: string) => {
    const draft: CPie = {
      ...(config as CPie),
      legend: {
        ...(legend as CPieLegend),
        value: newVal as LegendValues,
      },
    }
    onConfigChange(draft)
  }

  return (
    <Fieldset legend="Legend Options" accordion defaultOpen>
      {legend.hasOwnProperty('type') && (
        <FieldSelect
          label="Legend Type"
          options={legendTypes.map(t => ({ value: t }))}
          value={(legend as CPieLegend).type}
          onChange={handleTypeChange}
        />
      )}
      {legend.hasOwnProperty('value') && (
        <FieldSelect
          label="Legend Values"
          options={legendValues.map(v => ({ value: v }))}
          value={(legend as CPieLegend).value}
          onChange={handleValuesChange}
        />
      )}
    </Fieldset>
  )
}
