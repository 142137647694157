import type { FC } from 'react'
import React from 'react'
import { Info } from '@styled-icons/material/Info'
import { Icon, Tooltip } from '@looker/components'

interface FieldInfoProps {
  content: string
}

export const FieldInfo: FC<FieldInfoProps> = ({ content }) => {
  return (
    <Tooltip content={content}>
      <Icon icon={<Info />} size="xxsmall" color="ui4" />
    </Tooltip>
  )
}
