import React from "react"
import {
  Box,
  ButtonOutline,
  Heading,
  Flex,
} from "@looker/components"

import DottedCircles from "../../../images/dotted_circles.svg"
import DottedCircles2 from "../../../images/dotted_circles_2.svg"


const SignupBox = () => {
  return (
    <Box
      backgroundColor="key"
      width="100%"
      overflow="hidden"
      position="relative"
      alignItems="center"
      alignContent="center"
      paddingX="large" >
      <img
        src={DottedCircles2}
        style={{
          position: "absolute",
          top: "-200px",
          left: "-100px",
          zIndex: 0,
        }} />
      <img
        src={DottedCircles}
        style={{
          position: "absolute",
          top: "-210px",
          right: "-170px",
          zIndex: 0,
        }} />
      <Flex
        flexDirection="column"
        alignItems="center"
        paddingY="xxxxlarge"
        position="relative"
      >
        <Heading
          fontFamily="brand"
          color="#FFFFFF"
          fontWeight="normal"
          fontSize="xxxxlarge"
          textAlign="center"
          paddingBottom="xlarge"
        >
          Keep up with Looker developer updates
        </Heading>
        <a
          href="https://discover.looker.com/Preference-Center-customer.html"
          target="_blank"
          style={{textDecoration: 'none'}}>
          <ButtonOutline size="large">
            Sign Up
          </ButtonOutline>
        </a>
      </Flex>
    </Box>
  )
}

export default SignupBox
