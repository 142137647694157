import React from 'react'
import { Box, Heading } from '@looker/components'
import styled from 'styled-components'

import Layout from '.'
import { MarketingBanner } from './MarketingBanner'

interface IframePageProps {
  pageTitle?: string
  headerTitle?: string
  iframeSrc: string
  height: string
  showSignup?: boolean
}

const IframePage = ({
    pageTitle,
    headerTitle,
    iframeSrc,
    height = "100vh",
    showSignup = false
  }:IframePageProps) => {
  if(!height || !height.match(/^\d+[a-z]{2}$/)){
    console.warn(`Unsupported iframe height: ${height}`)
    height = "100vh"
  }
  return (
    <Layout
      defaultNav={false}
      headerSearch={true}
      showQuotes={false}
      showSignup={showSignup}
      title={pageTitle ?? headerTitle}
    >
      <HeaderContainer>
        <MarketingBanner />
        {headerTitle &&
        <Box paddingTop="xlarge" id="header">
          <Box maxWidth="600px">
            <Heading
              as="h1"
              fontSize="xxxxxlarge"
              fontWeight="normal"
              paddingBottom="u5"
            >
              {headerTitle ?? ''}
            </Heading>
          </Box>
        </Box>
        }
      </HeaderContainer>
      <Box id="iframe-box">
        <iframe 
          src={iframeSrc}
          frameBorder="0"
          style={{width:"100%",height}}
        ></iframe>
      </Box>
    </Layout>
  )
}

export default IframePage

export const HeaderContainer = styled.div`
  max-width: 1340px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  padding: 0 ${(props) => props.theme.space.large};
`

export const MDXBox = styled(Box)`
  max-width: auto;
  padding-right: 0;
  padding-bottom: ${(props) => props.theme.space.xxxlarge};
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    max-width: calc(100% - 320px);
    padding-right: ${(props) => props.theme.space.xxxxlarge};
  }
  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      max-width: calc(100% - 320px);
      padding-right: ${(props) => props.theme.space.xxxxlarge};
    }
  }
  h1, h2, h3 {margin-top:1.25em}
`
