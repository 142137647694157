import React, { useState, ReactNode } from 'react'
import { MDXComponents } from '../../MDX'
import { MDXProvider } from '@mdx-js/react'
import { ComponentsProvider } from '@looker/components-providers'
import { HeaderContent } from '../Layout/Header/HeaderContent'
import { GatsbyOverrides } from '../Layout'
import { THEME_OVERRIDES } from '../../styles'
import { CookieBanner } from '../Layout/CookieBanner'
import { Metadata } from '../Layout/Metadata'
import { useLocation } from '@reach/router'

interface LayoutPropsCr {
  children?: ReactNode
  title?: string
}

const LayoutCr = ({ children, title }: LayoutPropsCr) => {
  const toggleSidebar = () => {
    window.postMessage({ action: 'toggle_sidebar' }, window.origin)
  }
  const location = useLocation()

  return (
    <ComponentsProvider {...THEME_OVERRIDES}>
      <Metadata route={location.pathname} title={title} />
      <GatsbyOverrides />
      <MDXProvider components={MDXComponents}>
        <div
          style={{
            height: '100vh',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <div style={{ height: '4rem' }}>
            <HeaderContent
              className="header"
              headerSearch={false}
              toggleNavigation={toggleSidebar}
              navigationLabel="Toggle sidebar"
            />
          </div>
          <div
            className="nav-hiding"
            style={{ flex: '1 1 0%', overflowY: 'hidden' }}
          >
            {children}
          </div>
          <CookieBanner />
        </div>
      </MDXProvider>
    </ComponentsProvider>
  )
}

export default LayoutCr
