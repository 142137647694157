import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../../components/Layout/SectionOverview';
import { Link, Button, Card, CardContent } from '@looker/components';
import Img from '../../../images/GettingStarted/embedsso.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Get started with SSO embedding" headerTitle="Get started with SSO embedding">{children}</SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Single sign-on (SSO) embedding is a way to present secure embedded Looks, Explores, dashboards, or LookML dashboards to your users without requiring them to have a separate Looker login. Instead, users will be authenticated through your own application.`}</p>
    <p>{`SSO embedding works by creating a special Looker URL that you will use in an iframe. The URL contains the information you want to share, the ID of the user in your system, and the permissions you want that user to have. You'll then sign the URL with a secret key provided by Looker.`}</p>
    <h1>{`Proper hosting for SSO embedding`}</h1>
    <p>{`Some browsers (Internet Explorer and Safari at the time of this writing) default to a cookie policy that blocks third-party cookies. Because Looker uses cookies for user authentication, attempting to authenticate the embedded iframe across domains is not possible in these browsers (unless the user modifies their browser’s cookie privacy settings). For example, if you want to embed information on `}<inlineCode parentName="p">{`https://mycompany.com`}</inlineCode>{`, you need to make sure that Looker is on a subdomain, such as `}<inlineCode parentName="p">{`https://analytics.mycompany.com`}</inlineCode>{`.`}</p>
    <p>{`If Looker is hosting your instance, contact Looker Support to set up the necessary DNS configuration. Open a support request in Looker’s `}<Link href="https://help.looker.com" mdxType="Link">{`Help Center`}</Link>{` by clicking `}<strong parentName="p">{`Contact Us`}</strong>{`.`}</p>
    <p>{`If you have a customer-hosted Looker instance, make sure that the application that will use SSO embedding is on the same base domain as your Looker instance.`}</p>
    <h1>{`Controlling client visibility with a closed system`}</h1>
    <p>{`It is common in an SSO embed configuration for Looker users to present data to their own customers, and to have clients from different companies or groups that should not know about one another. In this scenario, to safeguard your customers’ private information, we strongly recommend you configure Looker as a closed system, also called a multitenant installation. In a closed system, content is siloed, preventing users from different groups from knowing about each other. For this reason, we recommend you enable the `}<Link href="https://docs.looker.com/admin-options/settings/general#closed_system" mdxType="Link">{`Closed System option`}</Link>{` before you grant any external users access to your instance.`}</p>
    <p>{`For more information, see the `}<Link href="https://docs.looker.com/admin-options/tutorials/access-controls" mdxType="Link">{`Designing and configuring a system of access levels`}</Link>{` and the `}<Link href="https://docs.looker.com/reference/embedding/security" mdxType="Link">{`Security best practices for embedded analytics`}</Link>{` documentation pages.`}</p>
    <h1>{`Generating Looker's secret key`}</h1>
    <p>{`To validate that an SSO embedding request is legitimate and hasn't been forged by someone else, you'll first need to generate an "embed secret." To do so:`}</p>
    <img style={{
      "maxWidth": "100%"
    }} src={Img} />
    <ol>
      <li parentName="ol">{`Go to the `}<strong parentName="li">{`Embed`}</strong>{` page in the `}<strong parentName="li">{`Admin`}</strong>{` section of Looker.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Enabled`}</strong>{` from the `}<strong parentName="li">{`Embed SSO Authentication`}</strong>{` drop-down, and then click `}<strong parentName="li">{`Update`}</strong>{`.`}</li>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Reset Secret`}</strong>{` button to generate your embed secret. `}<em parentName="li">{`Be sure to copy this secret to a secure location, because you will not be able to retrieve it from Looker again without resetting it. Resetting the key will break any embeds that used the old key.`}</em></li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Anyone with access to the secret key may create a URL to access any model that Looker instance is connected to, as any user, with any permission. `}<Link href="https://docs.looker.com/reference/embedding/security#single_sign-on_embedding" mdxType="Link">{`Protect the SSO embed secret`}</Link>{` as you would admin credentials to your embedded Looker instance, and keep SSO embedding disabled if you're not using it.`}</p>
    </blockquote>
    <h1>{`Building the Embed URL`}</h1>
    <p>{`Building the proper URL will require you to write code, so that you can properly encode the URL with your secret key, and generate other security related items. You can find several example scripts on our `}<Link href="https://github.com/looker/looker_embed_sso_examples" mdxType="Link">{`SSO examples GitHub repository`}</Link>{`. The following sections explain the information that you'll need to supply to those scripts.`}</p>
    <h2>{`Collecting the necessary Looker information`}</h2>
    <p>{`As a starting point for building your URL, you'll first want to determine all the information that will need to be included.  You will need:`}</p>
    <h3>{`Embed URL`}</h3>
    <p>{`Retrieve the URL of the Look, Explore, or dashboard that you want to embed. Then remove the domain and place `}<inlineCode parentName="p">{`/embed`}</inlineCode>{` before the path, as follows:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Item`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Normal URL Pattern`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Embed URL`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Look`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`https://instance_name.looker.com/`}</inlineCode><br /><inlineCode parentName="td">{`looks/4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/embed/looks/4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Explore`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`https://instance_name.looker.com/`}</inlineCode><br /><inlineCode parentName="td">{`explore/my_model/my_explore`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/embed/explore/my_model/my_explore`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User-defined dashboard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`https://instance_name.looker.com/`}</inlineCode><br /><inlineCode parentName="td">{`dashboards-next/1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/embed/dashboards-next/1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User-defined legacy`}<br />{`dashboard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`https://instance_name.looker.com/`}</inlineCode><br /><inlineCode parentName="td">{`dashboards/1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/embed/dashboards/1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LookML dashboard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`https://instance_name.looker.com/`}</inlineCode><br /><inlineCode parentName="td">{`dashboards-next/my_model::my_dashboard`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/embed/dashboards-next/my_model::my_dashboard`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Legacy LookML`}<br />{`dashboard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`https://instance_name.looker.com/`}</inlineCode><br /><inlineCode parentName="td">{`dashboards/my_model::my_dashboard`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/embed/dashboards/my_model::my_dashboard`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Embedded content always reflects the production version of the content. Any changes made while in `}<Link href="https://docs.looker.com/data-modeling/getting-started/dev-mode-prod-mode" mdxType="Link">{`Development Mode`}</Link>{` that affect content and that have not been deployed to production will not appear in an embed.`}</p>
    </blockquote>
    <h3>{`Permissions`}</h3>
    <p>{`A permission set defines what a `}<Link href="https://docs.looker.com/admin-options/settings/users" mdxType="Link">{`user`}</Link>{` or `}<Link href="https://docs.looker.com/admin-options/settings/groups" mdxType="Link">{`group`}</Link>{` can do. Permissions can be applied in one of two ways:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Model-Specific:`}</strong>{` This type of permission is applied only to the `}<Link href="https://docs.looker.com/admin-options/settings/roles#model_sets" mdxType="Link">{`model sets`}</Link>{` that are part of the same role.`}</li>
      <li parentName="ul"><strong parentName="li">{`Instance-Wide:`}</strong>{` This type of permission applies to the Looker instance as a whole. Embed users with instance-wide permissions can perform certain functions across the entire Looker instance, but cannot access content based on models not included in their role's model set.`}</li>
    </ul>
    <p>{`Determine the permissions that you'll want the user to have. The following list shows all available permissions for SSO embedding. Permissions that are not on the following list are not supported for SSO embedding:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Permission`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Depends On`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Definition`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`access_data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to access data (required for viewing Looks, dashboards, or Explores)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_lookml_dashboards`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`access_data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to see LookML dashboards`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`access_data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to see Looks`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_user_dashboards`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to see user-defined dashboards and to browse folders from an embed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`explore`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to see Explore pages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`create_table_calculations`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`explore`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance Wide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Needed to create `}<Link href="https://docs.looker.com/exploring-data/using-table-calculations" mdxType="Link">{`table calculations`}</Link>{` from an Explore`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`save_content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance Wide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to make and save changes to Looks and dashboards`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`send_outgoing_webhook`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to schedule dashboards and Looks to an arbitrary webhook`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`send_to_s3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to schedule dashboards and Looks to an Amazon S3 bucket`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`send_to_sftp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to schedule dashboards and Looks to an SFTP server`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`schedule_look_emails`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to schedule dashboards and Looks to be sent to their own email (which is set with a `}<Link href="https://docs.looker.com/admin-options/settings/user-attributes" mdxType="Link">{`user attribute`}</Link>{` named "email") or to an email address that is within the limitations set by the `}<Link href="https://docs.looker.com/admin-options/settings/general#email_domain_allowlist_for_scheduled_looks" mdxType="Link">{`email domain allowlist`}</Link>{`. Allows user with `}<inlineCode parentName="td">{`create_alerts`}</inlineCode>{` permissions to send alert notifications to an email address that is within the limitations set by the `}<Link href="https://docs.looker.com/admin-options/settings/general#email_domain_allowlist_for_scheduled_looks" mdxType="Link">{`email domain allowlist`}</Link>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`schedule_external_look_emails`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`schedule_look_`}</inlineCode><br /><inlineCode parentName="td">{`emails`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to schedule dashboards and Looks to be sent to any email. Allows user with `}<inlineCode parentName="td">{`create_alerts`}</inlineCode>{` permissions to send alert notifications to any email domain.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`send_to_`}</inlineCode><br /><inlineCode parentName="td">{`integration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to `}<Link href="https://docs.looker.com/sharing-and-publishing/scheduling-and-sharing/scheduling" mdxType="Link">{`deliver Looker content`}</Link>{` to the `}<Link href="https://docs.looker.com/admin-options/platform/actions#list_of_integrated_services" mdxType="Link">{`third-party services integrated with Looker`}</Link>{` through the Looker Action Hub. This permission is not related to `}<Link href="https://docs.looker.com/reference/field-params/action" mdxType="Link">{`data actions`}</Link>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`create_alerts`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance Wide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to create `}<Link href="https://docs.looker.com/sharing-and-publishing/alerts" mdxType="Link">{`alerts`}</Link>{` on dashboard tiles to receive notifications when specified conditions are met or exceeded. Users can edit, duplicate, and delete their own alerts and other users' `}<strong parentName="td">{`Public`}</strong>{` alerts. If the user's Slack workspace is not connected to the Looker instance, the user will not be able to create alerts that send notifications to Slack.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`download_with_limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance Wide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to download a query’s results with a limit applied`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`download_without_limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance Wide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to download a query’s results with no limit applied`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_sql`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_looks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to see the SQL for queries and any SQL errors resulting from running queries`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`see_drill_overlay`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`access_data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model Specific`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to drill without needing to go to the full Explore page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`embed_browse_spaces`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance Wide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enables the content browser so that a user can browse folders from an embed. Any embed user granted `}<inlineCode parentName="td">{`embed_browse_spaces`}</inlineCode>{` permission is given access to a `}<Link href="https://docs.looker.com/reference/embedding/viewing#using_embedded_spaces_to_find_looks_and_dashboards" mdxType="Link">{`personal embed folder`}</Link>{` and to your organization's Shared folder, if there is one. `}<br /><br />{`The `}<inlineCode parentName="td">{`embed_browse_spaces`}</inlineCode>{` permission is recommended for users who have the `}<inlineCode parentName="td">{`save_content`}</inlineCode>{` permission, so that the user can browse folders when selecting where to save content. `}<br /><br />{`To see the content in folders, the user also needs the `}<inlineCode parentName="td">{`see_looks`}</inlineCode>{`, `}<inlineCode parentName="td">{`see_user_dashboards`}</inlineCode>{`, and `}<inlineCode parentName="td">{`see_lookml_dashboards`}</inlineCode>{` permissions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`embed_save_shared_space`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instance Wide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user with the `}<inlineCode parentName="td">{`save_content`}</inlineCode>{` permission to save content to the organization's Shared folder, if there is one. Users who have the `}<inlineCode parentName="td">{`save_content`}</inlineCode>{` permission but not the `}<inlineCode parentName="td">{`embed_save_shared_space`}</inlineCode>{` permission will only have the option to save content to their `}<Link href="https://docs.looker.com/reference/embedding/viewing#using_embedded_spaces_to_find_looks_and_dashboards" mdxType="Link">{`personal embed folder`}</Link>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Model access`}</h3>
    <p>{`Determine which LookML models the user should have access to. This will simply be a list of model names.`}</p>
    <h3>{`User attributes`}</h3>
    <p>{`Determine which `}<Link href="https://docs.looker.com/admin-options/settings/user-attributes" mdxType="Link">{`user attributes`}</Link>{` the user should have, if any. You'll need the name of the user attribute from Looker, as well as the value that the user should have for that attribute.`}</p>
    <h3>{`Groups`}</h3>
    <p>{`Determine which `}<Link href="https://docs.looker.com/admin-options/settings/groups" mdxType="Link">{`groups`}</Link>{` the user should belong to, if any. You'll need the group IDs as opposed to the group names. Adding an SSO embed user to a Looker group allows you to manage that user's access to Looker folders. SSO embed users will `}<Link href="https://docs.looker.com/reference/embedding/viewing#interacting_with_an_embedded_look,_explore,_or_dashboard" mdxType="Link">{`have access`}</Link>{` to any folders shared with members of their Looker groups.`}</p>
    <p>{`You can also use the `}<inlineCode parentName="p">{`external_group_id`}</inlineCode>{` parameter to create a group that is external to the regular Looker groups. In that case, SSO embed users with the same `}<inlineCode parentName="p">{`external_group_id`}</inlineCode>{` will `}<Link href="https://docs.looker.com/reference/embedding/viewing#interacting_with_an_embedded_look,_explore,_or_dashboard" mdxType="Link">{`have access`}</Link>{` to a shared folder, called "Group", that is unique to the external group.`}</p>
    <h3>{`Embedded roles`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`permissions`}</inlineCode>{` and `}<inlineCode parentName="p">{`models`}</inlineCode>{` parameters create a `}<Link href="https://docs.looker.com/admin-options/settings/roles" mdxType="Link">{`role`}</Link>{` for the embed user. This role appears as an "Embedded Role" in the `}<Link href="https://docs.looker.com/admin-options/settings/users" mdxType="Link"><strong parentName="p">{`Users`}</strong></Link>{` page in Lookers `}<strong parentName="p">{`Admin`}</strong>{` section. If the `}<inlineCode parentName="p">{`permissions`}</inlineCode>{`, `}<inlineCode parentName="p">{`models`}</inlineCode>{`, and `}<inlineCode parentName="p">{`group_ids`}</inlineCode>{` parameters are all specified in the embed URL, then the embedded role is `}<em parentName="p">{`additive`}</em>{` to any roles already assigned to the groups listed in the `}<inlineCode parentName="p">{`group_ids`}</inlineCode>{` parameter. This is the same as standard roles in that all roles in Looker are additive.`}</p>
    <p>{`For example, say you have an existing group in Looker with the group ID `}<inlineCode parentName="p">{`1`}</inlineCode>{`, and that group already has the `}<inlineCode parentName="p">{`explore`}</inlineCode>{` permission for a model named `}<inlineCode parentName="p">{`model_one`}</inlineCode>{`, and you create an embed URL with the following parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`group_ids`}</inlineCode>{` = `}<inlineCode parentName="li">{`[1]`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`permissions`}</inlineCode>{` = `}<inlineCode parentName="li">{`["access_data","see_looks"]`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`models`}</inlineCode>{` = `}<inlineCode parentName="li">{`["model_two"]`}</inlineCode></li>
    </ul>
    <p>{`In that case, the embed user will inherit the ability to view and explore the data on `}<inlineCode parentName="p">{`model_one`}</inlineCode>{`, and the embed role created with the preceding parameters will also grant the ability to view the data on `}<inlineCode parentName="p">{`model_two`}</inlineCode>{`.`}</p>
    <h2>{`Creating the Embed URL`}</h2>
    <p>{`An SSO embed URL has the following format:`}</p>
    <span style={{
      "fontSize": "0.9em",
      "fontFamily": "'Menlo','Lucida Console',monospace",
      "color": "black"
    }}>https://</span><span style={{
      "borderRadius": "3px",
      "backgroundColor": "#0E88F1",
      "color": "white",
      "fontWeight": "bold",
      "fontSize": "0.9em",
      "padding": "3px",
      "margin": "0 3px 0 3px"
    }}>HOST</span><span style={{
      "fontSize": "0.9em",
      "fontFamily": "'Menlo','Lucida Console',monospace",
      "color": "black"
    }}>/login/embed/</span><span style={{
      "borderRadius": "3px",
      "backgroundColor": "#4BB86A",
      "color": "white",
      "fontWeight": "bold",
      "fontSize": "0.9em",
      "padding": "3px",
      "margin": "0 3px 0 3px"
    }}>EMBED URL</span><span style={{
      "fontSize": "0.9em",
      "fontFamily": "'Menlo','Lucida Console',monospace",
      "color": "black"
    }}>?</span><span style={{
      "borderRadius": "3px",
      "backgroundColor": "#E57200",
      "color": "white",
      "fontWeight": "bold",
      "fontSize": "0.9em",
      "padding": "3px",
      "margin": "0 3px 0 3px"
    }}>PARAMETERS</span><span style={{
      "fontSize": "0.9em",
      "fontFamily": "'Menlo','Lucida Console',monospace",
      "color": "black"
    }}>&signature=</span><span style={{
      "borderRadius": "3px",
      "backgroundColor": "#F2B431",
      "color": "white",
      "fontWeight": "bold",
      "fontSize": "0.9em",
      "padding": "3px",
      "margin": "0 3px 0 3px"
    }}>SIGNATURE</span>
    {
      /* ------- */
    }
    <h3 style={{
      "color": "#0E88F1"
    }}>Host</h3>
    <p>{`The host is the location where your Looker instance is being hosted. For example, `}<inlineCode parentName="p">{`analytics.mycompany.com`}</inlineCode>{`. Be sure to include the port number if you haven't enabled port forwarding, such as `}<inlineCode parentName="p">{`analytics.mycompany.com:9999`}</inlineCode>{`.`}</p>
    <h3 style={{
      "color": "#4BB86A"
    }}>Embed URL</h3>
    <p>{`The embed URL was determined previously. It will have a format such as:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`/embed/looks/4`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`/embed/explore/my_model/my_explore`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`/embed/dashboards-next/1`}</inlineCode>{` or `}<inlineCode parentName="li">{`/embed/dashboards/1`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`/embed/dashboards-next/my_model::my_dashboard`}</inlineCode>{` or `}<inlineCode parentName="li">{`/embed/dashboards/my_model::my_dashboard`}</inlineCode></li>
    </ul>
    <p>{`This does mean that the pattern `}<inlineCode parentName="p">{`/embed//embed/`}</inlineCode>{` will show up in your final URL; this is correct.`}</p>
    <p>{`If you are using `}<Link href="https://docs.looker.com/reference/embedding/embed-javascript-events" mdxType="Link">{`embedded JavaScript events`}</Link>{` be sure to add an `}<inlineCode parentName="p">{`embed_domain`}</inlineCode>{` (the domain where the iframe is being used) to the end of the embed URL, like this:`}</p>
    <div style={{
      "display": "flex",
      "flexWrap": "wrap"
    }}>
  <div markdown="1" style={{
        "paddingRight": "20px"
      }}>
    `/embed/looks/4`
  </div>
  <div markdown="1" style={{
        "paddingRight": "20px"
      }}>
    &#8680;
  </div>
  <div markdown="1">
    `/embed/looks/4?embed_domain=https://mywebsite.com`
  </div>
    </div>
    <p><inlineCode parentName="p">{`embed_domain`}</inlineCode>{` is added after the embed URL, and before any parameters. So if you had existing parameters, such as `}<inlineCode parentName="p">{`nonce=62`}</inlineCode>{`, the addition of the `}<inlineCode parentName="p">{`embed_domain`}</inlineCode>{` would look like:`}</p>
    <div style={{
      "display": "flex",
      "flexWrap": "wrap"
    }}>
  <div markdown="1" style={{
        "paddingRight": "20px"
      }}>
    `/embed/looks/4?nonce=626`
  </div>
  <div markdown="1" style={{
        "paddingRight": "20px"
      }}>
    &#8680;
  </div>
  <div markdown="1">
    `/embed/looks/4?embed_domain=https://mywebsite.com?nonce=626`
  </div>
    </div>
    <p>{`If you are using the `}<Link href="https://docs.looker.com/reference/embed-sdk" mdxType="Link">{`embed SDK`}</Link>{` be sure to add the `}<inlineCode parentName="p">{`embed_domain`}</inlineCode>{` and also include `}<inlineCode parentName="p">{`sdk=2`}</inlineCode>{` to the end of the embed URL, like this:`}</p>
    <div style={{
      "display": "flex",
      "flexWrap": "wrap"
    }}>
  <div markdown="1" style={{
        "paddingRight": "20px"
      }}>
    `/embed/looks/4`
  </div>
  <div markdown="1" style={{
        "paddingRight": "20px"
      }}>
    &#8680;
  </div>
  <div markdown="1">
    `/embed/looks/4?embed_domain=https://mywebsite.com&sdk=2`
  </div>
    </div>
    <p>{`The `}<inlineCode parentName="p">{`sdk=2`}</inlineCode>{` parameter allows Looker to identify that the SDK is present and can take advantage of additional features provided by the SDK. The SDK cannot add this parameter itself because it is part of the signed SSO URL.`}</p>
    <h3 style={{
      "color": "#E57200"
    }}>Parameters</h3>
    <p>{`The following URL parameters are used to specify the necessary information for the SSO embed:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value Required?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Data Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nonce`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Any random string you like, but it cannot be repeated within an hour and must be less than 255 characters.`}<br /><br />{`This prevents an attacker from re-submitting a legitimate user's URL to gather information they shouldn't have.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"22b1ee700ef3dc2f500fb7"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`time`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The current time as a UNIX timestamp.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1407876784`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`session_length`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of seconds that the user should remain logged in to Looker, between 0 and 2,592,000 seconds (30 days).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`86400`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`external_user_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique identifier for the user in the application that is embedding Looker. Looker uses this value to differentiate SSO embed users.`}<br /><br />{`You create this string, and it can be any value you like. But, this value must be unique for a given set of permissions, user attributes, and models. So, for example, if the same user will have different permissions in two contexts they'll need two different external user IDs. `}<br /><br />{`For security purposes, ensure that you are not using the same `}<inlineCode parentName="td">{`external_user_id`}</inlineCode>{` across different embed sessions for different interactive users.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"user-4"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`permissions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The list of permissions the user should have.`}<br /><br />{`See the `}<Link href="#permissions" mdxType="Link">{`Permissions`}</Link>{` section on this page for the list of allowed permissions.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of strings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[`}</inlineCode><br />{` `}{` `}<inlineCode parentName="td">{`"access_data",`}</inlineCode><br />{` `}{` `}<inlineCode parentName="td">{`"see_looks"`}</inlineCode><br /><inlineCode parentName="td">{`]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`models`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The list of model names the user should have access to.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of strings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[`}</inlineCode><br />{` `}{` `}<inlineCode parentName="td">{`"model_one",`}</inlineCode><br />{` `}{` `}<inlineCode parentName="td">{`"model_two"`}</inlineCode><br /><inlineCode parentName="td">{`]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`group_ids`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The list of Looker groups the user should be a member of, if any. Use group IDs instead of group names.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of integers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[4, 3]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`external_group_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique identifier for the group the user belongs to in the application that is embedding Looker, if desired.`}<br /><br />{`Users who have permission to save content, and share an external group ID, will be able to save and edit content in a `}<Link href="https://docs.looker.com/reference/embedding/viewing#using_embedded_spaces_to_find_looks_and_dashboards" mdxType="Link">{`shared Looker folder called "Group"`}</Link>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"Accounting"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`user_attributes`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The list of `}<Link href="https://docs.looker.com/admin-options/settings/user-attributes" mdxType="Link">{`user attributes`}</Link>{` the user should have, if any. Contains a list of user attribute names followed by the user attribute value.`}<br /><br />{`If your `}<Link href="https://docs.looker.com/data-modeling/learning-lookml/model-localization" mdxType="Link">{`LookML model is localized`}</Link>{`, you can use the `}<inlineCode parentName="td">{`locale`}</inlineCode>{` user attribute in the embed URL to specify a language for the embed. For example, including the parameter `}<inlineCode parentName="td">{`user_attributes { "locale" : "fr_FR" }`}</inlineCode>{` would cause the embed to load French as its language.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hash of strings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{`}</inlineCode><br />{` `}{` `}<inlineCode parentName="td">{`"vendor_id" : "17",`}</inlineCode><br />{` `}{` `}<inlineCode parentName="td">{`"company" : "xactness"`}</inlineCode><br /><inlineCode parentName="td">{`}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`access_filters`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`In Looker 3.10 this parameter was deprecated, but it is still required in the URL. Use `}<inlineCode parentName="td">{`access_filters`}</inlineCode>{` with an empty placeholder, for example `}<inlineCode parentName="td">{`access_filters={}`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Empty Placeholder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`first_name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user’s first name. If left blank, `}<inlineCode parentName="td">{`first_name`}</inlineCode>{` will retain the value from the last request, or be “Embed” if no first name has ever been set.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"Alice"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`last_name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user’s last name. If left blank, `}<inlineCode parentName="td">{`last_name`}</inlineCode>{` will retain the value from the last request, or be “Embed” if no last name has ever been set.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"Jones"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`user_timezone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If you've enabled `}<Link href="https://docs.looker.com/admin-options/settings/general#user_specific_time_zones" mdxType="Link">{`user specific time zones`}</Link>{`, sets the value of the `}<strong parentName="td">{`Viewer Time Zone`}</strong>{` option in the `}<strong parentName="td">{`Time Zone`}</strong>{` drop-down on the embedded Look or dashboard. This parameter does not directly change the time zone in which the content is shown; the user will need to select the desired time zone from the drop-down.`}<br /><br />{`See valid values on the `}<Link href="https://docs.looker.com/reference/embedding/timezones" mdxType="Link">{`SSO embedding time zone reference`}</Link>{` documentation page.`}<br /><br /><strong parentName="td">{`Chat team tip:`}</strong>{` If you want your embedded content to default to the viewer's time zone, use one of the following methods:`}<br /><br /><li>{`Add the parameter `}<inlineCode parentName="td">{`?query_timezone=user_timezone`}</inlineCode>{` to the `}<Link href="#embed_url" mdxType="Link">{`embed URL`}</Link>{`. For example:`}<br /><br /><inlineCode parentName="td">{`/embed/dashboards/1?query_timezone=user_timezone`}</inlineCode></li><li>{`Save the embedded dashboard or Look with the `}<Link href="/dashboards/editing-user-dashboards#default_timezone" mdxType="Link">{`default time zone`}</Link>{` set to `}<strong parentName="td">{`Viewer Time Zone`}</strong>{`, which will use the user's time zone by default for both embed users and non-embed users.`}</li></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON string or null`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"US/Pacific"`}</inlineCode><br /><br />{`- or -`}<br /><br /><inlineCode parentName="td">{`null`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`force_logout_login`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If a normal Looker user is already logged in to Looker, and they view an SSO embedded item, you can choose if:`}<br /><br />{`1) they should view the item with their current credentials`}<br /><br />{`or`}<br /><br />{`2) they should be logged out and logged back in with the SSO credentials.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean (true or false)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`All the preceding parameters are required, but any parameter with "No" in the "Value Required?" column can be used with an empty value. For example, you could use `}<inlineCode parentName="p">{`group_ids []`}</inlineCode>{` or `}<inlineCode parentName="p">{`user_attributes {}`}</inlineCode>{`.`}</p>
    <h3 style={{
      "color": "#F2B431"
    }}>Signature</h3>
    <p>{`To generate the signature you'll need to follow these steps.`}</p>
    <ol>
      <li parentName="ol">{`Gather the following parameter values in this order:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Host, followed by `}<inlineCode parentName="li">{`login/embed/`}</inlineCode>{` (for example, `}<inlineCode parentName="li">{`analytics.mycompany.com/login/embed/`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Embed URL`}</li>
      <li parentName="ul">{`Nonce`}</li>
      <li parentName="ul">{`Current Time`}</li>
      <li parentName="ul">{`Session Length`}</li>
      <li parentName="ul">{`External User ID`}</li>
      <li parentName="ul">{`Permissions`}</li>
      <li parentName="ul">{`Models`}</li>
      <li parentName="ul">{`Group IDs`}</li>
      <li parentName="ul">{`External Group ID`}</li>
      <li parentName="ul">{`User Attributes`}</li>
      <li parentName="ul">{`Access Filters (requires an empty placeholder)`}</li>
    </ul>
    <ol>
      <li parentName="ol">{`Format all values other than Host and Embed URL as JSON`}</li>
      <li parentName="ol">{`Concatenate the values with line breaks (`}<inlineCode parentName="li">{`\\n`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`HMAC sign the concatenated string with your Looker embed secret`}</li>
    </ol>
    <h3>{`Encoding`}</h3>
    <p>{`The final step is to URL encode your URL.`}</p>
    <p><em parentName="p">{`Before`}</em>{` you encode the URL, a properly formatted embed URL that uses all possible parameters might look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static",
        "static": true
      }}>{`https://analytics.mycompany.com/login/embed//embed/dashboards-next/1?
nonce="22b1ee700ef3dc2f500fb7"&
time=1407876784&
session_length=86400&
external_user_id="user-4"&
permissions=["access_data","see_user_dashboards","see_looks"]&
models=["model_one","model_two"]&
group_ids=[4,3]&
external_group_id="Allegra K"&
user_attributes={"vendor_id":"17","company":"xactness"}&
access_filters={}&
first_name="Alice"&
last_name="Jones"&
user_timezone="US/Pacific"&
force_logout_login=true&
signature=123456789ABCDEFGHIJKL
`}</code></pre>
    <p>{`As noted previously, it is correct for `}<inlineCode parentName="p">{`/embed//embed/`}</inlineCode>{` to appear in your URL.`}</p>
    <p><em parentName="p">{`After`}</em>{` you encode the URL, it would look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "static",
        "static": true
      }}>{`https://analytics.mycompany.com/login/embed/%2embed%2Fdashboards-next%2F1?
nonce=%2222b1ee700ef3dc2f500fb7&%22&
time=1407876784&
session_length=86400&
external_user_id=%22user-4%22&
permissions=%5B%22access_data%22%2C%22see_user_dashboards%22%2C%22see_looks%22%5D&
models=%5B%22model_one%22%2C%22model_two%22%5D&
group_ids=%5B4%2C3%5D&
external_group_id=%22Allegra%20K%22&
user_attributes=%7B%22vendor_id%22%3A%2217%22%2C%22company%22%3A%22xactness%22%7D&
access_filters%7B%7D%26%0A
first_name=%22Alice%22&
last_name=%22Jones%22&
user_timezone=%22US%2FPacific%22&
force_logout_login=true&
signature=123456789ABCDEFGHIJKL
`}</code></pre>
    <h2>{`Using the `}<inlineCode parentName="h2">{`create_sso_embed_url`}</inlineCode>{` API endpoint`}</h2>
    <p>{`The Looker API includes the `}<Link href="https://docs.looker.com/reference/api-and-integration/api-reference/v3.1/auth#create_sso_embed_url" mdxType="Link"><inlineCode parentName="p">{`create_sso_embed_url`}</inlineCode></Link>{` endpoint, which takes a set of SSO embed parameters that includes the URL of the content you want to embed, and returns a complete, encoded, cryptographically signed SSO URL.`}</p>
    <p>{`To use this API endpoint from a web server, the web server must be able to `}<Link href="https://docs.looker.com/reference/api-and-integration/api-auth" mdxType="Link">{`authenticate into the Looker API`}</Link>{` with admin privileges. The web server domain must also be listed in the `}<Link href="https://docs.looker.com/admin-options/platform/embed#embedded_domain_allowlist" mdxType="Link">{`Embed Domain Allowlist`}</Link>{`.`}</p>
    <p>{`You can also use the `}<Link href="https://docs.looker.com/reference/api-and-integration/api-getting-started#interactive_api_documentation_and_explorer" mdxType="Link">{`interactive API documentation`}</Link>{` on your Looker instance to generate an SSO URL using this endpoint. Once generated, the SSO URL must be copied exactly and can be used only once `}{`—`}{` otherwise, it will fail. The interactive API documentation is also useful for generating an SSO URL and comparing it to a manually created SSO URL for troubleshooting purposes.`}</p>
    <p>{`For more information about the Looker API, see the `}<Link href="https://docs.looker.com/reference/api-and-integration/api-getting-started" mdxType="Link">{`Getting started with the Looker API`}</Link>{` documentation page.`}</p>
    <h2>{`Testing the Embed URL`}</h2>
    <p>{`To test your final URL, paste it into the `}<Link href="https://docs.looker.com/admin-options/platform/embed#embed_uri_validator" mdxType="Link">{`Embed URI Validator`}</Link>{` on the `}<strong parentName="p">{`Embed`}</strong>{` page of Looker's `}<strong parentName="p">{`Admin`}</strong>{` section. While this option can't tell you if the data and permissions you envision have been set up correctly, it can validate that your authentication is working properly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      