import React, { FC } from "react"
import {
  Box,
  Divider,
} from "@looker/components"
import {
  useStaticQuery,
  graphql
} from "gatsby"
import styled from "styled-components"

import {
  ISiteMenuData,
} from "../../../types/sitemetadata"
import {
  SitemapFooter
} from "../../Navigation"
import QuotesBox from './QuotesBox'
import SignupBox from './SignupBox'
import LegalBox from './LegalBox'

export interface FooterProps {
  showQuotes: boolean | undefined
  showSignup?: boolean | undefined
}

export const Footer: FC<FooterProps> = ({
  showQuotes = false,
  showSignup = false
}) => {
  const data: ISiteMenuData = useStaticQuery(graphql`
    query FooterMenu {
      site {
        siteMetadata {
          title
          menuLinks {
            link
            name
            subMenu {
              link
              name
            }
          }
        }
      }
    }
  `)
  return (
    <Box position="relative">
      {showQuotes && <QuotesBox />}

      {showSignup && <SignupBox />}

      <FooterLinkContainer>
        <FooterLinkWrapper>
          <SitemapFooter data={data} />
          <Divider paddingX="xxlarge" />
          <LegalBox />
        </FooterLinkWrapper>
      </FooterLinkContainer>
    </Box>
  )
}

export const FooterLinkContainer = styled(Box)`
  background-color: white;
  padding: ${(props) => props.theme.space.xlarge}
    ${(props) => props.theme.space.large};
  justify-content: space-between;
`

export const FooterLinkWrapper = styled(Box)`
  max-width: 1340px;
  margin: 0 auto;
`;
