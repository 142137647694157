import React, { FC } from 'react'
import { Badge } from '@looker/components'
import styled from 'styled-components'

export type StatusLabels = 'experimental' | 'deprecated' | 'stable'

export interface StatusProps {
  className?: string
  status: StatusLabels
}

const mapStatusToIntent = (status: StatusLabels) => {
  switch (status) {
    case 'experimental':
      return 'inform'
    case 'deprecated':
      return 'warn'
    case 'stable':
      return 'positive'
  }
}

const StatusLayout: FC<StatusProps> = ({ className, status }) => {
  status = status || 'stable'

  return (
    <span className={className}>
      <Badge intent={mapStatusToIntent(status)}>{status.toUpperCase()}</Badge>
    </span>
  )
}

export const Status = styled(StatusLayout)`
  text-decoration: none;
`
