import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import type {
  LegendPositions,
  CArea,
  CBar,
  CColumn,
  CScatter,
  CLine,
  CPie,
  CAll,
  CPieLegend,
} from '@looker/visualizations-adapters'
import { FieldSelect } from '@looker/components'
import { LegendPie } from './LegendPie'

/**
 * A list of relevant charts that access the legend configuration
 */
type SupportedChartConfig = CPie | CLine | CArea | CBar | CColumn | CScatter

export type LegendProps = {
  config: SupportedChartConfig
  onConfigChange: Dispatch<SetStateAction<Partial<CAll>>>
}

const legendPositions: LegendPositions[] = ['left', 'right', 'top', 'bottom']
const renderLegendFor: Array<SupportedChartConfig['type']> = [
  'area',
  'bar',
  'column',
  'line',
  'pie',
  'scatter',
]

export const Legend = (props: LegendProps) => {
  const { config, onConfigChange } = props

  if (!renderLegendFor.includes(config.type)) {
    // Early return! Only render for supported charts
    return null
  }

  const { legend } = config
  const position = config.legend ? config.legend.position : 'none'

  const handlePositionChange = (draftPosition: string) => {
    if (draftPosition === 'none') {
      onConfigChange({
        ...config,
        legend: false,
      })
    } else {
      onConfigChange({
        ...config,
        legend: {
          ...(legend as CPieLegend),
          position: draftPosition as LegendPositions,
        },
      })
    }
  }

  return (
    <>
      <FieldSelect
        label="Legend"
        options={['none', ...legendPositions].map(p => ({ value: p }))}
        value={position}
        onChange={handlePositionChange}
      />
      {config.type === 'pie' && <LegendPie {...props} />}
    </>
  )
}
