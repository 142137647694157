import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../components/Layout/SectionOverview';
import { Link, Button } from '@looker/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Looker Marketplace Overiew" headerTitle="Marketplace" splashImage="marketplace">
    {children}
  </SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<a parentName="p" {...{
        "href": "https://marketplace.looker.com"
      }}>{`Looker Marketplace`}</a>{` is a central location for finding, deploying, and managing Looker models (blocks), visualizations, applications, and actions.`}</p>
    <p>{`This page summarizes the Looker Marketplace development process and provides an overview of the different types of Marketplace content that you can create.`}</p>
    <h2>{`Overview`}</h2>
    <p>{`Developers can contribute to the Marketplace by creating content such as `}<a parentName="p" {...{
        "href": "#blocks"
      }}>{`blocks`}</a>{`, `}<a parentName="p" {...{
        "href": "#visualizations"
      }}>{`visualizations`}</a>{`, and `}<a parentName="p" {...{
        "href": "#applications"
      }}>{`applications`}</a>{`. To get started, follow these high-level steps:`}</p>
    <ol>
      <li parentName="ol">{`Create your Marketplace content. Use one of the following guides to help you get started, depending on which type of content you'd like to build:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.looker.com/data-modeling/marketplace/adding-block-marketplace"
            }}>{`Developing a custom block for the Looker Marketplace`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.looker.com/data-modeling/marketplace/adding-viz-marketplace"
            }}>{`Developing a visualization for the Looker Marketplace`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.looker.com/data-modeling/extension-framework/installing-extension"
            }}>{`Building a Looker application with the Extension Framework`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.looker.com/sharing-and-publishing/action-hub#building_a_custom_action"
            }}>{`Building a custom action`}</a></li>
        </ul>
      </li>
      <li parentName="ol">{`Host the code for your Marketplace content on a public Git repository. (For actions, instead `}<a parentName="li" {...{
          "href": "https://github.com/looker-open-source/actions/blob/master/docs/adding_actions.md"
        }}>{`submit a pull request`}</a>{` to Looker’s action repository.)`}</li>
      <li parentName="ol">{`Submit your Markeplace content for review. See `}<a parentName="li" {...{
          "href": "https://docs.looker.com/data-modeling/marketplace/submit-content"
        }}>{`Submitting content to the Looker Marketplace`}</a>{` for more details.`}</li>
    </ol>
    <p>{`The following sections summarize the different types of Marketplace content that you can create.`}</p>
    <h2>{`Blocks`}</h2>
    <h3>{`What are blocks?`}</h3>
    <p><a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/looker-blocks"
      }}>{`Looker Blocks`}</a>{` are pre-built pieces of LookML that Looker customers can use as a starting point for quick and flexible data modeling.`}</p>
    <p>{`You can create a block that models a common third-party dataset, such as `}<a parentName="p" {...{
        "href": "https://marketplace.looker.com/marketplace/detail/ga360-v2"
      }}>{`Google Analytics 360`}</a>{`, or models a common analytical pattern, such as `}<a parentName="p" {...{
        "href": "https://marketplace.looker.com/marketplace/detail/retail-block-v2"
      }}>{`Retail Analytics`}</a>{`.`}</p>
    <h3>{`Using blocks`}</h3>
    <p>{`Blocks are designed to be plug-and-play, as long as you have the appropriate dataset in an existing Looker connection. You can `}<a parentName="p" {...{
        "href": "docs.looker.com/data-modeling/marketplace"
      }}>{`install a block`}</a>{` from the Marketplace, customize the LookML, and begin exploring.`}</p>
    <p>{`To develop a block for submission to the Marketplace, create a new LookML project in your Looker instance and back up the LookML in a public Github repository. See `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/marketplace/adding-block-marketplace"
      }}>{`Developing a custom block for the Looker Marketplace`}</a>{` for detailed instructions and guidelines.`}</p>
    <h3>{`Examples`}</h3>
    <p>{`Most `}<a parentName="p" {...{
        "href": "https://marketplace.looker.com/marketplace/directory?Type=tools"
      }}>{`blocks on the Looker Marketplace Directory`}</a>{` can be `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/marketplace"
      }}>{`one-click installed`}</a>{` onto your Looker instance. For examples, see:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://marketplace.looker.com/marketplace/detail/ga360-v2"
        }}>{`Google Analytics 360`}</a>{` block`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://marketplace.looker.com/marketplace/detail/retail-block-v2"
        }}>{`Retail Analytics`}</a>{` block`}</li>
    </ul>
    <h3>{`Getting Started`}</h3>
    <Link href="https://docs.looker.com/data-modeling/marketplace/adding-block-marketplace" mdxType="Link">
  <Button mdxType="Button">Developing a LookML block</Button>
    </Link>
    <h2>{`Visualizations`}</h2>
    <h3>{`What are visualizations?`}</h3>
    <p>{`In addition to Looker's `}<a parentName="p" {...{
        "href": "https://docs.looker.com/exploring-data/visualizing-query-results/visualization-types"
      }}>{`default visualization library`}</a>{`, you can `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/marketplace/adding-viz-marketplace"
      }}>{`create custom visualization types`}</a>{` in JavaScript. using the `}<a parentName="p" {...{
        "href": "https://github.com/looker/custom_visualizations_v2/blob/master/docs/getting_started.md"
      }}>{`Looker Visualization API`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://lookervisbuilder.com/"
      }}>{`Looker visualization testbed`}</a>{`, or your own environment.`}</p>
    <h3>{`Using visualizations`}</h3>
    <p>{`Visualizations are designed to be plug-and-play. You can `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/marketplace?Type=visualizations"
      }}>{`install a visualization`}</a>{` from the Marketplace and immediately select the new visualization type when exploring, building a new dashboard, and editing a dashboard.`}</p>
    <p>{`To develop a visualization for submission to the Marketplace, start by using the `}<a parentName="p" {...{
        "href": "https://github.com/looker/custom_visualizations_v2/blob/master/docs/getting_started.md"
      }}>{`Looker Visualization API`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://developers.looker.com/marketplace/custom-viz-builder"
      }}>{`Looker Custom Visualization Builder`}</a>{`, or your own Javascript environment. See `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/marketplace/adding-viz-marketplace"
      }}>{`Developing a visualization for the Looker Marketplace`}</a>{` for detailed instructions and guidelines.`}</p>
    <h3>{`Examples`}</h3>
    <p>{`Most `}<a parentName="p" {...{
        "href": "https://marketplace.looker.com/marketplace/directory?Type=visualizations"
      }}>{`visualizations on the Looker Marketplace Directory`}</a>{` can be `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/marketplace"
      }}>{`one-click installed`}</a>{` onto your Looker instance. For examples, see:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://marketplace.looker.com/marketplace/detail/viz-aster_plot"
        }}>{`Aster Plot visualization`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://marketplace.looker.com/marketplace/detail/viz-forcedirected"
        }}>{`Force-Directed Graph`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://marketplace.looker.com/marketplace/detail/viz-gauge"
        }}>{`Gauge visualization`}</a></li>
    </ul>
    <h3>{`Getting started`}</h3>
    <Link href="https://docs.looker.com/data-modeling/marketplace/adding-viz-marketplace" mdxType="Link">
  <Button mdxType="Button">Developing a visualization</Button>
    </Link>
    <h2>{`Applications`}</h2>
    <h3>{`What are applications?`}</h3>
    <p>{`Looker Applications allow you to provide highly customized and integrated experiences to your Looker instance’s users.`}</p>
    <p>{`A dedicated Looker page becomes your canvas, with a wide array of tools at your disposal, including the ability to:`}</p>
    <ul>
      <li parentName="ul">{`run Javascript code`}</li>
      <li parentName="ul">{`access the Looker APIs through a pre-authenticated client`}</li>
      <li parentName="ul">{`leverage Looker Components for seamless UI`}</li>
      <li parentName="ul">{`make HTTP calls from the client or through a convenient server proxy`}</li>
      <li parentName="ul">{`authenticate with third-party services via OAuth`}</li>
    </ul>
    <h3>{`Using Applications`}</h3>
    <p>{`Applications are designed to be plug-and-play. You can `}<a parentName="p" {...{
        "href": "https://marketplace.looker.com/marketplace/directory?Type=applications"
      }}>{`install an application`}</a>{` from the Marketplace and imediately begin using it.`}</p>
    <p>{`To develop an application for submission to the marketplace, the first step is to is author a Javascript-based client-side application that uses the APIs exposed by Looker’s `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/extension-framework/installing-extension"
      }}>{`Extension Framework`}</a>{`. Looker's `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/create-looker-extension"
      }}><inlineCode parentName="a">{`create-looker-extension`}</inlineCode></a>{` command line tool can get you started with a template codebase, including the necessary build tooling to bundle your application code via webpack. See the `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/extension-framework/installing-extension"
      }}>{`Building a Looker extension`}</a>{` page for detailed instructions and guidelines.`}</p>
    <h3>{`Examples`}</h3>
    <p>{`Several `}<a parentName="p" {...{
        "href": "https://marketplace.looker.com/marketplace/directory?Type=applications"
      }}>{`Looker-published applications`}</a>{` can be `}<a parentName="p" {...{
        "href": "https://docs.looker.com/data-modeling/marketplace"
      }}>{`one-click installed`}</a>{` into your Looker instance from the Looker Marketplace. For examples, see:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://marketplace.looker.com/marketplace/detail/extension-api-explorer"
        }}>{`API Explorer`}</a>{` application`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://marketplace.looker.com/marketplace/detail/extension-data-dictionary"
        }}>{`Data Dictionary`}</a>{` application`}</li>
    </ul>
    <h3>{`Getting started`}</h3>
    <Link href="https://docs.looker.com/data-modeling/extension-framework/installing-extension" mdxType="Link">
  <Button mdxType="Button">Building a Looker extension</Button>
    </Link>
    <h2>{`Actions`}</h2>
    <h3>{`What are actions?`}</h3>
    <p>{`Actions, also called integrations, deliver Looker data to third-party services. Expand on Looker's `}<a parentName="p" {...{
        "href": "https://docs.looker.com/admin-options/platform/actions#list_of_integrated_services"
      }}>{`action destination library`}</a>{` by creating an action to a new destination, such as `}<a parentName="p" {...{
        "href": "https://community.looker.com/explores-36/looker-actions-airtable-6812"
      }}>{`Airtable`}</a>{` or `}<a parentName="p" {...{
        "href": "https://community.looker.com/explores-36/looker-actions-azure-storage-6814"
      }}>{`Azure Storage`}</a>{`.`}</p>
    <h3>{`Using actions`}</h3>
    <p>{`Looker customers enable actions from the `}<a parentName="p" {...{
        "href": "https://docs.looker.com/admin-options/platform/actions"
      }}>{`Admin settings - Actions`}</a>{` page in their Looker instance, rather than by installing actions from the Marketplace.`}</p>
    <p>{`To develop a new action, write a Javascript method that sends either one cell of a Looker data table, one Looker query, or one Looker dashboard to the destination. See the `}<a parentName="p" {...{
        "href": "https://docs.looker.com/sharing-and-publishing/action-hub#building_a_custom_action"
      }}>{`Building a custom action`}</a>{` page for detailed instructions and guidelines.`}</p>
    <h3>{`Examples`}</h3>
    <p>{`To try out an action, enable an action from the `}<a parentName="p" {...{
        "href": "https://docs.looker.com/admin-options/platform/actions"
      }}>{`Admin settings - Actions`}</a>{` page in your Looker instance. Then, select the action when sending or scheduling data. For examples, see:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://community.looker.com/explores-36/looker-actions-airtable-6812"
        }}>{`Airtable`}</a>{` action`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://community.looker.com/explores-36/looker-actions-azure-storage-6814"
        }}>{`Azure Storage`}</a>{` action`}</li>
    </ul>
    <h3>{`Getting Started`}</h3>
    <Link href="https://docs.looker.com/sharing-and-publishing/action-hub#building_a_custom_action" mdxType="Link">
  <Button mdxType="Button">Building a custom action</Button>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      