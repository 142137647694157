import { useState, useEffect, useMemo } from "react";
import { Index } from "elasticlunr";

export const useSearch = (
  query?: string | Index.QueryBuilder,
  providedIndex?: Index | object | string,
  providedStore?: Store<T> | string
) => {
  const index = useMemo(() => {
    if (providedIndex === undefined || providedIndex === null) {
      return "No index was provided. Results will always be empty.";
    }
    if (providedIndex instanceof Index) return providedIndex;
    if (typeof providedIndex === "string")
      return Index.load(JSON.parse(providedIndex));
    if (typeof providedIndex === "object") return Index.load(providedIndex);
  }, [providedIndex]);

  const store = useMemo(() => {
    return index.documentStore as Store<T>;
  }, [providedStore]);

  return useMemo(() => {
    if (!query || !index) return [];

    const results =
      typeof query === "string"
        ? index.search(query, { expand: true })
        : index.query(query);

    if (store) return results.map(({ ref }) => store.getDoc(ref));

    return results;
  }, [query, index, store]);
};

// export const useFlexSearch = (query, providedIndex, store, searchOptions = {suggest: true}) => {
//   const [index, setIndex] = useState(null)

//   useEffect(() => {
//     if (!providedIndex && !store)
//       console.warn(
//         'A FlexSearch index and store was not provided. Your search results will be empty.',
//       )
//     else if (!providedIndex)
//       console.warn(
//         'A FlexSearch index was not provided. Your search results will be empty.',
//       )
//     else if (!store)
//       console.warn(
//         'A FlexSearch store was not provided. Your search results will be empty.',
//       )
//   }, [providedIndex, store])

//   useEffect(() => {
//     if (!providedIndex) {
//       setIndex(null)
//       return
//     }

//     if (providedIndex instanceof FlexSearch) {
//       setIndex(providedIndex)
//       return
//     }

//     const importedIndex = FlexSearch.create()
//     importedIndex.import(providedIndex)

//     setIndex(importedIndex)
//   }, [providedIndex])

//   return useMemo(() => {
//     if (!query || !index || !store) return []

//     const rawResults = index.search(query, searchOptions)

//     return rawResults.map(id => store[id])
//   }, [query, index, store])
// }
