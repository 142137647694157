import React from 'react'
import { VisualizationPlayground } from '../../../external/visualization-playground/src/VisualizationPlayground'
import {
  ComponentsProvider,
  Box2,
  Heading,
  Link,
  Button,
  Paragraph,
  SpaceVertical,
} from '@looker/components'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import SplashImg from '../../images/marketplace/marketplace_splash.svg'

// Mock SDK
const sdk = {
  query: async (id, fields) => {
    console.log({ query: { id, fields } })
    const response = await fetch(
      '/components/visualization-components/query.json'
    )
    const value = await response.json()
    return {
      ok: true,
      value,
    }
  },
  run_query: async ({ query_id, result_format }) => {
    console.log({ run_query: { query_id, result_format } })
    const response = await fetch(
      '/components/visualization-components/run_query.json'
    )
    const value = await response.json()
    return { ok: true, value }
  },
}

const themeCustomizations = {
  colors: {
    background: '#262626',
    key: '#8AB4F8',
    text: '#fff',
    link: '#8AB4F8',
    critical: '#FF877C',
  },
  fontFamilies: {
    brand: 'Google Sans',
  },
}

const VisPlayground = () => {
  return (
    <ComponentsProvider themeCustomizations={themeCustomizations}>
      <Layout
        defaultNav={false}
        showQuotes={false}
        title="Visualization  Playground"
      >
        <SplashContainer>
          <SplashImage src={SplashImg} />
        </SplashContainer>
        <PageContainer>
          <Box2
            paddingTop={['xxlarge', 'xxxlarge', 'xxxlarge', 'xxxxlarge']}
            paddingBottom="u4"
            maxWidth="650px"
            backgroundColor="white"
          >
            <SpaceVertical gap="u12">
              <SpaceVertical gap="u8">
                <Heading
                  as="h1"
                  fontSize={[
                    'xxxxlarge',
                    'xxxxlarge',
                    'xxxxlarge',
                    'xxxxxlarge',
                  ]}
                  fontWeight="normal"
                >
                  Visualization Components
                </Heading>
                <Paragraph color="text3" fontSize="large">
                  Visualization Components bring the power of Looker
                  visualizations and data to your React-based applications. They
                  can be lighter weight than similar iframe-based embed
                  solutions, and can offer deeper integration between your
                  application and Looker.
                </Paragraph>
                <Paragraph color="text3" fontSize="large">
                  Visualization Components can implicitly retreive their data
                  and settings anywhere that the Looker API SDK is available —
                  that includes both from Extensions via the Extension SDK, and
                  from external applications via our Typescript SDK.
                </Paragraph>

                <Link href="https://github.com/looker-open-source/components/tree/main/packages/visualizations">
                  <Button>View on Github</Button>
                </Link>
              </SpaceVertical>

              <SpaceVertical gap="u5">
                <Heading fontSize="xxlarge">Playground</Heading>
                <Paragraph color="text3" fontSize="large">
                  Preview our available chart types and visualization
                  configurations with our visualization component playground
                  below.
                </Paragraph>
              </SpaceVertical>
            </SpaceVertical>
          </Box2>

          <VisualizationPlayground
            sdk={sdk}
            localStorageGetItem={async (...args) =>
              localStorage.getItem(...args)
            }
            localStorageSetItem={async (...args) =>
              localStorage.setItem(...args)
            }
          />
        </PageContainer>
      </Layout>
    </ComponentsProvider>
  )
}

const FlexWrapper = styled.div`
  flex: 1;
  overflow: auto;
  * > div {
    overflow: auto;
  }
`

export const SplashContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 0;
`

export const SplashImage = styled.img`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 350%;
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    top: -32px;
    width: auto;
  }
`

export const PageContainer = styled.div`
  max-width: 1340px;
  z-index: 0;
  margin: 0 auto;
  position: relative;
  padding: 0 ${(props) => props.theme.space.large};
`

export default VisPlayground
