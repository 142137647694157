import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../components/Layout/SectionOverview';
import { Link, Button } from '@looker/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Looker Embed Solution Overiew" headerTitle="Embed" splashImage="embed">{children}</SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Looker’s embed solution offers application developers and product teams an extremely fast time-to-value solution for delivering embedded analytics experience, ranging from single visualizations to complete dashboard or data exploration experiences.`}</p>
    <p>{`Contrasted with solutions that are built entirely from the `}<a parentName="p" {...{
        "href": "/api/overview"
      }}>{`REST API`}</a>{`, or solutions that are built using `}<a parentName="p" {...{
        "href": "/components/visualization-components"
      }}>{`Visualization Components`}</a>{`, the Looker embed solution offers a more out-of-the-box solution with all the functionality and UI built in to a single iframe.`}</p>
    <h1>{`Using Looker's embed solution`}</h1>
    <p>{`You can integrate our embed solution into your web application either by manually `}<a parentName="p" {...{
        "href": "https://docs.looker.com/reference/embedding/sso-embed"
      }}>{`including an iframe with an appropriately signed URL`}</a>{` or by leveraging our `}<a parentName="p" {...{
        "href": "https://docs.looker.com/reference/embed-sdk/embed-sdk-intro"
      }}>{`Embed SDK`}</a>{` to facilitate the process. In addition, Looker customers who are looking to provide convenient access to already authenticated Looker users from the context of other applications can leverage our `}<a parentName="p" {...{
        "href": "https://docs.looker.com/sharing-and-publishing/embedding"
      }}>{`Private Embed modality`}</a>{` to simplify the integration work even further.`}</p>
    <h1>{`Try it out`}</h1>
    <p>{`Want to see the Looker embed solution in action before writing any code? These demos can help:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://atomfashion.io/analytics/home"
        }}>{`Atom Fashion`}</a>{`: A demo data-driven web app (requires Google account to log in)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fabio-looker.github.io/looker_sso_tool/"
        }}>{`Looker SSO Embed tool`}</a>{`: An app that you can use to generate an SSO embed URL (requires an Embed Secret from your instance)`}</li>
    </ul>
    <h1>{`Getting started`}</h1>
    <Link href="/embed/getting-started" mdxType="Link"><Button mdxType="Button">Get started with Looker Embed</Button></Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      