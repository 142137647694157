import { useStaticQuery, graphql } from "gatsby"
import { NavigationPage, NavigationPageHeading } from "../../types/navigation"

export interface Sitemap {
  [key: string]: NavigationPage[]
}

let cachedStaticSitemap:any

export function useSitemap(): Sitemap {
  const data = useStaticQuery(graphql`
    query docQuery {
      allContentfulEntry {
        edges {
          node {
            ... on ContentfulDocumentationArticle {
              id
              productArea
              slug
              section
              title
              index
              internal {
                type
              }
              content {
                childMdx {
                  excerpt
                  headings {
                    depth
                    value
                  }
                }
              }
            }
            ... on ContentfulGettingStarted {
              id
              productArea
              slug
              title
              index
              subtype
              internal {
                type
              }
              content {
                childMdx {
                  excerpt
                  headings {
                    depth
                    value
                  }
                }
              }
            }
            ... on ContentfulTutorialArticle {
              id
              productArea
              slug
              section
              index
              title
              internal {
                type
              }
              content {
                childMdx {
                  excerpt
                  headings {
                    depth
                    value
                  }
                }
              }
            }
            ... on ContentfulOverview {
              id
              productArea
              section
              index
              title
              internal {
                type
              }
              content {
                childMdx {
                  excerpt
                  headings {
                    depth
                    value
                  }
                }
              }
            }
          }
        }
      }
      allMdx(filter: { parent: { internal: { type: { eq: "File" } } } }) {
        edges {
          node {
            slug
            excerpt
            frontmatter {
              title
            }
            headings {
              depth
              value
            }
            id
          }
        }
      }
    }
  `)

  if(cachedStaticSitemap){
    //React hooks are called frequently, so we store the static return value to avoid doing unnecessary reduce operations on every component loop
    // But... still need to put it after useStaticQuery because React needs consistent calling of hooks
    return cachedStaticSitemap
  }

  const componentsMap = data.allMdx.edges.reduce(
    (
      acc: Sitemap,
      edge: any // TODO: type GraphQL response
    ) => {
      let {
        slug,
        frontmatter,
        excerpt,
        headings
      } = edge.node
      slug = slug || ""
      const productArea = "components"
      const existingPages = acc[productArea] || []
      const splitSlug = slug.replace("www/src/documentation/", "").split("/")

      var componentSection = splitSlug[0]
      var section = splitSlug[1]
      slug = splitSlug[2]

      if (componentSection != "components") {
        //Component Reference pages
        section = splitSlug[0]
        slug = splitSlug[1]
      }

      const title = frontmatter.title
      const gettingStartedType = undefined
      headings?.forEach((heading: any) => {
        heading["anchor"] = heading["value"].toLowerCase().replace(/\s/g, "-")
      })

      const index = title
      acc[productArea] = [
        ...existingPages,
        {
          slug,
          section,
          title,
          productArea,
          excerpt,
          headings,
          index,
          gettingStartedType,
        },
      ]
      return acc
    },
    {}
  )

  const contentfulMap = data.allContentfulEntry.edges.reduce(
    (
      acc: Sitemap,
      edge: any // TODO: type GraphQL response
    ) => {
      let {
        slug,
        section,
        productArea,
        title,
        content,
        subtype,
        internal,
        index,
      } = edge.node
      const existingPages = acc[productArea] || []
      const headings: NavigationPageHeading[] = content.childMdx?.headings ?? []
      const excerpt = content.childMdx?.excerpt ?? ""

      headings?.forEach((heading) => {
        // take out special characters
        const textHeading = heading["value"].replace(/[^\w\s]/gi, '')
        heading["anchor"] = textHeading.toLowerCase().replace(/\s/g, "-")
      })

      section = !section ? null : section
      //Handle Getting Started pages
      const gettingStartedType = subtype ?? null

      if (internal.type == "ContentfulGettingStarted") {
        title = "Getting Started"
        slug = "getting-started"
      }

      if (internal.type == "ContentfulOverview") {
        title = "Overview"
        index = -1
      }

      acc[productArea] = [
        ...existingPages,
        {
          slug,
          section,
          title,
          excerpt,
          productArea,
          headings,
          index,
          gettingStartedType,
        },
      ]
      return acc
    },
    {}
  )
  contentfulMap.components = componentsMap.components
  cachedStaticSitemap = contentfulMap
  return cachedStaticSitemap
}
