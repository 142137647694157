import React, { useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import { MDXComponents } from '../../MDX'
import { MDXProvider } from '@mdx-js/react'
import { useLocation } from '@reach/router'
import { ComponentsProvider } from '@looker/components-providers'
import { Flex } from '@looker/components'
import styled from 'styled-components'

import { Footer } from './Footer'
import { Navigation, ComponentNavigation } from '../Navigation'
import { MenuHeader } from './Header'
import { THEME_OVERRIDES } from '../../styles'
import './styles.css'
import { LayoutProps } from './types'
import { CookieBanner } from './CookieBanner'
import { Metadata } from './Metadata'

export const GatsbyOverrides = createGlobalStyle`
  body {
    height: 100vh;
    margin: 0;
  }
  #___gatsby,
  #gatsby-focus-wrapper{
    height: 100%;
  }

  .gatsby-highlight-code-line {
    background-color: #feb;
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 0.75em;
    border-left: 0.25em solid #f99;
  }

    /**
   * Add back the container background-color, border-radius, padding, margin
   * and overflow that we removed from <pre>.
   */
  .gatsby-highlight {
    background-color: #fdf6e3;
    border-radius: 0.3em;
    margin: 0.5em 0;
    padding: 1em;
    overflow: auto;
  }

  /**
   * Remove the default PrismJS theme background-color, border-radius, margin,
   * padding and overflow.
   * 1. Make the element just wide enough to fit its content.
   * 2. Always fill the visible space in .gatsby-highlight.
   * 3. Adjust the position of the line numbers
   */
  .gatsby-highlight pre[class*="language-"] {
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: initial;
    float: left; /* 1 */
    min-width: 100%; /* 2 */
  }

  /** Hacking styles to address combobox search popover styles **/
  [role="listbox"] {
    li {
      padding: 10px 24px 10px 16px;
    }
  }

  /** Hacking styles to override the locked down props for Menu position **/
  /** Doesn't work because data-overlay-surface is shared with the search popover, too **/
  /*
  #modal-root {
    position: absolute;
    z-index: 9999;
  }
  [data-overlay-surface="true"] {
    transform: translate(-16px, -13px);

    [role="menu"] {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
  */
`

const Layout = ({
  children,
  defaultNav,
  headerSearch,
  showQuotes,
  showSignup = false,
  title,
}: LayoutProps) => {
  const [showNavigation, setShowNavigation] = useState(defaultNav ?? false)
  const toggleNavigation = () => {
    setShowNavigation(!showNavigation)
  }
  const closeNavigation = () => {
    setShowNavigation(false)
  }
  const location = useLocation()

  return (
    <ComponentsProvider {...THEME_OVERRIDES}>
      <Metadata route={location.pathname} title={title} />
      <GatsbyOverrides />
      <MDXProvider components={MDXComponents}>
        <MenuHeader
          headerSearch={headerSearch}
          toggleNavigation={toggleNavigation}
        />
        <Flex flexDirection="column">
          <CookieBanner />
          <PageWrapper padding="0px" paddingTop="4rem" as="main">
            {showNavigation &&
              (location.pathname.includes('/components/docs') ? (
                <ComponentNavigation closeNavigation={closeNavigation} />
              ) : (
                <Navigation closeNavigation={closeNavigation} />
              ))}
            <div className={showNavigation ? 'nav-showing' : 'nav-hiding'}>
              {children}
            </div>
          </PageWrapper>
          <Footer showQuotes={showQuotes} showSignup={showSignup} />
        </Flex>
      </MDXProvider>
    </ComponentsProvider>
  )
}

export const PageWrapper = styled(Flex)`
  > .nav-showing {
    margin-left: 0 auto;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    position: relative;
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      margin-left: 320px;
      padding-left: 48px;
      padding-right: 48px;
      width: calc(100% - 320px);
    }
  }
  > .nav-hiding {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    position: relative;
  }
`
export default Layout
