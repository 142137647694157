import React from "react"
import {
  Box,
  Flex,
  Heading,
  Text,
  Icon,
} from "@looker/components"
import styled from "styled-components"
import { ArrowForward } from "@styled-icons/material/ArrowForward"

import Layout from "../components/Layout"
import Splash from "../images/splash.svg"
import ApiIcon from "../images/api_icon.svg"
import EmbedIcon from "../images/embed_icon.svg"
import ExtensionIcon from "../images/extension_icon.svg"
import AtomSpot from "../images/spot_atom_fashion.svg"
import GovSpot from "../images/spot_gov.svg"
import { Search } from "../components/Search"
import {
  InfoCard,
  ExternalLink
} from "../components/Content"
import { MarketingBanner } from "../components/Layout/MarketingBanner"

// markup
const IndexPage = () => {
  return (
    <Layout defaultNav={false} headerSearch={false} showQuotes>
      <PageContainer>
        <MarketingBanner />
        <SplashContainer>
          <SplashImg src={Splash} />
        </SplashContainer>
        <Box
          paddingTop={["xxlarge", "xxxlarge", "xxxlarge", "xxxxlarge"]}
          paddingBottom={["xxxlarge", "xxxlarge", "xxxlarge", "100px"]}
          maxWidth="510px"
        >
          <Heading
            as="h1"
            fontSize={["xxxxlarge", "xxxxlarge", "xxxxlarge", "xxxxxlarge"]}
            fontWeight="normal"
            paddingBottom="large"
          >
            Build with the
            <br />
            Looker Platform
          </Heading>
          <Text
            color="text3"
            textAlign="center"
            fontSize="large"
            fontFamily="brand"
          >
            Enhance your own workflows with Looker, or build extensions on the
            Looker platform for the rest of the world.
          </Text>
          <Box marginTop="xxlarge">
            <Search />
          </Box>
        </Box>
        <CardRow>
          <InfoCard
            icon={ApiIcon}
            title="Get started with the API"
            content="Automate repetitive tasks, create complex workflows, or build entire applications— All powered by your data"
            cta="API"
            link="/api/getting-started"
          />
          <InfoCard
            icon={EmbedIcon}
            title="Embed Looker in your App"
            content="Bring Looker reports and dashboards into the tools your customers use the most."
            cta="Embedding"
            link="/embed/getting-started"
          />
          <InfoCard
            icon={ExtensionIcon}
            title="Extend the power of Looker"
            content="Significantly reduce the time and complexity of building your custom data applications."
            cta="Extension Framework"
            link="/extensions/getting-started"
          />
        </CardRow>
        <Heading
          color="text"
          as="h2"
          fontWeight="normal"
          fontSize="xxxxlarge"
          textAlign="center"
          paddingBottom={["xxxlarge", "xxxlarge", "xxxlarge", "xxxxlarge"]}
        >
          Bespoke Data Experiences
        </Heading>
        <Box paddingBottom="100px">
          <Flex
            flexDirection={["column", "column", "column", "row"]}
            alignContent="center"
            alignItems="center"
            textAlign={["center", "center", "center", "left"]}
          >
            <Flex
              order={["2", "2", "2", "1"]}
              flexDirection="column"
              paddingRight={["0", "0", "0", "120px"]}
              paddingTop={["xlarge", "xlarge", "xlarge", "0"]}
            >
              <Heading
                color="text"
                as="h4"
                fontSize={["xxxlarge", "xxxlarge", "xxxlarge", "xxxxlarge"]}
                marginBottom={["large", "large", "large", "xlarge"]}
              >
                Analytics Driven Ecommerce
              </Heading>
              <Text
                fontSize={["large", "large", "large", "xlarge"]}
                color="text2"
                marginBottom="xxlarge"
                fontFamily="brand"
                padding={["0 60px", "0 120px", "0 120px", "0"]}
              >
                Using a combination of API calls and embeds to build a clothing
                brand inventory and sales tracker.
              </Text>
              <ExperienceLink
                href="https://atomfashion.io"
                fontWeight="medium"
                fontSize="large"
              >
                Atom Fashion
                <Icon icon={<ArrowForward />} color="key" size="small" />
              </ExperienceLink>
            </Flex>
            <Box order={["1", "1", "1", "2"]} width="100%">
              <img src={AtomSpot} width={["80%", "80%", "100%"]} />
            </Box>
          </Flex>
        </Box>
        <Box paddingBottom={["80px", "80px", "80px", "120px"]}>
          <Flex
            flexDirection={["column", "column", "column", "row"]}
            alignContent="center"
            alignItems="center"
            textAlign={["center", "center", "center", "left"]}
          >
            <Flex
              order="2"
              flexDirection="column"
              paddingLeft={["0", "0", "0", "0"]}
            >
              <Heading
                color="text"
                as="h4"
                fontSize={["xxxlarge", "xxxlarge", "xxxlarge", "xxxxlarge"]}
                marginBottom={["large", "large", "large", "xlarge"]}
                paddingTop={["small", "small", "small", "0"]}
              >
                Better benefits built on data
              </Heading>
              <Text
                fontSize={["large", "large", "large", "xlarge"]}
                color="text2"
                marginBottom="xxlarge"
                fontFamily="brand"
                padding={["0 60px", "0 120px", "0 120px", "0"]}
              >
                Using a combination of API calls and embeds to build a benefits
                information portal.
              </Text>
              <ExperienceLink href="https://govportal.io/" fontWeight="medium" fontSize="large">
                Benefits Portal
                <Icon icon={<ArrowForward />} color="key" size="small" />
              </ExperienceLink>
            </Flex>
            <Box order="1" width="100%">
              <img src={GovSpot} width={["80%", "80%", "100%"]} />
            </Box>
          </Flex>
        </Box>
      </PageContainer>
    </Layout>
  );
};

export default IndexPage;

export const SplashContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
`;

export const SplashImg = styled.img`
  position: absolute;
  top: -60px;
  right: -100px;
  width: 60%;
  z-index: -1;
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    top: -150px;
    right: 0;
    width: auto;
  }
`;

export const PageContainer = styled.div`
  max-width: 1340px;
  z-index: 0;
  margin: 0 auto;
  position: relative;
  padding: 0 ${(props) => props.theme.space.large};
`;

export const CardRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: ${(props) => props.theme.space.xlarge};
  padding-bottom: ${(props) => props.theme.space.xxxlarge};
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-bottom: 120px;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ExperienceLink = styled(ExternalLink)`
  color: ${(props) => props.theme.colors.key} !important;
  display: flex;
  align-items: center;
  font-family: "Google Sans";
  margin: 0 auto;
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    margin: 0;
  }
  &:hover, &:active {
    color: ${(props) => props.theme.colors.keyInteractive};
    text-decoration: none;
    transition: color 150ms ease-in-out;

    ${Icon} {
      color: ${(props) => props.theme.colors.keyInteractive};
      transform: translateX(4px);
    }
  }

  ${Icon} {
    padding-left: ${(props) => props.theme.space.xxsmall};
    transition: transform 150ms ease-in-out;
  }
`
