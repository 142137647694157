// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-layout-documentation-tsx": () => import("./../../../src/components/Layout/Documentation.tsx" /* webpackChunkName: "component---src-components-layout-documentation-tsx" */),
  "component---src-components-layout-getting-started-tsx": () => import("./../../../src/components/Layout/GettingStarted.tsx" /* webpackChunkName: "component---src-components-layout-getting-started-tsx" */),
  "component---src-components-layout-overview-tsx": () => import("./../../../src/components/Layout/Overview.tsx" /* webpackChunkName: "component---src-components-layout-overview-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-actions-overview-mdx": () => import("./../../../src/pages/actions/overview.mdx" /* webpackChunkName: "component---src-pages-actions-overview-mdx" */),
  "component---src-pages-api-docs-tsx": () => import("./../../../src/pages/api/docs.tsx" /* webpackChunkName: "component---src-pages-api-docs-tsx" */),
  "component---src-pages-api-explorer-tsx": () => import("./../../../src/pages/api/explorer/[...].tsx" /* webpackChunkName: "component---src-pages-api-explorer-tsx" */),
  "component---src-pages-api-overview-mdx": () => import("./../../../src/pages/api/overview.mdx" /* webpackChunkName: "component---src-pages-api-overview-mdx" */),
  "component---src-pages-components-getting-started-mdx": () => import("./../../../src/pages/components/getting-started.mdx" /* webpackChunkName: "component---src-pages-components-getting-started-mdx" */),
  "component---src-pages-components-overview-mdx": () => import("./../../../src/pages/components/overview.mdx" /* webpackChunkName: "component---src-pages-components-overview-mdx" */),
  "component---src-pages-components-tsx": () => import("./../../../src/pages/components/[...].tsx" /* webpackChunkName: "component---src-pages-components-tsx" */),
  "component---src-pages-components-visualization-components-tsx": () => import("./../../../src/pages/components/visualization-components.tsx" /* webpackChunkName: "component---src-pages-components-visualization-components-tsx" */),
  "component---src-pages-embed-builder-jsx": () => import("./../../../src/pages/embed/builder.jsx" /* webpackChunkName: "component---src-pages-embed-builder-jsx" */),
  "component---src-pages-embed-getting-started-mdx": () => import("./../../../src/pages/embed/getting-started.mdx" /* webpackChunkName: "component---src-pages-embed-getting-started-mdx" */),
  "component---src-pages-embed-getting-started-private-mdx": () => import("./../../../src/pages/embed/getting-started/private.mdx" /* webpackChunkName: "component---src-pages-embed-getting-started-private-mdx" */),
  "component---src-pages-embed-getting-started-sso-mdx": () => import("./../../../src/pages/embed/getting-started/sso.mdx" /* webpackChunkName: "component---src-pages-embed-getting-started-sso-mdx" */),
  "component---src-pages-embed-overview-mdx": () => import("./../../../src/pages/embed/overview.mdx" /* webpackChunkName: "component---src-pages-embed-overview-mdx" */),
  "component---src-pages-embed-sso-embed-demo-mdx": () => import("./../../../src/pages/embed/sso-embed-demo.mdx" /* webpackChunkName: "component---src-pages-embed-sso-embed-demo-mdx" */),
  "component---src-pages-extensions-overview-mdx": () => import("./../../../src/pages/extensions/overview.mdx" /* webpackChunkName: "component---src-pages-extensions-overview-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketplace-custom-viz-builder-mdx": () => import("./../../../src/pages/marketplace/custom-viz-builder.mdx" /* webpackChunkName: "component---src-pages-marketplace-custom-viz-builder-mdx" */),
  "component---src-pages-marketplace-overview-mdx": () => import("./../../../src/pages/marketplace/overview.mdx" /* webpackChunkName: "component---src-pages-marketplace-overview-mdx" */)
}

