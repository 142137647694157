import * as React from "react";
import { Text } from "@looker/components";
import Layout from "../../components/Layout";

const BuilderPage = () => {
  return (
    <Layout defaultNav={false}>
      <Text>Builder will go here eventually!</Text>
    </Layout>
  );
};

export default BuilderPage;
