import type { FormEvent } from 'react'
import React, { useContext, useState, useRef, useEffect } from 'react'
import type { CAll } from '@looker/visualizations'
import { Query, Visualization, isNumeric } from '@looker/visualizations'
import {
  Box,
  Tab2,
  Tabs2,
  Layout,
  Aside,
  Section,
  ExtendComponentsThemeProvider,
  Page,
  FieldText,
} from '@looker/components'
import debounce from 'lodash/debounce'
import { ThemeContext } from 'styled-components'
import { EmbedEditor } from './EmbedEditor'
import { ConfigEditor } from './ConfigEditor'
import { ShareLink } from './ShareLink'
import { mockSDK } from './__mocks__'

type Props = {
  sdk?: any
  localStorageGetItem: (key: string) => Promise<string | null>
  localStorageSetItem: (key: string, value: string) => void
}

export const VisualizationPlayground = ({
  sdk,
  localStorageGetItem,
  localStorageSetItem,
}: Props) => {
  const [tabId, setTabId] = useState<string>('mock')
  const [queryId, setQueryId] = useState('')
  const [queryIdentifier, setQueryIdentifier] = useState('')
  const [configOverrides, setConfigOverrides] = useState<Partial<CAll>>({})
  const [width, setWidth] = useState<string | undefined>()
  const [height, setHeight] = useState<string | undefined>()

  const debouncedSetQueryIdentifier = useRef(
    debounce((nextValue: string) => {
      setQueryIdentifier(nextValue)
      if (nextValue) {
        setConfigOverrides({})
      }
    }, 500)
  ).current

  const handleQueryIdChange = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement
    localStorageSetItem('visComponentQueryIdentifier', value)
    setQueryId(value)
  }

  const handleTabChange = (tabId: string) => {
    localStorageSetItem('sdkTabId', String(tabId))
    setTabId(tabId)
  }

  useEffect(() => {
    debouncedSetQueryIdentifier(queryId)
  }, [queryId])

  useEffect(() => {
    // clear config overrides when switching from mock to live data
    // and vice versa
    setConfigOverrides({})
  }, [tabId])

  useEffect(() => {
    const getLocalStorage = async () => {
      const storedIdentifier = await localStorageGetItem(
        'visComponentQueryIdentifier'
      )
      const storedTabId = await localStorageGetItem('sdkTabId')
      setQueryId(storedIdentifier || '')
      if (storedTabId) {
        setTabId(storedTabId)
      }
    }
    getLocalStorage()
  }, [])

  const liveApi = {
    sdk,
    queryId: queryIdentifier,
  }

  const mockApi = {
    sdk: (mockSDK as unknown) as any,
    queryId: 'mock-query-id',
  }

  const api = sdk && tabId === 'live' ? liveApi : mockApi

  const { colors } = useContext(ThemeContext)

  return (
    <Query query={api.queryId} sdk={api.sdk} config={configOverrides}>
      <Page fixed>
        <Layout hasAside title="Visualization Playground">
          <Section main px="large" py="medium">
            {/*<Tabs2 tabId={tabId} onTabChange={handleTabChange}>
              <Tab2 id="mock" label="Visualization">*/}
            <Visualization
              width={isNumeric(width) ? Number(width) : undefined}
              height={isNumeric(height) ? Number(height) : undefined}
            />
            {/*</Tab2>
              <Tab2 id="live" label="Data">
                <Box mt="small">
                  <FieldText
                    label="Query"
                    name="query"
                    detail="ID or Slug"
                    value={queryId}
                    onChange={handleQueryIdChange}
                  />
                  <ShareLink />
                </Box>
                <Visualization
                  width={isNumeric(width) ? Number(width) : undefined}
                  height={isNumeric(height) ? Number(height) : undefined}
                />
              </Tab2>
              </Tabs2>*/}
          </Section>
          <ExtendComponentsThemeProvider
            themeCustomizations={{
              colors: {
                background: colors.text,
                text: colors.background,
              },
            }}
          >
            <Aside
              borderLeft
              p="medium"
              backgroundColor="background"
              height="100vh"
            >
              <EmbedEditor
                width={width}
                setWidth={setWidth}
                height={height}
                setHeight={setHeight}
              />
              <ConfigEditor
                config={configOverrides}
                onConfigChange={setConfigOverrides}
              />
            </Aside>
          </ExtendComponentsThemeProvider>
        </Layout>
      </Page>
    </Query>
  )
}
