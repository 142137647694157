import React, { FC } from "react"
import { Link, LinkProps } from "@looker/components"
import styled from "styled-components"

interface ExternalLinkProps extends LinkProps {
}

export const ExternalLink: FC<ExternalLinkProps> = (props) => {
  const newProps = {
    ...props
  }
  if (newProps?.href?.startsWith('http')) {
    newProps.target = '_blank'
  }
  return (
    <ExternalLinkWrapper {...newProps} />
  )
}

export const ExternalLinkWrapper = styled(Link)`
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
  }

  svg
`
