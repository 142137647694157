import * as React from 'react'
import { Text } from '@looker/components'
import Layout from '../../components/Layout'

const Docs = () => {
  return (
    <Layout defaultNav={false}>
      <Text>API Documentation will go here eventually!</Text>
    </Layout>
  )
}

export default Docs
