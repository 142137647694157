import {Box, Flex, Text, ButtonOutline, IconButton } from "@looker/components";
import styled from "styled-components";
import { Close } from '@styled-icons/material/Close'
import React, { FC, useState, useEffect } from "react";
import { Link } from "gatsby";

export interface MarketingBannerProps {

}

export const MarketingBanner: FC<MarketingBannerProps> = (props) => {

  const currentBannerStorageKey = "bannerDismissal"
  const storageDismissedValue = "2023-06-cloud-docs"

  const [open, setOpen] = useState(true)

  useEffect(load,[])

  return open ? (
    <Banner className="banner-body">
      <Flex alignItems="center" justifyContent="space-between">
        <Text>
        Looker Developer Portal content will be migrating to Google Cloud Docs
        </Text>
        <Flex>
          <Link to="https://cloud.google.com/looker/docs/" target="banner_clickthrough">
            <ButtonOutline size="xsmall">Google Cloud Docs</ButtonOutline>
          </Link>
          <IconButton id="close" icon={<Close />} label="Close" tooltipDisabled onClick={close} />
        </Flex>
      </Flex>
    </Banner>
  ):null

  function load(){
    const wasDismissed = sessionStorage.getItem(currentBannerStorageKey)
    setOpen(wasDismissed !== storageDismissedValue)
  }

  function close(){
    sessionStorage.setItem(currentBannerStorageKey,storageDismissedValue)
    setOpen(false)
  }
};

export const Banner = styled(Box)`
  background-color: #34A853;
  color: #fff;
  padding: ${(props) => props.theme.space.u3} ${(props) => props.theme.space.u3} ${(props) => props.theme.space.u3} ${(props) => props.theme.space.u6};
  overflow: visible;
  position: relative;
  top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;

  ${Text} {
    font-size: ${(props) => props.theme.fontSizes.small};
    padding-right: ${(props) => props.theme.space.u4};
    line-height: 1.3;

    span {
      display: none;
      @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
        display: inline;
      }
    }
  }

  a {
    text-decoration: none;
  }

  ${ButtonOutline} {
    color: #34A853;
    border: none;
  }

  ${IconButton} {
    color: #fff;
    margin-left: 12px;
  }
`;
