import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SectionOverview from '../../components/Layout/SectionOverview';
import { Link, Button } from '@looker/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <SectionOverview pageTitle="Looker API Overiew" headerTitle="API" splashImage="api">{children}</SectionOverview>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Looker’s API provides access to the vast majority of Looker functionality over a convenient JSON-oriented REST API. It includes a diversity of endpoints, from simple running of queries in a variety of formats, to managing users, content, schedules, instance configurations, and more.`}</p>
    <p>{`The API can be used as a part of many use cases. Some examples include internal operational tools, highly custom customer-facing embedded analytics, mobile app integrations, specialized Looker administrative tools, and more.`}</p>
    <h1>{`Using the API`}</h1>
    <p>{`You can use the API either directly via HTTPS requests or through the convenience of a `}<a parentName="p" {...{
        "href": "https://docs.looker.com/reference/api-and-integration/api-sdk"
      }}>{`language-specific API SDK`}</a>{`.`}</p>
    <p>{`In addition, the API provides for a few authentication modes, which may help inform your architectural choices. Backend services can authenticate with API credentials, can use the API with a service account, and can conveniently impersonate API requests on behalf of end users. Alternatively, frontend clients can leverage `}<a parentName="p" {...{
        "href": "https://docs.looker.com/reference/api-and-integration/api-cors"
      }}>{`OAuth authentication`}</a>{` (`}<a parentName="p" {...{
        "href": "https://oauth.net/2/pkce/"
      }}>{`Authorization Code PKCE`}</a>{`), without the need to handle sensitive client secrets.`}</p>
    <p>{`Here is a sample of the most common ways that the API is used end-to-end:`}</p>
    <ul>
      <li parentName="ul">{`Backend only`}
        <ul parentName="li">
          <li parentName="ul">{`Authenticate with `}<a parentName="li" {...{
              "href": "https://docs.looker.com/reference/api-and-integration/api-auth"
            }}>{`API Credentials`}</a>{`: Common for command-line scripts, administrative tasks, or sandbox environments`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Frontend and backend`}
        <ul parentName="li">
          <li parentName="ul">{`Provide an application server with single service account and credentials, and make API calls through the service account`}</li>
          <li parentName="ul">{`Provide an application server with single service account and credentials, but use Looker’s `}<inlineCode parentName="li">{`sudo`}</inlineCode>{` or `}<inlineCode parentName="li">{`login_as_user`}</inlineCode>{` functionality to make calls on behalf of the end user`}</li>
          <li parentName="ul">{`Authenticate with an application server, use sudo/login_as_user, send the resulting token back to the user, and make frontend API calls with `}<a parentName="li" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS"
            }}>{`Cross-Origin Resource Sharing (CORS)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Frontend only`}
        <ul parentName="li">
          <li parentName="ul">{`Authenticate with `}<a parentName="li" {...{
              "href": "https://docs.looker.com/reference/api-and-integration/api-cors"
            }}>{`OAuth`}</a>{`, and make front-end API calls with CORS`}</li>
          <li parentName="ul">{`Implicit authentication and invocation from within `}<a parentName="li" {...{
              "href": "/extensions/overview"
            }}>{`Looker Extensions`}</a></li>
        </ul>
      </li>
    </ul>
    <h1>{`Try it out`}</h1>
    <p>{`Want to see the API in action before writing any code? These demos can help:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://atomfashion.io/"
        }}>{`Atom Fashion`}</a>{` is a demo data-driven web app (requires Google account to log in).`}</li>
      <li parentName="ul">{`Explore API endpoints and make sample API calls against your Looker instance in our `}<a parentName="li" {...{
          "href": "/api/explorer/"
        }}>{`API Explorer`}</a>{`. You can also `}<a parentName="li" {...{
          "href": "https://marketplace.looker.com/marketplace/detail/extension-api-explorer"
        }}>{`install the API Explorer as an extension`}</a>{` for more convenient use from your instance.`}</li>
    </ul>
    <h1>{`Getting started`}</h1>
    <Link href="/api/getting-started" mdxType="Link"><Button mdxType="Button">Get started with the Looker API</Button></Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      