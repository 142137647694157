import { Card, Flex, Heading, Text, Box } from "@looker/components"
import styled from "styled-components"
import QuoteBubble from "../../images/quote_bubble.svg"
import React, { FC } from "react"

interface QuoteCardProps {
  id: string
  content: string
  image: string
  attribution: string
}

export const QuoteCard: FC<QuoteCardProps> = (props) => {
  return (
    <QCard borderRadius="8px">
      <FlexWrapper>
        <LogoFlex
          width={["100%", "100%", "100%"]}
          backgroundColor="#FBFBFC"
          alignItems="center"
          height={["200px", "auto"]}
          padding="small" >
          <img src={props.image} width="100%" />
        </LogoFlex>
        <QuoteFlex
          flexDirection="column"
          alignItems="center"
          textAlign="center" >
          <QuoteBubbleBox>
            <img src={QuoteBubble} />
          </QuoteBubbleBox>
          <Heading
            fontWeight="normal">
            {props.content}
          </Heading>
          <Text
            paddingTop="large"
            fontFamily="code"
            fontSize="small"
            color="text3" >
            &mdash; {props.attribution}
          </Text>
        </QuoteFlex>
      </FlexWrapper>
    </QCard>
  )
}

export const QCard = styled(Card)`
  border: none;

  ${Heading} {
    font-size: ${(props) => props.theme.fontSizes.large};
    padding-top: ${(props) => props.theme.space.medium};

    @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
      font-size: ${(props) => props.theme.fontSizes.xlarge};
      padding-top: ${(props) => props.theme.space.large};
    }
  }
`;

export const FlexWrapper = styled(Flex)`
  height: auto;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    height: 350px;
    flex-direction: row;
  }
`;

export const LogoFlex = styled(Flex)`
  img {
    max-width: 160px;
    margin: 0 auto;
    padding: ${(props) => props.theme.space.large} 0;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    min-width: 180px;
    max-width: 180px;

    img {
      max-width: auto;
      padding: 0 ${(props) => props.theme.space.small};
    }
  }
`;

export const QuoteFlex = styled(Flex)`
  padding: ${(props) => props.theme.space.xlarge};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space.xxlarge};
  }
`;

export const QuoteBubbleBox = styled(Box)`
  img {
    width: 28px;
    @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
      width: 36px;
    }
  }
`;
