import startCase from "lodash/startCase";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { graphql, navigate, useStaticQuery, Link } from "gatsby";
import { useSearch } from "../utils/useSearch";
import {
  IconButton,
  InputSearch,
  SelectOptionObject,
} from "@looker/components";
import { Search as SearchButton } from "@styled-icons/material/";
import useKeyboardShortcut from "../utils/useKeyboardShortcut";
import styled from "styled-components";

interface SearchProps {
  headerSearch?: boolean;
}

const targetParams = {
  api: {
    value: "language",
    default: "python",
  },
  embed: {
    value: "embed_method",
    default: "public-embed",
  },
  extensions: {
    value: "extension_language",
    default: "react",
  },
  marketplace: {
    value: "marketplace_type",
    default: "extensions",
  },
  actions: {
    value: "na",
    default: "na",
  },
  components: {
    value: "na",
    default: "na",
  },
};

export const Search = ({ headerSearch }: SearchProps) => {
  const data = useStaticQuery(
    graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `
  );
  return (
    <>
      {headerSearch ? (
        <HeaderSearchField searchIndex={data.siteSearchIndex.index} />
      ) : (
        <SearchField searchIndex={data.siteSearchIndex.index} />
      )}
    </>
  );
};

interface FauxSearchProps {
  searchIndex: any;
}

//  const iconMap: { [key: string]: JSX.Element } = {
//    Components: <Code />,
//    Develop: <Info />,
//    Foundations: <VerifiedUser />,
//    Utilities: <Code />,
//  }

interface Result {
  id: string;
  slug: string;
  subtype?: string;
  productArea: string;
  section?: string;
  title: string;
}

const SearchField = ({ searchIndex }: FauxSearchProps) => {
  const [query, setQuery] = useState("");
  const results = useSearch(query, searchIndex);
  const elementRef = useRef<HTMLElement>();

  //Keyboard Shortcuts
  const searchKeys = ["Meta", "K"];
  const handleKeyboardShortcut = useCallback((searchKeys) => {
    if (elementRef.current) {
      elementRef.current.focus();
    }
  }, []);
  useKeyboardShortcut(searchKeys, handleKeyboardShortcut);

  const escapeKeys = ["Escape"];
  const handleEscape = useCallback((escapeKeys) => {
    if (elementRef.current) {
      elementRef.current.blur();
    }
  }, []);
  useKeyboardShortcut(escapeKeys, handleEscape);

  useEffect(() => {
    // Tracking the search term & results
    const t = window.setTimeout(() => {
      //Todo: fire GA event here
      if (query) {
        const noResults = results.length === 0 ? "-no-results" : "";
      }
    }, 500);
    return () => {
      window.clearTimeout(t);
    };
  }, [query, results]);

  const selectOption = (option?: SelectOptionObject) => {
    const gotoPage = () => option && navigate(option.value);
    gotoPage();
  };

  const options = results.map(({ slug, title, productArea, section }) => {
    const pageType = section ? startCase(section) : "";
    var path;
    if (slug?.includes("getting-started")) {
      const param = slug.replace("getting-started-", "").replace("-embed", "");
      const targetParam = targetParams[productArea]["value"];
      path =
        targetParam == "na"
          ? `/${productArea}/getting-started`
          : `/${productArea}/getting-started?${targetParam}=${param}`;
    } else {
      path = `/${productArea}/${section ? section + "/" : ""}${slug}`;
    }
    return {
      detail: pageType,
      //    icon: iconMap[pageType] || <InsertDriveFile />,
      label: title,
      value: path,
    };
  });

  return (
    <LGMInputSearch
      placeholder="Search for Looker developer content"
      value={query}
      ref={elementRef}
      onChange={setQuery}
      openOnFocus
      isClearable
      options={options}
      changeOnSelect={false}
      onSelectOption={selectOption}
      indicator={false}
      marginTop="xlarge"
    />
  );
};

const HeaderSearchField = ({ searchIndex }: FauxSearchProps) => {
  const [query, setQuery] = useState("");
  const results = useSearch(`*${query}*`, searchIndex);
  const [searchToggled, setSearchToggled] = useState(false);

  //Keyboard Shortcuts
  const searchKeys = ["Meta", "K"];
  const handleKeyboardShortcut = useCallback(
    (searchKeys) => {
      setSearchToggled(!searchToggled);
    },
    [setSearchToggled]
  );
  useKeyboardShortcut(searchKeys, handleKeyboardShortcut);

  const escapeKeys = ["Escape"];
  const handleEscape = useCallback(
    (escapeKeys) => {
      setSearchToggled(false);
    },
    [setSearchToggled]
  );
  useKeyboardShortcut(escapeKeys, handleEscape);

  useEffect(() => {
    // Tracking the search term & results
    const t = window.setTimeout(() => {
      if (query) {
        //TODO: Add GA Tracking
        const noResults = results.length === 0 ? "-no-results" : "";
      }
    }, 500);
    return () => {
      window.clearTimeout(t);
    };
  }, [query, results]);

  const selectOption = (option?: SelectOptionObject) => {
    const gotoPage = () => option && navigate(option.value);
    gotoPage();
  };

  const options = results.map(
    ({ slug, title, subtype, productArea, section }) => {
      const pageType = section ? startCase(section) : "";
      var path;
      if (slug?.includes("getting-started")) {
        const param = slug
          .replace("getting-started-", "")
          .replace("-embed", "");
        const targetParam = targetParams[productArea]["value"];
        path = `/${productArea}/getting-started?${targetParam}=${param}`;
      } else {
        path = `/${productArea}/${section ? section + "/" : ""}${slug}`;
      }
      return {
        detail: pageType,
        //    icon: iconMap[pageType] || <InsertDriveFile />,
        label: title,
        value: path,
      };
    }
  );

  return (
    <>
      {searchToggled ? (
        <HeaderLGMInputSearch
          placeholder="Search for Looker developer content"
          value={query}
          onChange={setQuery}
          autoFocus
          paddingX="medium"
          openOnFocus
          isClearable
          options={options}
          changeOnSelect={false}
          onSelectOption={selectOption}
          indicator={false}
          onBlur={() => setSearchToggled(!searchToggled)}
        />
      ) : (
        <IconButton
          icon={<SearchButton />}
          size="large"
          tooltipDisabled={true}
          label="Search"
          toggle={searchToggled}
          onClick={() => setSearchToggled(!searchToggled)}
        />
      )}
    </>
  );
};

export const LGMInputSearch = styled(InputSearch)`
  width: 100%;
  margin-top: 0;
  & > div {
    height: 48px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.15),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    padding-left: ${(props) => props.theme.space.medium};
    padding-right: ${(props) => props.theme.space.medium};
    svg {
      fill: ${(props) => props.theme.colors.ui5};
    }
  }
  input {
    font-size: 16px;
    font-family: "Google Sans";
    padding-left: ${(props) => props.theme.space.medium};
  }
`;

export const HeaderLGMInputSearch = styled(LGMInputSearch)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 510px;
  z-index: 2;
  &:before {
    content: " ";
    position: absolute;
    left: -1000%;
    top: -8px;
    background-color: white;
    width: 9999px;
    height: 64px;
    z-index: -1;
    border-bottom: 1px solid ${(props) => props.theme.colors.ui2};
  }
`;
