import styled from "styled-components"
import React, { FC } from "react"
import { Box } from '@looker/components'

import Api from "../../images/GettingStarted/api.svg"
import Embedding from "../../images/GettingStarted/embedding.svg"
import Extensions from "../../images/GettingStarted/extensions.svg"
import Marketplace from "../../images/GettingStarted/marketplace.svg"
import Actions from "../../images/GettingStarted/actions.svg"
import Components from "../../images/GettingStarted/components.svg"

export interface ProductSplashImageProps {
  section?: string
}

const getSectionArt = (section?: string) => {
  if (section === 'marketplace') {
    return Marketplace
  } else if (section === 'actions') {
    return Actions
  } else if (section === 'embed') {
    return Embedding
  } else if (section === 'extensions') {
    return Extensions
  } else if (section === 'components') {
    return Components
  } else if (section === 'api') {
    return Api
  } else {
    return Api
  }
}

export const ProductSplashImage: FC<ProductSplashImageProps> = ({ section }) => {
  return (
    <SplashImage>
      <img src={getSectionArt(section)} />
    </SplashImage>
  )
}

export const SplashImage = styled(Box)`
  position: absolute;
  top: -32px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  img {
    position: absolute;
    right: -180px;
    top: 60px;
    z-index: -1;
    width: 400px;
    opacity: 0.4;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    img {
      right: -180px;
      top: 60px;
      width: 500px;
      opacity: 1;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    img {
      right: -180px;
      top: 60px;
      width: 400px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    img {
      right: -48px;
      top: 30px;
      width: 500px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    img {
      width: auto;
      right: 0;
      top: 0px;
    }
  }

  .nav-hiding & {
    @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
      img {
        right: -48px;
        top: 30px;
        width: 500px;
      }
    }
    @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
      img {
        width: auto;
        right: 0;
        top: 0px;
      }
    }
  }
`
