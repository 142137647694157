import React, { useEffect, useRef } from 'react'
import { useLocation } from '@reach/router'

import LayoutCr from '../../../components/LayoutCr'
import { MarketingBanner } from '../../../components/Layout/MarketingBanner'

const ApiExplorer = () => {
  const location = useLocation()
  const apixCtrRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (apixCtrRef.current) {
      ;(window as any).LOOKER_DEV_PORTAL = {
        basename: location.pathname.split('/').slice(0, 3).join('/'),
      }
      const apixCtr = document.createElement('div')
      apixCtr.setAttribute('id', 'apix-container')
      const ctr = document.createElement('div')
      ctr.setAttribute('id', 'container')
      apixCtr.appendChild(ctr)
      const scriptJs = document.createElement('script')
      // To DEBUG API Explorer, swap the scriptJs.setAttribute() calls below
      scriptJs.setAttribute('src', '/apix/dist/bundle.js')
      // scriptJs.setAttribute('src', 'https://localhost:8080/dist/bundle.js')
      apixCtr.appendChild(scriptJs)
      apixCtrRef.current.appendChild(apixCtr)
    }
    return () => {
      if (apixCtrRef.current) {
        delete (window as any).LOOKER_DEV_PORTAL
        const apixCtr2 = document.getElementById('apix-container')
        if (apixCtr2) {
          apixCtrRef.current.removeChild(apixCtr2)
        }
      }
    }
  }, [apixCtrRef])

  return (
    <LayoutCr title="Looker API Interactive Reference">
      <MarketingBanner />
      <div ref={apixCtrRef} />
    </LayoutCr>
  )
}

export default ApiExplorer
