import React, { useState } from 'react'
import type { FormEvent } from 'react'
import type { YAxisConfig } from '@looker/visualizations'
import { Slider, FieldRadio, Field, useID } from '@looker/components'

export type TickDensityProps = {
  axis: YAxisConfig
  onAxisChange: (axis: YAxisConfig) => void
}

export const TickDensity = (props: TickDensityProps) => {
  const {
    axis,
    // TODO: uncomment this when tick density is supported
    // axis: { tick_density },
    onAxisChange,
  } = props

  const [densityToggle, setDensityToggle] = useState<string>()
  // TODO: uncomment this when tick density is supported
  // tick_density === 'default' ? 'default' : 'custom'

  const handleSliderChange = (e: FormEvent) => {
    const draft = {
      ...axis,
      tick_density: parseInt((e.target as HTMLInputElement).value),
    }
    onAxisChange(draft)
  }

  const handleToggleChange = (e: FormEvent) => {
    const { value } = e.target as HTMLInputElement
    setDensityToggle(value)
    // TODO: uncomment this when tick density is supported
    // const densityValue = value === 'default' ? 'default' : 50
    // onAxisChange({ ...axis, tick_density: densityValue })
  }

  const groupID = useID()

  return (
    <>
      <Field id={groupID} label="Tick Density">
        <FieldRadio
          name={`${groupID}-default`}
          label="Default"
          value="default"
          checked={densityToggle === 'default'}
          onChange={handleToggleChange}
        />
        <FieldRadio
          name={`${groupID}-custom`}
          label="Custom"
          value="custom"
          checked={densityToggle === 'custom'}
          onChange={handleToggleChange}
        />
      </Field>
      {densityToggle === 'custom' && (
        <Slider
          min={0}
          max={100}
          onChange={handleSliderChange}
          // TODO: uncomment this when tick density is supported

          // value={
          //   isNumeric(axis.tick_density)
          //     ? (axis.tick_density as number)
          //     : undefined
          // }
        />
      )}
    </>
  )
}
